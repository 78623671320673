import { put, call, select } from "redux-saga/effects"

import * as actions from "../../actions"
import axios from "axios"
// import {getFakePending} from './_getFake'

const getToken = state => state.auth.token

const apiCall = (id, token) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_PATH,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const path = `/dashboard/templates/${id}`
  return instance
    .get(path)
    .then(response => response)
    .catch(error => error)
}

export default function* getSingleTemplateAsync(action) {
  try {
    const token = yield select(getToken)
    const res = yield call(apiCall, action.payload.id, token)
    console.log(" res = ", res)
    if (res.status === 200)
      yield put(actions.getSingleTemplateSuccess(res.data))
    else yield put(actions.getSingleTemplateFailure())
  } catch (err) {
    yield put(actions.getSingleTemplateFailure())
  }
}
