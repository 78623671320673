import { put, call, select } from "redux-saga/effects"

import * as actions from "../../actions"
import axios from "axios"

const getToken = state => state.auth.token
const getShowDeleted = state => state.deleted.show

const apiCall = (id, token) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_PATH,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const path = `/dashboard/technicians/restore/${id}`
  return instance
    .put(path)
    .then(response => response)
    .catch(error => error.response)
}

export default function* restoreTechnicianAsync(action) {
  try {
    const token = yield select(getToken)
    const { id } = action.payload

    const res = yield call(apiCall, id, token)

    console.log(" res = ", res)
    if (res.status === 200) {
      yield put(actions.restoreTechnicianSuccess())
      if (action.payload.type === "pending") {
        const getPage = state => state.currentPages.pending
        const getParams = {
          page: yield select(getPage),
          soft_deleted: yield select(getShowDeleted),
          search: null,
          sort: "id",
        }
        yield put(actions.getPendingRequest(getParams))
      } else {
        const getPage = state => state.currentPages.technicians
        const getParams = {
          page: yield select(getPage),
          soft_deleted: yield select(getShowDeleted),
          search: null,
          sort: "id",
        }
        yield put(actions.getTechniciansRequest(getParams))
      }
    } else yield put(actions.restoreTechnicianFailure(res.data))
  } catch (err) {
    yield put(actions.restoreTechnicianFailure(err))
  }
}
