import React from "react"
import styled from "styled-components"
import theme from "../style/theme"

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const StyledHead = styled.h3`
	margin-bottom: 2%;
	font-size: ${theme.font.sm};
	font-weight: ${theme.font.regular}
	color: ${theme.colors.blackMain}
`
const StyledInput = styled.input`
  height: 24px;
  width: 150px;
  min-width: ${theme.size.minWidth};
  border-radius: 1px;
  padding: 6px;
  font-size: ${theme.font.sm};
  box-sizing: border-box;
  border: 0.5px solid ${theme.colors.borderMain};
`

export default function MySearchInput({
  name = "search",
  value = "default",
  placeholder = "Search",
  onChange,
}) {
  return (
    <StyledContainer>
      <StyledInput
        placeholder={placeholder}
        type="text"
        name={name}
        value={value}
        onChange={onChange}
      />
    </StyledContainer>
  )
}
