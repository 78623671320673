import React, { useState, useEffect } from "react"
import moment from "moment"
import axios from "axios"
import { useSelector } from "react-redux"
import { Button, Modal, Table } from "antd"
import { useHistory } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

import { inspectionStatusByStatusId } from "helpers/inspectionStatus"

const tableColumns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Inspection Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
]

const AppointmentsHistory = ({ appointment }) => {
  const [appointmentsHistory, setAppointmentsHistory] = useState([])
  const [isHistoryLoading, setIsHistoryLoading] = useState(false)
  const { token } = useSelector(state => state.auth)
  const history = useHistory()

  useEffect(() => {
    if (!appointment?.customer?.id) return

    getAppointmentsHistory()
  }, [appointment])

  const getAppointmentsHistory = async () => {
    try {
      setIsHistoryLoading(true)
      const response = await axios.get(
        `${process.env.REACT_APP_API_PATH}/appointments/customer/${appointment.customer.id}/history`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )

      const items = response.data.map(item => ({
        date: moment(item.application_date).format("L LT"),
        id: item.id,
        status: inspectionStatusByStatusId(item.status),
      }))

      setIsHistoryLoading(false)
      setAppointmentsHistory(items)
    } catch {
      setIsHistoryLoading(false)

      Modal.error({
        title: "Failed to get customer's appointments history",
        content: "Try to refresh page",
      })
    }
  }

  return (
    <Table
      columns={[
        ...tableColumns,
        {
          title: "",
          key: "action",
          render: (_, record) => (
            <Button
              type="primary"
              shape="circle"
              icon={<FontAwesomeIcon icon={faArrowRight} />}
              onClick={() => history.push(`/appointment/${record.id}`)}
            />
          ),
        },
      ]}
      dataSource={appointmentsHistory}
      loading={isHistoryLoading}
      pagination={false}
    />
  )
}

export default AppointmentsHistory
