import React, { useEffect, useState } from "react"
import classes from "./classes.module.css"
import { connect } from "react-redux"
import { checkAuth } from "../../../store/actions"
import { Doughnut, Bar } from "react-chartjs-2"
import { Row, Col, DatePicker } from "antd"
import { getSummaryStatistic } from "../../../helpers/statisctic"
const { RangePicker } = DatePicker

const legendLabels = [
  `Created Appointments:`,
  `Inspected:`,
  `Registered users:`,
]

const Dashboard = props => {
  const [barData, setBarData] = useState({
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: legendLabels[0],
        backgroundColor: "#ffc34d",
        borderColor: "#cca300",
        borderWidth: 1,
        data: [15, 10, 4, 12, 10, 11, 2],
      },
      {
        label: legendLabels[1],
        backgroundColor: "#00b33c",
        borderColor: "#006622",
        borderWidth: 1,
        data: [15, 10, 4, 12, 10, 11, 2],
      },
      {
        label: legendLabels[2],
        backgroundColor: "#99ccff",
        borderColor: "#0080ff",
        borderWidth: 1,
        data: [30, 19, 25, 12, 21, 15, 2],
      },
    ],
  })

  const [dateValueBar0, setDateValueBar0] = useState(null)
  const [dateValueBar1, setDateValueBar1] = useState(null)
  const [dateValueBar2, setDateValueBar2] = useState(null)
  const [dateValueBar3, setDateValueBar3] = useState(null)

  const [dateValueDoughnut0, setDateValueDoughnut0] = useState(null)
  const [dateValueDoughnut1, setDateValueDoughnut1] = useState(null)
  const [dateValueDoughnut2, setDateValueDoughnut2] = useState(null)
  const [dateValueDoughnut3, setDateValueDoughnut3] = useState(null)

  const labelOnChange = (hoverData, hoverData2) => {
    if (!hoverData2 || !hoverData2.length) {
      return
    }
    setBarData({
      ...barData,
      datasets: barData.datasets.map((item, i) => {
        const hoverData2Index = hoverData2[i]._index
        return {
          ...item,
          label: `${legendLabels[i]} ${item.data[hoverData2Index]}`,
        }
      }),
    })
  }

  const data = [10, 8, 9, 2]
  const [circleData, setCircleData] = useState({
    labels: [
      `Awaiting approve: ${data[0]}`,
      `Awaiting execution: ${data[1]}`,
      `Inspected: ${data[2]}`,
      `Canceled: ${data[3]}`,
    ],

    datasets: [
      {
        data: [...data],
        backgroundColor: ["#ffc34d", "#006622", "#0080ff", "#663300"],
      },
    ],
  })

  useEffect(() => {
    props.checkAuth()
    setBarData({
      ...barData,
      datasets: barData.datasets.map((item, i) => {
        return { ...item, label: `${legendLabels[i]} ${item.data[0]}` }
      }),
    })
  }, [])

  useEffect(() => {
    console.log("Test", dateValueBar0)

    // Remove hardcoded param and use state var from one of the pickers

    // dateValueBar0, dateValueBar1, dateValueBar2, dateValueBar3 // typeof === MOMENT

    let prams = null
    if (dateValueBar0) {
      prams = {
        day: {
          number: dateValueBar0.dayOfYear(),
          year: dateValueBar0.year(),
        },
      }
    } else if (dateValueBar1) {
      prams = {
        week: {
          number: dateValueBar1.week(),
          year: dateValueBar1.year(),
        },
      }
    } else if (dateValueBar2) {
      prams = {
        month: {
          number: dateValueBar2.month(),
          year: dateValueBar2.year(),
        },
      }
    } else if (dateValueBar3) {
      prams = {
        year: dateValueBar3.year(), // not working
      }
    }

    if (!prams) {
      return
    }

    getSummaryStatistic(props.auth.token, prams).then(data => {
      const dataSets = [...barData.datasets]
      dataSets[0].data = []
      dataSets[1].data = []
      dataSets[2].data = []

      if (!Array.isArray(data)) {
        return
      }
      data.forEach(item => {
        dataSets[0].data.push(item.created_appointments)
        dataSets[1].data.push(item.inspected_appointments)
        dataSets[2].data.push(item.registered_users)
      })

      setBarData({
        ...barData,
        datasets: dataSets,
      })
    })
  }, [dateValueBar0, dateValueBar1, dateValueBar2, dateValueBar3])
  // for Bar
  const handleBarDay = dateMoment => {
    setDateValueBar0(dateMoment)
    setDateValueBar1(null)
    setDateValueBar2(null)
    setDateValueBar3(null)
  }
  const handleBarWeek = dateMoment => {
    setDateValueBar0(null)
    setDateValueBar1(dateMoment)
    setDateValueBar2(null)
    setDateValueBar3(null)
  }
  const handleBarMonth = dateMoment => {
    setDateValueBar0(null)
    setDateValueBar1(null)
    setDateValueBar2(dateMoment)
    setDateValueBar3(null)
  }
  const handleBarYear = dateMoment => {
    setDateValueBar0(null)
    setDateValueBar1(null)
    setDateValueBar2(null)
    setDateValueBar3(dateMoment)
  }
  // for Doughnut
  const handleDoughnutDay = dateMoment => {
    setDateValueDoughnut0(dateMoment)
    setDateValueDoughnut1(null)
    setDateValueDoughnut2(null)
    setDateValueDoughnut3(null)
  }
  const handleDoughnutWeek = dateMoment => {
    setDateValueDoughnut0(null)
    setDateValueDoughnut1(dateMoment)
    setDateValueDoughnut2(null)
    setDateValueDoughnut3(null)
  }
  const handleDoughnutMonth = dateMoment => {
    setDateValueDoughnut0(null)
    setDateValueDoughnut1(null)
    setDateValueDoughnut2(dateMoment)
    setDateValueDoughnut3(null)
  }
  const handleDoughnutYear = dateMoment => {
    setDateValueDoughnut0(null)
    setDateValueDoughnut1(null)
    setDateValueDoughnut2(null)
    setDateValueDoughnut3(dateMoment)
  }

  return (
    <div style={{ padding: "40px" }}>
      <h1 className={classes.title}>Dashboard</h1>

      <Row>
        <Col span="12">
          <div style={{ padding: "10px" }}>
            <Row>
              <Col span="6">
                <DatePicker
                  value={dateValueBar0}
                  bordered={false}
                  onChange={handleBarDay}
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span="6">
                <DatePicker
                  value={dateValueBar1}
                  onChange={handleBarWeek}
                  picker="week"
                  bordered={false}
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span="6">
                <DatePicker
                  value={dateValueBar2}
                  onChange={handleBarMonth}
                  picker="month"
                  bordered={false}
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span="6">
                <DatePicker
                  value={dateValueBar3}
                  onChange={handleBarYear}
                  picker="year"
                  bordered={false}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col span="12">
          <div style={{ padding: "10px" }}>
            <Row>
              <Col span="6">
                <DatePicker
                  value={dateValueDoughnut0}
                  onChange={handleDoughnutDay}
                  bordered={false}
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span="6">
                <DatePicker
                  value={dateValueDoughnut1}
                  onChange={handleDoughnutWeek}
                  picker="week"
                  bordered={false}
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span="6">
                <DatePicker
                  value={dateValueDoughnut2}
                  onChange={handleDoughnutMonth}
                  picker="month"
                  bordered={false}
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span="6">
                <DatePicker
                  value={dateValueDoughnut3}
                  onChange={handleDoughnutYear}
                  picker="year"
                  bordered={false}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span="12">
          <div style={{ padding: "10px" }}>
            <RangePicker
              style={{ width: "100%", marginTop: "30px" }}
              format="YYYY-MM-DD"
              onChange={() => {}}
              onOk={() => {}}
            />
          </div>
        </Col>

        <Col span="12">
          <div style={{ padding: "10px" }}>
            <RangePicker
              style={{ width: "100%", marginTop: "30px" }}
              format="YYYY-MM-DD"
              onChange={() => {}}
              onOk={() => {}}
            />
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: "30px" }}>
        <Col span="12">
          <Bar
            data={barData}
            options={{
              responsive: true,
              legend: {
                position: "top",
              },
              title: {
                display: true,
                text: "Summary statistics",
              },
              tooltips: {
                mode: "index",
                // intersect: false,
                // enabled: false,
                callbacks: {
                  label: function (tooltipItem) {
                    return `${legendLabels[tooltipItem.datasetIndex]} ${
                      tooltipItem.value
                    }`
                  },
                },
              },
              onClick: labelOnChange,
              scales: {
                xAxes: [
                  {
                    stacked: true,
                  },
                ],
                yAxes: [
                  {
                    stacked: true,
                  },
                ],
              },
            }}
          />
        </Col>
        <Col span="12">
          <Doughnut
            data={circleData}
            options={{
              legend: {
                position: "top",
              },
              title: {
                display: true,
                text: "Appointments",
              },
              animation: {
                animateScale: true,
                animateRotate: true,
              },
              tooltips: {
                callbacks: {
                  label: function (tooltipItem) {
                    // const value = circleData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                    const label = circleData.labels[tooltipItem.index]
                    return `${label}`
                  },
                },
              },
            }}
          />
        </Col>
      </Row>
    </div>
  )
}

const connectDispatch = {
  checkAuth,
}

const mapState = state => ({
  auth: state.auth,
})

export default connect(mapState, connectDispatch)(Dashboard)
