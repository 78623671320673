import React, { useState, useEffect } from "react"
import { Image, Space, Typography, Input, Divider, Button, Modal } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { postLoginRequest, clearLoginStatus } from "store/actions"

import styles from "styles/pages/LoginPage.module.css"

const LoginPage = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const dispatch = useDispatch()
  const history = useHistory()
  const loginStatus = useSelector(state => state.auth.status)

  useEffect(() => {
    if (loginStatus === "failure") {
      Modal.error({
        title: "Failed to log into admin panel",
        content: "Double check your credencials and try again",
      })

      dispatch(clearLoginStatus())

      return
    }

    if (loginStatus === "success") {
      history.replace("/")
    }
  }, [loginStatus])

  const onLoginPress = () => {
    dispatch(postLoginRequest(email, password))
  }

  return (
    <div className={styles.container}>
      <div className={styles.inputContainer}>
        <Image src="/logo.png" preview={false} />
        <Typography.Title level={4}>Admin Panel</Typography.Title>
        <Divider />
        <Space direction="vertical">
          <Input
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="E-Mail"
            size="large"
          />
          <Input.Password
            value={password}
            onChange={e => setPassword(e.target.value)}
            placeholder="Password"
            size="large"
          />
          <Button
            type="primary"
            block
            ghost
            loading={loginStatus === "loading"}
            onClick={onLoginPress}
          >
            Log in
          </Button>
        </Space>
      </div>
    </div>
  )
}

export default LoginPage
