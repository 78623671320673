import types from "../types"

const initialState = {
  show: false,
}

export default function deleted(state = initialState, action) {
  switch (action.type) {
    case types.SET_SHOW_DELETED:
      return {
        ...state,
        show: action.payload,
      }
    default:
      return state
  }
}
