import { combineReducers } from "redux"
import errors from "./errors.reducer"
import test from "./test.reducer"
import deleted from "./deleted.reducer"
import sub from "./sub.reducer"
import modals from "./modals.reducer"
import auth from "./auth.reducer"
import pending from "./pending.reducer"
import createTemplate from "./createTemplate.reducer"
import currentPages from "./currentPages.reducer"
import technicians from "./technicians.reducer"
import companies from "./companies.reducer"
import statuses from "./statuses.reducer"
import customers from "./customers.reducer"
import vehicles from "./vehicles.reducer"
import factors from "./factors.reducer"
import categories from "./categories.reducer"
import templates from "./templates.reducer"
import paymentPackage from "./paymentPackage.reducer"

const rootReducer = combineReducers({
  auth,
  test,
  errors,
  deleted,
  sub,
  modals,
  createTemplate,
  pending,
  currentPages,
  technicians,
  companies,
  statuses,
  customers,
  vehicles,
  factors,
  categories,
  templates,
  paymentPackage,
})

export default rootReducer
