import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { SimpleButton } from "../../../../../components/Buttons"
import * as actions from "../../../../../store/actions"
import { connect } from "react-redux"
import CategoryItem from "./Item"

const LeftBlock = styled.div`
  display: flex;
  flex: 1.2;
  height: 100%;
  width: 80%;
  justify-self: flex-start;
  align-self: flex-start;
  box-sizing: border-box;
  flex-direction: column;
  background: white;
  border-right: 8px dashed #eee;
  // max-height: 510px !important;
  // overflow-y: auto;
  padding: 35px 20px 20px 20px;
`

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
`

const AddButton = ({ onClick }) => {
  return (
    <BtnContainer>
      <SimpleButton
        scalable
        title="+ Add Category"
        color="#559966"
        onClick={onClick}
      />
    </BtnContainer>
  )
}

const LeftEditor = ({
  categories,
  pushTemplateCategories,
  setTemplateCurrentCategory,
  retriveCategoriesEdit,
  template,
  isEdit,
}) => {
  const someItemsBeingEditing = () => {
    if (categories.some(e => e.editing)) {
      alert("Please, finish editing the current Category")
      return true
    }
    return false
  }

  useEffect(() => {
    if (isEdit) {
      retriveCategoriesEdit(template.templates_categories)
    }
    setTemplateCurrentCategory(null)
  }, [])

  const handleCreate = () => {
    if (someItemsBeingEditing()) return
    pushTemplateCategories(null)
  }

  let content
  content = categories.map(e => {
    return (
      <CategoryItem
        setTemplateCurrentCategory={setTemplateCurrentCategory}
        someItemsBeingEditing={someItemsBeingEditing}
        item={e}
        key={e.id}
      />
    )
  })

  return (
    <LeftBlock>
      <AddButton onClick={handleCreate} />
      {content}
    </LeftBlock>
  )
}

const connectState = state => ({
  categories: state.createTemplate.categories,
  template: state.templates.template,
})
const connectActions = {
  pushTemplateCategories: actions.pushTemplateCategories,
  setTemplateCurrentCategory: actions.setTemplateCurrentCategory,
  retriveCategoriesEdit: actions.retriveCategoriesEdit,
}
export default connect(connectState, connectActions)(LeftEditor)
