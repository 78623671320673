import React, { useState, useEffect } from "react"
import styled from "styled-components"
import MyCanvas from "../../../../../../components/MyCanvas"
import { SimpleButton } from "../../../../../../components/Buttons"
import RenderModal from "../../../../../../components/RenderModal"
import SimpleModal from "../../../../../../components/SimpleModal"
import Steps, { Step } from "rc-steps"
import "./steps.css"
import { nPoints } from "../../../../../../constants"

const StyledImageModal = styled.div`
  width: 700px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`

const Display = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 15px 15px 0 15px;
  background: whitesmoke;
  height: 90%;
  width: 200px;
  border-radius: 2px;
  overflow-y: auto;
`

const Message = styled.div`
  font-size: 1.5rem;
  padding: 0 30px 0 30px;
  color: #555;
  font-weight: 600;
`

const ImageModal = ({ model, areas, setAreas, sub }) => {
  const [selection, setSeletion] = useState(0)
  //   const [circle, setCircle] = useState(null);
  const [hover, setHover] = useState(null)
  const [url, setURL] = useState(null)
  const [removeModal, setRemoveModal] = useState({
    open: false,
    target: null,
    name: "",
  })

  useEffect(() => {
    if (!model) {
      return
    }
    const file = model.picture
    console.log("file = ", file)
    setURL(file instanceof File ? URL.createObjectURL(file) : file)
  }, [model])

  const handleSetCircle = e => {
    setTimeout(() => alert(JSON.stringify(e)), 100)
    setAreas(e)
  }

  const handleRemove = () => {
    setAreas(null)
  }

  if (!model | !url) {
    return (
      <StyledImageModal>
        <Message>
          Please, choose a Model Picture for the Current Category.
        </Message>
      </StyledImageModal>
    )
  }

  return (
    <StyledImageModal>
      <RenderModal
        alignButtonsRight
        label="Confirmation"
        onConfirm={handleRemove}
        render={() => <SimpleModal text={`Remove [${removeModal.name}]?`} />}
        active={removeModal.open}
        setActive={() =>
          setRemoveModal({ open: false, target: null, name: "" })
        }
      />
      <Display>
        {/* <SimpleButton
          title={sub ? sub.name : "Current Subcategory"}
          color="#3B86FF"
          onClick={() => {}}
          scalable
        /> */}
        <Step
          onMouseEnter={() => {}}
          onMouseLeave={() => {}}
          onClick={() =>
            setRemoveModal({
              open: true,
              target: 0,
              name: `area 0`,
            })
          }
          status={"process"}
          title={`${sub ? sub.name : "Current Subcategory"} Circle`}
          description={`Delete Zone`}
          style={{ cursor: "pointer" }}
        />
        <Steps style={{ marginTop: 15 }} direction="vertical" size="small">
          {/* {stepArray} */}
        </Steps>
      </Display>
      <MyCanvas
        hover={hover}
        setCircle={handleSetCircle}
        circle={areas}
        url={url}
      />
    </StyledImageModal>
  )
}

export default ImageModal
