import types from "../types"

const initialMeta = {
  last_page: 1,
}
const initialState = {
  array: [],
  subArray: [],
  meta: initialMeta,
}

// id: 1
// name: "Recommendation"
// parent_id: null
// created_at: null

const formatResponseData = data => {
  const array = data.map(e => {
    return {
      id: e.id,
      name: e.name,
      factor: e.factors.find(f => f.id !== 1 && f.id !== 2) || null,
      // stupidEmptyColumn: null,
      allowSub: e.is_allowed,
      actions: true,
      // not displayable
    }
  })
  return array
}

const formatSubData = data => {
  const array = data.map(e => {
    return {
      id: e.id,
      name: e.name,
      stupidEmptyColumn: null,
      factor: e.factors.find(f => f.id !== 1 && f.id !== 2) || null,
      // allowSub: e.is_allowed,
      actions: true,
      // not displayable
    }
  })
  return array
}

export default function categories(state = initialState, action) {
  let array
  switch (action.type) {
    case types.GET_CATEGORIES_SUCCESS:
      array = formatResponseData(action.payload.data)
      console.log("array = ", array)
      return {
        ...state,
        array: array,
        meta: action.payload.meta,
      }
    case types.GET_SUB_CATEGORIES_SUCCESS:
      array = formatSubData(action.payload.data)
      console.log("array = ", array)
      return {
        ...state,
        subArray: array,
        meta: action.payload.meta,
      }
    default:
      return state
  }
}
