import React from "react"
import { Switch, Route } from "react-router-dom"
import { PageContainer } from "../styledComponents"
import {
  setDeleteModal,
  setRestoreModal,
  setEditModal,
  setShowDeleted,
  setAddModal,
  getTemplatesRequest,
  createTemplateRequest,
  deleteTemplateRequest,
  setCurrentPageTemplates,
  restoreTemplateRequest,
  editTemplateRequest,
  resetCreateTemplate,
  clearCreateTemplateStatus,
  setShowSub,
} from "../../../store/actions"
import { connect } from "react-redux"
import Create from "./Create"
import Body from "./Body"

const mapState = state => ({
  deleted: state.deleted,
  modals: state.modals,
  templates: state.templates,
  getTemplatesStatus: state.statuses.getTemplates,
  getVehiclesStatus: state.statuses.getVehicles,
  createRequestStatus: state.statuses.createTemplate,
  createRequestError: state.errors.createTemplate,
  currentPage: state.currentPages.templates,
  sub: state.sub,
})

const mapDispatch = {
  setDeleteModal,
  setRestoreModal,
  setEditModal,
  resetCreateTemplate,
  setShowDeleted,
  setAddModal,
  getTemplatesRequest,
  createTemplateRequest,
  deleteTemplateRequest,
  restoreTemplateRequest,
  editTemplateRequest,
  setCurrentPageTemplates,
  clearCreateTemplateStatus,
  setShowSub,
}

const ConnectedBody = connect(mapState, mapDispatch)(Body)

const Templates = props => {
  return (
    <PageContainer noPadding>
      <Switch>
        <Route exact path="/templates/" component={ConnectedBody} />
        <Route path="/templates/create" component={Create} />
        <Route path="/templates/edit" component={Create} />
        <Route render={() => <div> Sorry, this page does not exist. </div>} />
      </Switch>
    </PageContainer>
  )
}

export default Templates
