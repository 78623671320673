import React from "react"
import { connect } from "react-redux"
import { SimpleButton } from "../../../../components/Buttons"
import {
  setAssignModal,
  setDeleteModal,
  setRestoreModal,
} from "../../../../store/actions"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash, faCalendarCheck } from "@fortawesome/free-solid-svg-icons"
import { StyledAction } from "../../styledComponents"
import classes from "../../classes.module.css"

const Actions = ({
  pending,
  deleted,
  setAssignModal,
  setDeleteModal,
  setRestoreModal,
}) => {
  if (deleted.show) {
    return (
      <StyledAction>
        <SimpleButton
          title="Restore"
          onClick={() => setRestoreModal(true, pending.id)}
        />
      </StyledAction>
    )
  }
  return (
    <StyledAction>
      <FontAwesomeIcon
        icon={faCalendarCheck}
        className={classes.calendarCheck}
        onClick={() => setAssignModal(true, pending.id, pending)}
      />
      <FontAwesomeIcon
        icon={faTrash}
        className={classes.trash}
        onClick={() => setDeleteModal(true, pending.id)}
      />
    </StyledAction>
  )
}

const mapDispatch = {
  setAssignModal,
  setDeleteModal,
  setRestoreModal,
}

const mapState = state => ({
  deleted: state.deleted,
})

export default connect(mapState, mapDispatch)(Actions)
