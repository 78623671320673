import { put, call, select } from "redux-saga/effects"

import * as actions from "../../actions"
import axios from "axios"

const getToken = state => state.auth.token
const getPage = state => state.currentPages.technicians
const getShowDeleted = state => state.deleted.show

const apiCall = ({ id, company_id }, token) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_PATH,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const path = `/dashboard/technicians/${id}/assign-company`
  return instance
    .patch(path, { company_id })
    .then(response => response)
    .catch(error => error.response)
}

export default function* assignPendingAsync(action) {
  try {
    const token = yield select(getToken)
    const { id, company_id } = action.payload

    const res = yield call(apiCall, { id, company_id }, token)

    console.log(" res = ", res)
    if (res.status === 200) {
      yield put(actions.assignPendingSuccess())
      const getParams = {
        page: yield select(getPage),
        soft_deleted: yield select(getShowDeleted),
        search: null,
        sort: "id",
      }
      yield put(actions.getPendingRequest(getParams))
    } else yield put(actions.assignPendingFailure(res.data))
  } catch (err) {
    yield put(actions.assignPendingFailure(err))
  }
}
