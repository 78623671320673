import React from "react"
import styled from "styled-components"
import theme from "../style/theme"

const SimpleModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  align-items: center;
  // justify-content: center;
  height: 12vh;
`

const StyledVal = styled.div`
  font-size: ${theme.font.md};
  color: ${theme.colors.blackMain};
  font-weight: 400;
  margin-top: 20px;
  width: 100%;
  text-align: left;
`

export default function ({ text = "Simple Modal" }) {
  return (
    <SimpleModalContainer>
      <StyledVal>{text}</StyledVal>
    </SimpleModalContainer>
  )
}
