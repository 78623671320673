import React from "react"
import styled from "styled-components"
import { FullPageSpinner } from "./MySpinner"

const FullSizeCenter = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`

const ErrorMessage = styled.h2`
  color: #666;
  font-weight: 400;
  font-size: 30px;
`

const NoItems = styled.h2`
  color: #ddd;
  font-weight: 400;
  font-size: 30px;
`

const ContentDisplay = ({ status, data }) => {
  let finalStatus
  let content

  if (Array.isArray(status)) {
    if (status.some(e => e === "failure")) finalStatus = "failure"
    else if (status.some(e => e === "loading")) finalStatus = "loading"
    else finalStatus = "success"
  } else finalStatus = status

  switch (finalStatus) {
    case "loading":
      content = <FullPageSpinner />
      break
    case "failure":
      content = (
        <FullSizeCenter>
          <ErrorMessage>Error Occured</ErrorMessage>
        </FullSizeCenter>
      )
      break
    default:
      if (Array.isArray(data) && data.length === 0) {
        content = (
          <FullSizeCenter>
            <NoItems>Nothing to Display</NoItems>
          </FullSizeCenter>
        )
      } else content = data
      break
  }

  return content
}

export default ContentDisplay
