import React, { useState } from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import SubEditor from "./Sub"
import StepEditor from "./Step"

const RightBLock = styled.div`
  display: flex;
  flex: 3;
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  padding: 0;
  background: white;
  // background: #f8f8f8;
`

const CenteredBlock = styled(RightBLock)`
  align-items: center;
  justify-content: center;
`

const Message = styled.div`
  font-size: 1.5rem;
  padding: 0 30px 0 30px;
  color: #666;
  font-weight: 500;
`

const PleaseChooseBlock = () => {
  return (
    <CenteredBlock>
      <Message>Please, select a Category</Message>
    </CenteredBlock>
  )
}

const RightEditor = ({ createTemplate }) => {
  if (!createTemplate.currentCategory || createTemplate.currentCategory.isEmpty)
    return <PleaseChooseBlock />

  const { currentCategory } = createTemplate
  if (currentCategory.allowSub) {
    return (
      <RightBLock>
        <SubEditor />
      </RightBLock>
    )
  }

  return (
    <RightBLock>
      <StepEditor categoryName={currentCategory.name} />
    </RightBLock>
  )
}

const connectState = state => ({
  createTemplate: state.createTemplate,
})

const connectActions = {}
export default connect(connectState, connectActions)(RightEditor)
