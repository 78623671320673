import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTimes,
  faCheckCircle,
  faInfo,
  faSpinner,
  faEllipsisH,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons"
import classes from "./classes.module.css"
import styled from "styled-components"

const Message = styled.h2`
  font-size: 1.1rem;
  color: #888;
  font-weight: 400;
  text-align: left;
  display: flex;
  flex: 1;
  // margin-top: 10px;
  // margin: 5px;
`

const Close = ({ onClick }) => (
  <FontAwesomeIcon
    onClick={onClick}
    icon={faTimes}
    className={classes.closeAlert}
  />
)

const Success = () => (
  <FontAwesomeIcon icon={faCheckCircle} className={classes.successAlert} />
)
const Info = () => (
  <FontAwesomeIcon icon={faEllipsisH} className={classes.infoAlert} />
)
const Error = () => (
  <FontAwesomeIcon icon={faExclamationCircle} className={classes.errorAlert} />
)

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  background-color: #fefefe;
  justify-content: space-between;
`

const AlertTemplate = ({ style, options, message, close }) => (
  <div
    style={{
      ...style,
      borderRadius: 2,
      padding: "10px 10px",
      display: "flex",
      alignItems: "center",
      backgroundColor: "white",
      justifyContent: "center",
      boxShadow: "0 0 10px rgba(0,0,0,0.2)",
    }}
  >
    <InnerContainer>
      {options.type === "info" && <Info />}
      {options.type === "success" && <Success />}
      {options.type === "error" && <Error />}
      <Message>{message}</Message>
      <Close onClick={close}></Close>
    </InnerContainer>
  </div>
)

export default AlertTemplate
