import React, { useState, useEffect } from "react"
import {
  Row,
  Center,
  StyledTemplateSelect,
  ChooseTemplatesInner,
  ChooseTemplatesContainer,
  Vehicle,
} from "./styled"
import MySelect from "../../../../components/MySelect"
import {
  getVehiclesRequest,
  getTemplatesRequest,
} from "../../../../store/actions"
import { SimpleButton } from "../../../../components/Buttons"
import { makeOption } from "../../../../helpers"
import { connect } from "react-redux"

const TemplateSelect = ({
  vehicle,
  isLoading,
  onFetch,
  templateOptions,
  onChange,
}) => {
  return (
    <StyledTemplateSelect>
      <Center>
        <Vehicle>{vehicle.label}</Vehicle>
      </Center>
      <Row>
        <MySelect
          onMenuOpen={() => onFetch(vehicle)}
          isLoading={isLoading}
          title="Template"
          options={isLoading ? [] : templateOptions}
          name="Template"
          placeholder={"Choose"}
          onChange={onChange}
        />
      </Row>
    </StyledTemplateSelect>
  )
}

export const AssignTemplates = ({
  setSelectedTemplates,
  selectedTemplates,
  getVehicles,
  storedVehicles,
  getTemplates,
  getTemplatesStatus,
  storedTemplates,
}) => {
  const [vehicleOptions, setVehicleOptions] = useState([])
  const [targetVehicle, setTargetVehicle] = useState(null)
  const [templateOptions, setTemplateOptions] = useState([])

  useEffect(() => {
    const arr = storedTemplates.map(e => makeOption(e.name, e.id))
    arr.unshift(makeOption("none", null))
    setTemplateOptions(arr)
  }, [storedTemplates])

  const handleFetchTemplates = vehicle => {
    setTargetVehicle(vehicle)
    getTemplates({
      vehicle_type_id: vehicle.id,
      paginate: 10000,
      search: null,
      page: 0,
      sort: "id",
      soft_deleted: 0,
    })
  }

  useEffect(() => {
    getVehicles({
      paginate: 10000,
      search: null,
      page: 0,
      sort: "id",
      soft_deleted: 0,
    })
  }, [])

  const handleChange = event => {
    console.log("event == ", event)
    if (!event || !event.id || event.id === "none") {
      return
    }
    setSelectedTemplates([...selectedTemplates, event.id])
  }
  useEffect(() => {
    const arr = storedVehicles.map(e => makeOption(e.name, e.id))
    setVehicleOptions(arr)
  }, [storedVehicles])

  return (
    <ChooseTemplatesContainer>
      <ChooseTemplatesInner>
        {vehicleOptions.map((e, index) => (
          <TemplateSelect
            key={index}
            onFetch={handleFetchTemplates}
            vehicle={e}
            onChange={handleChange}
            isLoading={
              targetVehicle &&
              targetVehicle.id === e.id &&
              getTemplatesStatus === "loading"
            }
            templateOptions={templateOptions}
          />
        ))}
      </ChooseTemplatesInner>
    </ChooseTemplatesContainer>
  )
}

const connectState = state => ({
  getTemplatesStatus: state.statuses.getTemplates,
  storedVehicles: state.vehicles.array,
  storedTemplates: state.templates.array,
})

const connectActions = {
  getVehicles: getVehiclesRequest,
  getTemplates: getTemplatesRequest,
}

export default connect(connectState, connectActions)(AssignTemplates)
