import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Editing from "./Editing"
import { StyledField } from "../../styled"

const CategoryItem = ({ item, array, onUpdate, onRemove, pushStore }) => {
  const handleSubmit = () => {
    if (!item) return
    onUpdate({ ...item, editing: false })
    pushStore()
  }

  const handleStartEditing = () => {
    onUpdate({ ...item, editing: true })
  }

  if (item.editing)
    return (
      <Editing
        item={item}
        array={array}
        onRemove={onRemove}
        onUpdate={onUpdate}
        onSubmit={handleSubmit}
      />
    )
  return (
    <StyledField onClick={handleStartEditing}>
      {item.subcategory ? item.subcategory.name : "Unknown"}
    </StyledField>
  )
}

export default CategoryItem
