import React from "react"
import styled from "styled-components"
import theme from "../style/theme"
import classes from "./classes.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"

const StyledTitle = styled.h2`
  font-size: ${theme.font.lg};
  color: ${theme.colors.blackMain};
  margin: 0;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const PageTitle = ({ title, showBack, onClickBack = null }) => {
  return (
    <Container>
      {showBack && onClickBack && (
        <FontAwesomeIcon
          icon={faArrowLeft}
          className={classes.back}
          onClick={onClickBack}
        />
      )}
      <StyledTitle>{title}</StyledTitle>
    </Container>
  )
}

export default PageTitle
