import React, { useState, useEffect } from "react"
import MySwitch from "../../../../components/MySwitch"
import MySearchInput from "../../../../components/MySearchInput"
import PageTitle from "../../../../components/PageTitle"
import Table from "./Table"
import { PageContainer, StyledToolBar, ToolGroup } from "../../styledComponents"
import { NewButton } from "../../../../components/Buttons"
import AddModal from "../Actions/AddModal"
import RenderModal from "../../../../components/RenderModal"
// import MyPagination from "../../../../components/MyPagination";
import { useAlert } from "react-alert"
import SimpleModal from "../../../../components/SimpleModal"

const VehicleTypes = props => {
  const [search, setSearch] = useState(null)
  const [newVehicle, setNewVehicle] = useState(null)
  const [editedVehicle, setEditedVehicle] = useState(null)
  const alert = useAlert()

  const {
    setShowDeleted,
    deleted,
    getVehiclesRequest,
    getVehiclesStatus,
    vehicles,
    currentPage,
    setCurrentPageVehicles,
    setDeleteModal,
    setEditModal,
    setRestoreModal,
    modals,
    editVehicleRequest,
    restoreVehicleRequest,
    deleteVehicleRequest,
    createVehicleRequest,
    clearCreateVehicleStatus,
    createRequestStatus,
    createRequestError,
  } = props

  const getData = (page = null, search = null) => {
    const params = {
      page: page !== null ? page : currentPage,
      soft_deleted: deleted.show,
      search,
      sort: "name",
    }
    getVehiclesRequest(params)
  }

  useEffect(() => {
    if (createRequestStatus === "loading") {
      alert.show("Sending")
    }
    if (createRequestStatus === "success") {
      alert.success("Vehicle created!")
    }

    if (createRequestStatus === "failure") {
      // alert.error("Error creating Technician");
      Object.values(createRequestError).forEach(e => {
        alert.error(e)
      })
    }
    clearCreateVehicleStatus()
  }, [createRequestStatus])

  const handleEdit = () => {
    console.log("editedVehicle = ", editedVehicle)
    editVehicleRequest(editedVehicle, modals.edit.id)
  }

  const handleCreate = () => {
    console.log("newVehicle = ", newVehicle)
    createVehicleRequest(newVehicle)
  }

  const handleDelete = () => {
    deleteVehicleRequest(modals.delete.id)
  }

  const handleRestore = () => {
    restoreVehicleRequest(modals.restore.id)
  }

  useEffect(() => {
    getData()
  }, [deleted])

  const handleChangePage = event => {
    setCurrentPageVehicles(event.selected)
    getData(event.selected, search)
  }

  const handleSearch = e => {
    const query = e.target.value
    setSearch(query)
    getData(null, query)
  }

  return (
    <PageContainer>
      <RenderModal
        label="Add New Vehicle"
        onConfirm={handleCreate}
        render={() => <AddModal callback={setNewVehicle} />}
        active={props.modals.showAdd}
        setActive={props.setAddModal}
      />
      <RenderModal
        label={`Edit ${modals.edit.selected.name}`}
        render={() => (
          <AddModal
            callback={setEditedVehicle}
            editData={modals.edit.selected}
          />
        )}
        active={modals.edit.show}
        onConfirm={handleEdit}
        setActive={setEditModal}
      />
      <RenderModal
        alignButtonsRight
        label="Confirmation"
        render={() => (
          <SimpleModal text="Are you sure you want to delete Vehicle?" />
        )}
        onConfirm={handleDelete}
        active={modals.delete.show}
        setActive={setDeleteModal}
      />
      <RenderModal
        alignButtonsRight
        label="Confirmation"
        render={() => (
          <SimpleModal text="Are you sure you want to restore Vehicle?" />
        )}
        active={modals.restore.show}
        setActive={setRestoreModal}
        onConfirm={handleRestore}
      />
      <PageTitle title="Vehicle Types" />
      <StyledToolBar>
        <MySearchInput value={search} onChange={handleSearch} />
        <ToolGroup>
          {/* <MyPagination
                        current={currentPage}
                        pageCount={vehicles.meta.last_page}
                        onChangePage={handleChangePage}
                    /> */}
        </ToolGroup>
        <MySwitch
          label="Show Deleted"
          active={deleted.show}
          setActive={setShowDeleted}
        />
        <NewButton onClick={() => props.setAddModal(true)} />
      </StyledToolBar>
      <Table
        onChangePage={handleChangePage}
        data={vehicles.array}
        status={getVehiclesStatus}
        vehicles={vehicles}
      />
    </PageContainer>
  )
}

export default VehicleTypes
