import React from "react"
import { SimpleButton } from "../../../../../components/Buttons"
import { SimpleInput } from "../../../../../components/Inputs"
import styled from "styled-components"

const StyledStepModal = styled.div`
  width: 200px;
  height: 100px;
  padding: 10px 0 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Row = styled.div`
  width: 130%;
  display: flex;
`

const StepModal = ({ name, setName, close }) => {
  return (
    <StyledStepModal>
      <Row>
        <SimpleInput
          margin={false}
          title="Name"
          value={name}
          name="name"
          onChange={setName}
        />
      </Row>
    </StyledStepModal>
  )
}

export default StepModal
