import axios from "axios"

/**
 * Get packages items
 *
 * @param token
 * @param filters
 * @param page
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getItems = token => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/packages`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(response => {
      return response.data
    })
}

export const getItem = (token, id) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/package/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(response => {
      return response.data
    })
}

/**
 * Update package.
 * @param token
 * @param id
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
export const updatePackage = (token, id, data) => {
  return axios
    .put(`${process.env.REACT_APP_API_PATH}/package/${id}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(response => {
      return response.data
    })
}
