import types from "../types"

const initialState = {
  show: false,
  selected: { name: "" },
}

export default function sub(state = initialState, action) {
  switch (action.type) {
    case types.SET_SHOW_SUB:
      return {
        ...state,
        show: action.payload.bool,
        selected: action.payload.obj,
      }
    default:
      return state
  }
}
