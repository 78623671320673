import React from "react"
import Select from "react-select"
import classes from "./classes.module.css"
import theme from "../style/theme"
import styled from "styled-components"

const StyledSimpleContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 5%;
  margin-right: 5%;
  width: 100%;
`

const StyledHead = styled.h3`
	margin-bottom: 2%;
	font-size: ${theme.font.sm};
	font-weight: ${theme.font.regular}
	color: ${theme.colors.blackMain}
`

const myStyle = flexibleHeight => ({
  control: (base, state) => ({
    ...base,
    // height: "30px",
    minHeight: "30px",
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: theme.font.sm,
    border: `0.5px solid ${theme.colors.borderMain}`,
  }),
  dropdownIndicator: base => ({
    ...base,
    padding: 4,
  }),
  menuList: base => ({
    ...base,
    "max-height": "200px",
  }),
  clearIndicator: base => ({
    ...base,
    padding: 4,
  }),
  multiValue: base => ({
    ...base,
  }),
  valueContainer: base => ({
    ...base,
    padding: "0px 6px",
  }),
  input: base => ({
    ...base,
    margin: 0,
    padding: 0,
  }),
  option: base => ({
    ...base,
    fontSize: theme.font.sm,
  }),
})

const MySelect = ({
  title,
  options,
  name,
  onChange,
  placeholder = "",
  disabled = false,
  isLoading = false,
  isMulti = false,
  flexibleHeight = false,
  defaultValue = null,
  isClearable = true,
  containerStyle = {},
  loadingMessage = () => "loading",
  ...rest
}) => {
  return (
    <StyledSimpleContainer style={containerStyle}>
      <StyledHead>{title}</StyledHead>
      <Select
        className={classes.simpleSelect}
        classNamePrefix={classes.select}
        defaultValue={defaultValue}
        styles={myStyle(flexibleHeight)}
        placeholder={placeholder}
        loadingMessage={loadingMessage}
        isClearable={isClearable}
        isSearchable={true}
        isMulti={isMulti}
        isLoading={isLoading}
        name={name}
        onChange={onChange}
        options={options}
        components={{
          IndicatorSeparator: () => null,
        }}
        {...rest}
      />
    </StyledSimpleContainer>
  )
}

export default MySelect
