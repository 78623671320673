import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { SimpleButton } from "../../../../../../components/Buttons"
import RenderModal from "../../../../../../components/RenderModal"
import SimpleModal from "../../../../../../components/SimpleModal"
import MySelect from "../../../../../../components/MySelect"
import { makeOptionWidthData } from "../../../../../../helpers"
import {
  setTemplateCurrentCategory,
  getSubCategoriesOptionsRequest,
} from "../../../../../../store/actions"
import ImageModal from "./ImageModal"
import StepBlock from "../Steps"
import StepModal from "../StepModal"

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
`

const StyledEditing = styled.div`
  margin-top: 15px;
  display: flex;
  width: 100%;
  height: 350px;
  align-items: start;
  flex-direction: column;
  padding: 15px;

  box-sizing: border-box;
  background: #eee;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
`
const Row = styled.div`
  width: 100%;
  display: flex;
`

const InnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`
const Col = styled.div`
    display: flex;
    flex-direction: column;
    flex: ${props => (props.flex ? props.flex : 1)}
    width: 100%;
    margin: 20px;
    align-items: center;
    justify-content: ${props => (props.colCenter ? "center" : "start")};
    // margin-bottom: 5px;
`

const emptyArea = []

const Editing = ({
  onSubmit,
  item,
  currentCategory,
  setTemplateCurrentCategory,
  subCategories,
  onUpdate,
  array,
  onRemove,
  getSubCategoriesStatus,
  getSubCategoriesOptionsRequest,
}) => {
  const [imageModal, setImageModal] = useState(false)
  const [stepModal, openStepModal] = useState(false)
  const [sub, setSub] = useState(item)
  const [step, setStep] = useState("")
  const [areas, setAreas] = useState(item.areas)
  const [steps, setSteps] = useState(item.steps)
  const [removeModal, setRemoveModal] = useState({
    open: false,
    target: null,
    name: "",
  })
  const handlePushStep = () => {
    if (step === "") return
    setStep("")
    setSteps([...steps, step])
  }

  const handleDeleteStep = () => {
    setSteps(steps.filter((e, index) => index !== removeModal.target))
  }
  const handleSubmit = () => {
    console.log("sub === ", sub)
    if (!subSelected() || !areas) return
    onSubmit()
  }

  const getDefaultCategory = () => {
    if (!sub || !sub.subcategory || sub.subcategory.id === null) return null
    return makeOptionWidthData(sub.subcategory.name, sub)
  }

  useEffect(() => {
    const pack = {
      ...item,
      steps,
    }

    onUpdate(pack)
  }, [steps])

  useEffect(() => {
    const pack = {
      ...item,
      areas,
    }
    onUpdate(pack)
  }, [areas])

  useEffect(() => {
    console.log("sub == ", sub)
    if (!sub || !sub.id) return
    const pack = {
      ...item,
      editing: true,
      subcategory: { name: sub.name, id: sub.id },
    }
    onUpdate(pack)
  }, [sub])

  const handleChangeSub = option => {
    if (!option) return
    setSub(option.data)
  }

  const handleChangeArea = a => {
    setAreas(a)
  }

  const subSelected = () => {
    return (sub && sub.id) || (sub && sub.subcategory && sub.subcategory.id)
  }

  const getSubOptions = () => {
    const storedSubcategories = currentCategory.subcategories

    return subCategories
      .filter(
        f =>
          !storedSubcategories ||
          !storedSubcategories.some(s => f.id === s.subcategory.id),
      ) // limit reselect
      .map(e => makeOptionWidthData(e.name, e))
  }

  const getSubRawData = () => {
    getSubCategoriesOptionsRequest(currentCategory.id)
  }

  return (
    <StyledEditing>
      <RenderModal
        alignButtonsRight
        label="Confirmation"
        onConfirm={handleDeleteStep}
        render={() => <SimpleModal text={`Remove [${removeModal.name}]?`} />}
        active={removeModal.open}
        setActive={() =>
          setRemoveModal({ open: false, target: null, name: "" })
        }
      />
      <RenderModal
        label="Model"
        render={() => (
          <ImageModal
            sub={sub}
            areas={areas}
            setAreas={handleChangeArea}
            model={currentCategory.model}
          />
        )}
        active={imageModal}
        setActive={setImageModal}
      />
      <RenderModal
        label="Add Step"
        render={() => (
          <StepModal
            close={() => openStepModal(false)}
            name={step}
            setName={setStep}
          />
        )}
        onConfirm={handlePushStep}
        active={stepModal}
        setActive={openStepModal}
      />
      <InnerContainer>
        <Col flex={1} colCenter>
          <Row>
            <MySelect
              options={getSubOptions()}
              defaultValue={
                getSubCategoriesStatus === "loading" ? [] : getDefaultCategory()
              }
              onMenuOpen={getSubRawData}
              isLoading={getSubCategoriesStatus === "loading"}
              title="Sub Category"
              name="Sub Category"
              isClearable={false}
              placeholder="Select"
              onChange={handleChangeSub}
            ></MySelect>
          </Row>
          <SimpleButton
            scalable
            highlight={areas}
            title="Highlight Areas"
            color="#E8C77A"
            onClick={() => subSelected() && setImageModal(true)}
          />
          <BtnContainer>
            <SimpleButton
              title="Remove"
              color="crimson"
              onClick={() => onRemove(item.store_id)}
            />
            <SimpleButton title="Ok" color="#559966" onClick={handleSubmit} />
          </BtnContainer>
        </Col>
        <Col flex={2}>
          <SimpleButton
            scalable
            title="+ Add Step"
            color="#555555"
            onClick={() => openStepModal(true)}
          />
          <StepBlock
            onRemove={target =>
              setRemoveModal({
                open: true,
                target: target.id,
                name: target.name,
              })
            }
            steps={steps}
          />
        </Col>
      </InnerContainer>
      {/* <FontAwesomeIcon onClick={() => {}} icon={faPlusSquare} className={classes.new} /> */}
    </StyledEditing>
  )
}

const connectState = state => ({
  createTemplate: state.createTemplate,
  subCategories: state.categories.subArray,
  getSubCategoriesStatus: state.statuses.getSubCategories,
  currentCategory: state.createTemplate.currentCategory,
})

const connectActions = {
  setTemplateCurrentCategory,
  getSubCategoriesOptionsRequest,
}

export default connect(connectState, connectActions)(Editing)
