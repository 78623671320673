import React from "react"
import Item from "./Item"
import Header from "./Header"
import { TableContainer, StyledTableBody } from "../../styledComponents"
import ContentDisplay from "../../../../components/ContentDisplay"
import MyPagination from "../../../../components/MyPagination"

const Table = ({ onChangePage, vehicles, currentPage }) => {
  const { status, array: data, meta } = vehicles
  return (
    <TableContainer>
      <Header />
      <StyledTableBody>
        <ContentDisplay
          status={status}
          data={data.map((e, index) => (
            <Item key={index} vehicle={e} />
          ))}
        ></ContentDisplay>
        <MyPagination
          current={currentPage}
          pageCount={meta.last_page}
          onChangePage={onChangePage}
        />
      </StyledTableBody>
    </TableContainer>
  )
}

export default Table
