import styled from "styled-components"
import theme from "../../style/theme"

export const StyledTableItem = styled.div`
  //   width: 100%;
  padding: 15px 8px 15px 8px;
  box-sizing: border-box;
  background: white;
  border-radius: 2px;
  margin-bottom: 4px;
  align-items: center;
  justify-content: center;
  display: flex;
`

export const FullSizeCenter = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`

export const ErrorMessage = styled.h2`
  color: crimson;
`

export const StyledField = styled.div`
flex: ${props => props.flex};
font-size: ${theme.font.md};
color: ${theme.colors.blackMain}
font-weight: 500;
display: flex;
// width: 100%;
// word-break: break-all; 
height: 40px;
overflow: hidden;
// border: 1px solid red;
align-items: center;
justify-content: center;
`

export const StyledFieldCentered = styled(StyledField)`
  justify-content: center;
  background: blue;
`

export const StyledAction = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`

export const TableContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 20px 0 20px 0;
  width: 100%;
  height: 100%;
`

export const SubContainer = styled(TableContainer)`
  padding-top: 10px;
`

export const StyledTableBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60%;
  border-radius: 2px;
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 0 10px 10px 10px;
  // background: ${theme.colors.greyLight};
  background: #f7f7f7;
`

export const StyledTableHeader = styled.div`
  width: 100%;
  box-sizing: border-box;
  // background: ${theme.colors.greyLight};
  background: #f7f7f7;
  padding: 8px 20px 8px 18px;
  display: flex;
  border-radius: 4px 4px 0 0;
`

export const StyledLabel = styled.div`
  flex: ${props => props.flex};
  font-size: ${theme.font.xs};
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: ${props => (props.noPadding ? 0 : "4%")};
  box-sizing: border-box;
  overflow-y: hidden;
`

export const StyledToolBar = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 2px 10px 4px;
  justify-content: space-between;
`

export const ToolGroup = styled.div`
  display: flex;
  align-items: center;
  width: 55%;
  justify-content: space-between;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`

export const FlexRow = styled.div`
    display: flex;
    flex: ${props => (props.flex ? props.flex : 1)}
    align-items: center;
    justify-content: center;
    // height: 100%;
`

export const StyledDropDown = styled.div`
  margin: 10px 2px 10px 0;
`

export const StyledTextAreat = styled.div`
  margin: 20px 0 10px;
  min-width: 300px;
`
