import types from "../types"

const initialMeta = {
  last_page: 1,
}
const initialState = {
  currentFactor: null,
  array: [],
  subArray: [],
  meta: initialMeta,
}

// id: 1
// name: "Recommendation"
// parent_id: null
// created_at: null

const formatResponseData = data => {
  const array = data.map(e => {
    return {
      id: e.id,
      name: e.name,
      stupidEmptyColumn: null,
      parent_id: e.parent_id,
      actions: true,
      type: e.type,
      options: e.options,
      // not displayable
    }
  })
  return array
}

const formatSubData = data => {
  // const array = data.map(e => {
  //     return {
  //         id: e.id,
  //         name: e.name,
  //         stupidEmptyColumn: null,
  //         parent_id: e.parent_id,
  //         actions: true
  //         // not displayable
  //     };
  // });
  return data
}

export default function factors(state = initialState, action) {
  let array
  switch (action.type) {
    case types.GET_FACTORS_SUCCESS:
      array = formatResponseData(action.payload.data)
      console.log("array = ", array)
      return {
        ...state,
        array: array,
        meta: action.payload.meta,
      }
    case types.GET_ONE_FACTOR_SUCCESS:
      return {
        ...state,
        currentFactor: action.payload.data,
      }
    case types.GET_SUB_FACTORS_SUCCESS:
      array = formatSubData(action.payload.data)
      console.log("array = ", array)
      return {
        ...state,
        subArray: array,
      }
    default:
      return state
  }
}
