import React, { useState, useEffect } from "react"
import { Badge } from "antd"
import styled from "styled-components"
import { Button } from "antd"

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const StyledTHead = styled.thead`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  color: #a4a4b4;
  background-color: #e1eae5;
  td {
    padding: 13px;
  }
`

const StyledTBody = styled.tbody`
  font-size: 0.65rem;
`

const StyledTr = styled.tr`
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #f5f5f7;
  td {
    padding: 13px;
  }
  :nth-child(even) {
    background-color: #f2f2f2;
  }
  :hover {
    background-color: #d0d0d0;
    cursor: pointer;
  }
`

const StatusCell = styled.td`
  .ant-badge-status-text {
    font-size: 0.65rem;
  }
`

const PaymentDetails = props => {
  const [rows, setRows] = useState([])

  useEffect(() => {
    const mockRows = []
    for (let i = 0; i < 2; i++) {
      mockRows.push({
        appointmentId: "#123456789",
        transactionId: "#123456777",
        date: "06/28/2020",
        name: "John Doe",
        type: i % 2 ? "Split" : "Full",
        status:
          i % 3 ? (
            <Badge color="#87d068" text="Success" />
          ) : (
            <Badge color="#f50" text="Filed" />
          ),
      })
    }

    setRows(mockRows)
  }, [])

  return (
    <StyledTable>
      <StyledTHead>
        <tr>
          <td>Appointment ID</td>
          <td>Transavtion #</td>
          <td>Time and Date</td>
          <td>Payer name</td>
          <td>Type</td>
          <td>Status</td>
          <td>Actions</td>
        </tr>
      </StyledTHead>
      <StyledTBody>
        {rows &&
          rows.map((item, index) => {
            return (
              <StyledTr key={`appointment-${index}`}>
                <td style={{ width: "150px" }}>{item.appointmentId}</td>
                <td>{item.transactionId}</td>
                <td style={{ width: "150px" }}>{item.date}</td>
                <td style={{ width: "150px" }}>{item.name}</td>
                <td style={{ width: "100px" }}>{item.type}</td>
                <StatusCell style={{ width: "100px" }}>
                  {item.status}
                </StatusCell>
                <td style={{ width: "100px" }}>
                  <Button type="primary">retweet</Button>
                </td>
              </StyledTr>
            )
          })}
      </StyledTBody>
    </StyledTable>
  )
}

export default PaymentDetails
