import React from "react"
import Body from "./Body"

import {
  setShowDeleted,
  setAddModal,
  setShowSub,
  getCompaniesRequest,
  setCurrentPageCompanies,
  setViewModal,
  setEditModal,
  setDeleteModal,
  setRestoreModal,
  deleteCompanyRequest,
  restoreCompanyRequest,
  createCompanyRequest,
  editCompanyRequest,
  clearCreateCompanyStatus,
} from "../../../store/actions"
import { connect } from "react-redux"

const mapState = state => ({
  deleted: state.deleted,
  modals: state.modals,
  sub: state.sub,
  currentPage: state.currentPages.companies,
  companies: state.companies,
  getCompaniesStatus: state.statuses.companies,
  createRequestStatus: state.statuses.createCompany,
  createRequestError: state.errors.createCompany,
})

const mapDispatch = {
  setShowDeleted,
  setAddModal,
  setShowSub,
  getCompaniesRequest,
  setCurrentPageCompanies,
  setDeleteModal,
  setEditModal,
  setViewModal,
  setRestoreModal,
  deleteCompanyRequest,
  restoreCompanyRequest,
  createCompanyRequest,
  editCompanyRequest,
  clearCreateCompanyStatus,
}

const Companies = props => {
  return <Body {...props} />
}

export default connect(mapState, mapDispatch)(Companies)
