import React, { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons"
import styled from "styled-components"
import theme from "../../../../style/theme"
import classes from "../../classes.module.css"
import { connect } from "react-redux"
import { getVehiclesForSelectRequest } from "../../../../store/actions"

const Container = styled.div`
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: center;
`

const StyledField = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  background: ${props => (props.selected ? "white" : "#A6C4AE")};
  color: ${props => (props.selected ? theme.colors.blackMain : "white")};
  font-size: ${theme.font.md};

  cursor: pointer;
`

const VehiclePicker = ({
  selected,
  setSelected,
  getVehiclesForSelectRequest,
  vehicles,
}) => {
  const [options, setOptions] = useState([])

  useEffect(() => {
    setOptions(
      vehicles
        .map(e => ({ name: e.name, id: e.id }))
        .sort((a, b) => a.id - b.id),
    )
    if (selected === null && vehicles.length > 0) setSelected(vehicles[0].id)
  }, [vehicles])

  useEffect(() => {
    getVehiclesForSelectRequest()
  }, [])

  return (
    <Container>
      {/* <FontAwesomeIcon
                icon={faAngleLeft}
                className={classes.arrow}
                // onClick={() => setEditModal(true)}
            /> */}

      {options.map(e => (
        <StyledField
          key={e.id}
          onClick={() => setSelected(e.id)}
          selected={selected === e.id}
        >
          {e.name}
        </StyledField>
      ))}
      {/* <FontAwesomeIcon
                icon={faAngleRight}
                className={classes.arrow}
                // onClick={() => setDeleteModal(true)}
            /> */}
    </Container>
  )
}

const connectState = state => ({ vehicles: state.vehicles.array })
const connectActions = { getVehiclesForSelectRequest }
export default connect(connectState, connectActions)(VehiclePicker)
