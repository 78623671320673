import React from "react"
import Actions from "../Actions"
import { StyledTableItem, StyledField } from "../../styledComponents"

const Field = ({ vehicle, field, index, type }) => {
  let content
  let flexScale
  if (index === 0) return null

  flexScale = 1
  if (type === "actions") {
    content = <Actions vehicle={vehicle} />
  } else if (type === "is_active") {
    content = field ? "Active" : "Inactive"
  } else content = field

  return <StyledField flex={flexScale}>{content}</StyledField>
}

const TableItem = ({ vehicle }) => {
  return (
    <StyledTableItem>
      {Object.keys(vehicle).map((e, index) => (
        <Field
          vehicle={vehicle}
          type={e}
          field={vehicle[e]}
          index={index}
          key={index}
        />
      ))}
    </StyledTableItem>
  )
}

export default TableItem
