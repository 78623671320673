import { put, call, select } from "redux-saga/effects"

import * as actions from "../../actions"
import axios from "axios"

const getToken = state => state.auth.token
const getShowDeleted = state => state.deleted.show
const apiCall = (id, token) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_PATH,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const path = `/dashboard/categories/${id}`
  return instance
    .delete(path)
    .then(response => response)
    .catch(error => error.response)
}

export default function* deleteCategoryAsync(action) {
  try {
    const token = yield select(getToken)
    const { id } = action.payload

    // const res = {};
    const res = yield call(apiCall, id, token)

    console.log(" res = ", res)
    if (res.status === 200) {
      yield put(actions.deleteCategorySuccess())
      if (action.payload.type === "sub") {
        const getPage = state => state.currentPages.subCategories
        const getParams = {
          page: yield select(getPage),
          soft_deleted: yield select(getShowDeleted),
          search: null,
          sort: "id",
        }
        yield put(actions.getSubCategoriesRequest(getParams))
      } else {
        const getPage = state => state.currentPages.categories
        const getParams = {
          page: yield select(getPage),
          soft_deleted: yield select(getShowDeleted),
          search: null,
          sort: "id",
        }
        yield put(actions.getCategoriesRequest(getParams))
      }
    } else yield put(actions.deleteCategoryFailure(res.data))
  } catch (err) {
    yield put(actions.deleteCategoryFailure(err))
  }
}
