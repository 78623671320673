import React, { useState, useEffect } from "react"
import { Checkbox } from "antd"
import { connect } from "react-redux"
import * as template from "../../../../../../../helpers/template"
import { Badge, Pagination } from "antd"
// import {assignCompany} from "../../../../../../../helpers/template";
import styled from "styled-components"

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const StyledTHead = styled.thead`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  color: #a4a4b4;
  background-color: #e1eae5;
  td {
    padding: 13px;
  }

  @media (max-width: 1470px) {
    font-size: 10px;
  }
`

const StyledTBody = styled.tbody`
  font-size: 0.65rem;
`

const StyledTr = styled.tr`
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #f5f5f7;
  td {
    padding: 13px;
  }
  :nth-child(even) {
    background-color: #f2f2f2;
  }
  :hover {
    background-color: #d0d0d0;
    cursor: pointer;
  }
`

const StatusCell = styled.td`
  .ant-badge-status-text {
    font-size: 0.65rem;
  }
`

const Table = props => {
  useEffect(() => {
    // load(1);
  }, [])

  const handleAssignTemplate = e => {
    props.onChangeAssign(props.companyId, e.target.value, e.target.checked)
    return true
  }

  return (
    <>
      <StyledTable>
        <StyledTHead>
          <tr>
            <td></td>
            <td>Template ID</td>
            <td>Name</td>
            <td>Vehicle Type</td>
            <td>Package</td>
          </tr>
        </StyledTHead>
        <StyledTBody>
          {props.items &&
            props.items.map((item, index) => {
              let status = <Badge color="#87d068" text="Awaiting Execution" />

              switch (item.status) {
                case 0:
                  status = <Badge color="#ffff00" text="Awaiting Approve" />
                  break
                case 1:
                  status = <Badge color="#66ccff" text="Awaiting Execution" />
                  break
                case 2:
                  status = <Badge color="#87d068" text="Inspected" />
                  break
                case 3:
                  status = <Badge color="#f50" text="Canceled" />
                  break
              }

              // console.log('aaa', props.companyId);
              const company = item.companies.find(
                company => parseInt(props.companyId) === company.id,
              )
              const assigned = typeof company !== "undefined"

              return (
                <StyledTr key={`appointment-${index}`}>
                  <td>
                    <Checkbox
                      checked={assigned}
                      onChange={handleAssignTemplate}
                      value={item.id}
                    />
                  </td>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item.vehicle_type.name}</td>
                  <td>{item.package ? item.package.name : "-"}</td>
                </StyledTr>
              )
            })}
        </StyledTBody>
      </StyledTable>
    </>
  )
}

const mapState = state => ({
  auth: state.auth,
})

export default connect(mapState)(Table)
