import React from "react"
import "./spinner.css"
import theme from "../../style/theme"
import styled from "styled-components"

const FullSizeCenter = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`

const MySpinner = () => {
  return (
    <div
      style={{ color: theme.colors.greenMain }}
      className="la-ball-clip-rotate la-2x"
    >
      <div></div>
    </div>
  )
}

export const FullPageSpinner = () => {
  return (
    <FullSizeCenter>
      <MySpinner></MySpinner>
    </FullSizeCenter>
  )
}

export default MySpinner
