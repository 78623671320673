import types from "../types"

const initialUser = {
  first_name: "Unknown",
  last_name: "User",
  image: null,
}

const initialState = {
  status: null,
  token: null,
  user: initialUser,
}

export default function auth(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return {
        status: "success",
        token: action.payload.token,
        user: action.payload.user,
      }
    case types.LOGIN_REQUEST:
      return {
        ...state,
        status: "loading",
      }
    case types.LOGIN_FAILURE:
      return {
        ...state,
        status: "failure",
      }
    case types.SIGN_OUT:
      return {
        status: null,
        token: null,
        user: initialUser,
      }
    case types.CLEAR_LOGIN_STATUS:
      return {
        ...state,
        status: null,
      }
    default:
      return state
  }
}
