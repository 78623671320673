import Technicians from "./Body"

import {
  setShowDeleted,
  setAddModal,
  setCurrentPageTechnicians,
  getTechniciansRequest,
  createTechnicianRequest,
  clearCreateTechnicianStatus,
  restoreTechnicianRequest,
  deleteTechnicianRequest,
  editTechnicianRequest,
  setRestoreModal,
  setEditModal,
  setDeleteModal,
} from "../../../store/actions"
import { connect } from "react-redux"

const mapState = state => ({
  deleted: state.deleted,
  modals: state.modals,
  technicians: state.technicians,
  currentPage: state.currentPages.technicians,
  createRequestStatus: state.statuses.createTechnician,
  errors: state.errors,
})

const mapDispatch = {
  setShowDeleted,
  setAddModal,
  setCurrentPageTechnicians,
  getTechniciansRequest,
  createTechnicianRequest,
  clearCreateTechnicianStatus,
  deleteTechnicianRequest,
  restoreTechnicianRequest,
  editTechnicianRequest,
  setDeleteModal,
  setRestoreModal,
  setEditModal,
}

export default connect(mapState, mapDispatch)(Technicians)
