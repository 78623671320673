import React from "react"
import {
  FirstView,
  SecondView,
  ThirdView,
  ViewContainer,
  Title,
  Text,
  StyledLine,
  BlockTitle,
} from "./styled"

const Line = ({ title, text }) => {
  return (
    <StyledLine>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </StyledLine>
  )
}

const TechnicianList = ({ array }) => {
  if (array.length <= 0) return <Text>Nothing to Display</Text>
  return array.map(e => (
    <Line title={e.user.last_name} text={e.user.first_name} />
  ))
}

const ViewModal = ({ company }) => {
  console.log("selected company == ", company)
  return (
    <ViewContainer>
      <FirstView>
        <Line title="Company Name" text={company.name} />
        <Line title="Email" text={company.email} />
        <Line title="Contact Person" text={company.contactPerson} />
      </FirstView>
      <BlockTitle marginBottom={10}>Technicians Assigned</BlockTitle>
      <SecondView>
        <TechnicianList array={company.technicians} />
      </SecondView>
      <BlockTitle marginBottom={10}>Assigned Templates</BlockTitle>
      <ThirdView>
        <Line title="Development" text="Development" />
      </ThirdView>
    </ViewContainer>
  )
}

export default ViewModal
