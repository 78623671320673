import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { SimpleInput } from "../../../../components/Inputs"
import ImageSelect from "../../../../components/ImageSelect"
import MySwitch from "../../../../components/MySwitch"
import { toBase64 } from "../../../../helpers"
import { uploadImage } from "../../../../helpers/image"

const AddModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  padding: 20px 0 20px 0;
  align-items: center;
`

const Row = styled.div`
  display: flex;
  flex: ${props => (props.flex ? props.flex : 1)};
  flex-direction: row;
  align-items: center;
  width: 100%;
`

const Status = styled(Row)`
  justify-content: flex-start;
  margin: 15px;
`

const getName = data => (data ? data.name : "")
const getStatus = data => (data ? data.is_active : false)

const AddModal = ({ editData, callback }) => {
  const [name, setName] = useState(getName(editData))
  const [status, setStatus] = useState(getStatus(editData))
  const [photo, setPhoto] = useState(editData ? editData.image : null)

  console.log("edit data == ", photo)
  useEffect(() => {
    const packData = async () => {
      return {
        name,
        is_active: status ? 1 : 0,
        image: photo,
      }
    }
    packData().then(e => callback(e))
  }, [name, status, photo])

  return (
    <AddModalContainer>
      <ImageSelect path={photo} onLoad={path => setPhoto(path)} />
      <div style={{ marginBottom: 15 }} />
      <Row>
        <SimpleInput title="Name" value={name} name="name" onChange={setName} />
      </Row>
      <Status>
        <MySwitch label="Status" active={status} setActive={setStatus} />
      </Status>
    </AddModalContainer>
  )
}

export default AddModal
