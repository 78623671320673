import React from "react"
import Actions from "../Actions"
import { StyledTableItem, StyledField } from "../../styledComponents"

const Field = ({ customer, field, index, type }) => {
  let content
  let flexScale

  flexScale = 1.5
  if (index < 4) flexScale = 1

  if (type === "state_id" || type === "plate" || type === "vin") return null
  if (type === "actions") {
    content = <Actions customer={customer} />
  } else content = field

  return <StyledField flex={flexScale}>{content}</StyledField>
}

const ItemContainer = ({ customer }) => {
  return (
    <StyledTableItem>
      {Object.keys(customer).map((e, index) => (
        <Field
          customer={customer}
          type={e}
          field={customer[e]}
          index={index}
          key={index}
        />
      ))}
    </StyledTableItem>
  )
}

export default ItemContainer
