import React from "react"
import styled from "styled-components"
import theme from "../../style/theme"
import classes from "../classes.module.css"
import { faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const StyledContainer = styled.div`
  background: ${theme.colors.blueLight};
  width: 100px !important;
  height: 100px !important;
  border-radius: ${props => (props.square ? 0 : "50%")};
  display: flex;
  align-items: center !important;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  text-align: center;
  border: 1px solid ${theme.colors.borderDarkBlue};
  &:hover {
    opacity: 0.8;
  }
`

const Plus = styled.p`
  height: 100px !important;
  width: 100px !important;
  position: relative;

  font-size: 100px;
  line-height: 1.8;
  //   width: 100%;
  left: 2px;
  color: #999;
  font-weight: 200;
`

const StyledInput = styled.input`
  cursor: pointer;
  width: 100px !important;
  height: 100px !important;
  border-radius: ${props => (props.square ? 0 : "50%")};
  position: absolute !important;
  background: yellow;
  opacity: 0;
`

const StyledImageBox = styled.div`
  width: 100px !important;
  height: 100px !important;
  border-radius: ${props => (props.square ? 0 : "50%")};
  overflow: hidden;
`
const Row = styled.div`
  display: flex;
  position: relative;
  right: 60px;
  top: 10px;
`

const ImageContainer = ({ image, deletePhoto, square }) => {
  return (
    <div className={classes.imageField}>
      <StyledImageBox square={square}>
        <img height={100} src={URL.createObjectURL(image)} alt="avatar" />
      </StyledImageBox>
      <FontAwesomeIcon
        onClick={deletePhoto}
        icon={faTimesCircle}
        className={classes.close}
      />
    </div>
  )
}

const Container = props => {
  const showImage = props.image
  return (
    <>
      <StyledContainer>
        {showImage ? (
          <ImageContainer deletePhoto={props.deletePhoto} image={props.image} />
        ) : (
          <Plus>+</Plus>
        )}
        {props.children}
      </StyledContainer>
    </>
  )
}

const MyPhotoInput = ({ value, onChange, ...rest }) => {
  return (
    <Container deletePhoto={() => onChange([])} image={value[0]}>
      <StyledInput
        {...rest}
        onChange={e => {
          onChange(e.target.files)
        }}
        // value={value}
        type="file"
        id="avatar"
        name="avatar"
        accept="image/png, image/jpeg"
      />
    </Container>
  )
}

export default MyPhotoInput
