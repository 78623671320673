import { put, call, select } from "redux-saga/effects"

import * as actions from "../../actions"
import axios from "axios"

const getToken = state => state.auth.token

const apiCall = token => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_PATH,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  let path = `/dashboard/pending-technicians?paginate=1&page=1&sort=id&soft_deleted=0`
  return instance
    .get(path)
    .then(response => response)
    .catch(error => {
      return error.response
    })
}

export default function* getPendingAsync(action) {
  try {
    const token = yield select(getToken)
    const data = yield call(apiCall, token)

    console.log(" data = ", data)
    if (data.status === 401) yield put(actions.signOut())
  } catch (err) {
    console.log(err)
  }
}
