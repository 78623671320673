import { put, call, select } from "redux-saga/effects"

import * as actions from "../../actions"
import axios from "axios"

const getToken = state => state.auth.token
const getPage = state => state.currentPages.templates
const getShowDeleted = state => state.deleted.show

const apiCall = (id, token) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_PATH,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const path = `/dashboard/templates/restore/${id}`
  return instance
    .put(path)
    .then(response => response)
    .catch(error => error.response)
}

export default function* restoreTemplatesAsync(action) {
  try {
    const token = yield select(getToken)
    const { id, vehicle } = action.payload

    const res = yield call(apiCall, id, token)

    console.log(" res = ", res)
    if (res.status === 200) {
      yield put(actions.restoreTemplateSuccess())

      const getParams = {
        page: yield select(getPage),
        soft_deleted: 1,
        vehicle_type_id: vehicle,
        search: null,
        sort: "id",
      }

      console.log(" getParams = ", getParams)
      yield put(actions.getTemplatesRequest(getParams))
    } else yield put(actions.restoreTemplateFailure(res.data))
  } catch (err) {
    yield put(actions.restoreTemplateFailure(err))
  }
}
