import React, { useState, useEffect } from "react"

import { Modal, Input, InputNumber } from "antd"

const Add = ({ modal, setModal, onConfirm, editTarget }) => {
  const [name, setName] = useState("")
  const [panelsFrom, setPanelsFrom] = useState("")
  const [panelsTo, setPanelsTo] = useState("")
  const [score, setScore] = useState("")

  useEffect(() => {
    if (editTarget) {
      setName(editTarget.name)
      setPanelsFrom(editTarget.panelsFrom)
      setPanelsTo(editTarget.panelsTo)
      setScore(editTarget.score)
    } else {
      setName("")
      setPanelsFrom("")
      setPanelsTo("")
      setScore("")
    }
  }, [editTarget])

  const handleConfirm = () => {
    if (name !== "" && panelsFrom !== "" && panelsTo !== "" && score !== "") {
      onConfirm({ name, panelsFrom, panelsTo, score })
      setModal(false)
    }
  }

  return (
    <Modal
      title={editTarget ? `Edit ${editTarget.name}` : "New Option"}
      visible={modal}
      onOk={handleConfirm}
      okButtonProps={{
        style: {
          background: "#559966",
          borderRadius: 20,
          height: 40,
          width: 120,
          fontSize: "0.8rem",
          margin: "0 6px",
          color: "#fff",
          border: "none",
        },
      }}
      onCancel={() => setModal(false)}
      cancelButtonProps={{
        style: {
          background: "#D53E3E",
          borderRadius: 20,
          height: 40,
          width: 120,
          fontSize: "0.8rem",
          margin: "0 6px",
          color: "#fff",
        },
      }}
    >
      <Input
        placeholder="Name"
        value={name}
        onChange={e => setName(e.target.value)}
        style={{ marginBottom: 20 }}
      ></Input>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <InputNumber
          placeholder="Score"
          min={0}
          value={score}
          style={{ flex: 1, marginRight: 10 }}
          onChange={value => setScore(value)}
        ></InputNumber>
        <InputNumber
          placeholder="Panels From"
          min={0}
          style={{ flex: 1, marginRight: 10 }}
          value={panelsFrom}
          onChange={value => setPanelsFrom(value)}
        ></InputNumber>
        <InputNumber
          placeholder="Panels To"
          min={0}
          style={{ flex: 1 }}
          value={panelsTo}
          onChange={value => setPanelsTo(value)}
        ></InputNumber>
      </div>
    </Modal>
  )
}

export default Add
