import types from "../types"

const initialMeta = {
  last_page: 1,
}
const initialState = {
  array: [],
  meta: initialMeta,
}

// "id": 2,
// "first_name": "TESTyy",
// "last_name": "TEST",
// "email": "test8@test8.com",
// "phone": "5858585857",
// "plate": "12444",
// "vin": "22888888888wwwww3",
// "state": "Indiana",
// "state_id": 14

const formatResponseData = data => {
  const array = data.map(e => {
    return {
      id: e.id,
      first_name: e.first_name,
      last_name: e.last_name,
      email: e.email,
      phone: e.phone,
      plate: e.plate,
      vin: e.vin,
      state: e.state,
      actions: true,
      // not displayable
      state_id: e.state_id - 1,
    }
  })
  return array
}

export default function companies(state = initialState, action) {
  switch (action.type) {
    case types.GET_CUSTOMERS_SUCCESS:
      const array = formatResponseData(action.payload.data)
      console.log("array = ", array)
      return {
        ...state,
        array: array,
        meta: action.payload.meta,
      }

    default:
      return state
  }
}
