import React from "react"
import styled from "styled-components"
import theme from "../style/theme"

const red = "#D53E3E"
const green = "#559966"

const StyledButton = styled.div`
	background: ${props => (props.type === "red" ? red : green)};
	height: 40px;
	width: 120px
	border-radius: 20px;
	color: white;
	font-size: ${theme.font.sm};
	display: flex;
	margin: 0 6px 0 6px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`

const StyledSmallButton = styled.div`
  background: ${props => (props.color ? props.color : green)};
  height: 32px;
  width: ${props => (props.scalable ? "100%" : "80px")};
  border-radius: 20px;
  border: ${props => (props.highlight ? `solid #ffff66 2px` : "none")};
  color: ${props => (props.textColor ? props.textColor : "white")};
  font-size: ${theme.font.xs};
  font-weight: bold;
  display: flex;
  padding: 0 6px 0 6px;
  opacity: ${props => (props.disabled ? 0.6 : 1)};
  margin: 0 10px 0 10px;
  align-items: center;
  justify-content: center;
  box-shadow: ${props =>
    props.shadow ? "0px 0px 8px rgba(0,0,0,0.1)" : "none"};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
`

const StyledColoredButton = styled.div`
    background: ${props => props.color};
    height: 40px;
	width: 120px
	border-radius: 20px;
	color: white;
	font-size: ${theme.font.sm};
	display: flex;
	margin: 0 6px 0 6px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`

export const SimpleButton = ({
  title,
  onClick,
  textColor = null,
  color = null,
  highlight = false,
  shadow = false,
  disabled = false,
  scalable,
  containerStyle = {},
}) => (
  <StyledSmallButton
    highlight={highlight}
    scalable={scalable}
    textColor={textColor}
    color={color}
    shadow={shadow}
    disabled={disabled}
    onClick={disabled ? () => {} : onClick}
    style={containerStyle}
  >
    {title}
  </StyledSmallButton>
)
export const ColoredButton = ({ title, onClick, color = "black" }) => (
  <StyledColoredButton onClick={onClick} color={color}>
    {title}
  </StyledColoredButton>
)

export const ConfirmButton = ({ title = "Confirm", onClick }) => (
  <StyledButton onClick={onClick} type="green">
    {title}
  </StyledButton>
)

export const EditButton = ({ title = "Edit", onClick }) => (
  <ColoredButton
    title={title}
    onClick={onClick}
    color="#E2B855"
  ></ColoredButton>
)
export const CancelButton = ({ title = "Cancel", onClick }) => (
  <StyledButton onClick={onClick} type="red">
    {title}
  </StyledButton>
)
export const NewButton = ({ title = "Add New", onClick }) => (
  <StyledSmallButton onClick={onClick}>{title}</StyledSmallButton>
)
