import types from "../types"

const initialMeta = {
  last_page: 1,
}
const initialState = {
  array: [],
  meta: initialMeta,
  status: null,
}

const formatResponseData = data => {
  const array = data.map(e => {
    return {
      id: e.id,
      name: e.name,
      phone: e.phone,
      email: e.email,
      type: e.is_bodyshop ? "Bodyshop" : "Not Bodyshop",
      address: e.address,
      actions: true,
      // not displayable
      photo: e.photo,
      company: e.company,
    }
  })
  return array
}

export default function pending(state = initialState, action) {
  switch (action.type) {
    case types.GET_PENDING_SUCCESS:
      const array = formatResponseData(action.payload.data)
      console.log("payload = ", action.payload)
      return {
        ...state,
        array: array,
        meta: action.payload.meta,
        status: "success",
      }
    case types.GET_PENDING_FAILURE:
      return {
        ...state,
        meta: initialMeta,
        status: "failure",
      }
    case types.GET_PENDING_REQUEST:
      return {
        ...state,
        meta: initialMeta,
        status: "loading",
      }
    default:
      return state
  }
}
