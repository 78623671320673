import React, { useState, useEffect } from "react"
import MySwitch from "../../../../components/MySwitch"
import MySearchInput from "../../../../components/MySearchInput"
import PageTitle from "../../../../components/PageTitle"
import Table from "./Table"
import { PageContainer, StyledToolBar, ToolGroup } from "../../styledComponents"
import { NewButton } from "../../../../components/Buttons"
import AddModal from "../Actions/AddModal"
import RenderModal from "../../../../components/RenderModal"
// import MyPagination from "../../../../components/MyPagination";
import ViewModal from "../Actions/ViewModal"
import { useAlert } from "react-alert"
import SimpleModal from "../../../../components/SimpleModal"

const Companies = props => {
  const [search, setSearch] = useState(null)
  const [newCompany, setNewCompany] = useState(null)
  const [editedCompany, setEditedCompany] = useState(null)
  const alert = useAlert()

  const {
    setShowDeleted,
    deleted,
    getCompaniesRequest,
    getCompaniesStatus,
    companies,
    currentPage,
    setCurrentPageCompanies,
    setDeleteModal,
    setEditModal,
    setViewModal,
    setRestoreModal,
    modals,
    editCompanyRequest,
    restoreCompanyRequest,
    deleteCompanyRequest,
    createCompanyRequest,
    clearCreateCompanyStatus,
    createRequestStatus,
    createRequestError,
  } = props

  const getData = (page = null, search = null) => {
    const params = {
      page: page !== null ? page : currentPage,
      soft_deleted: deleted.show,
      search,
      sort: "id",
    }
    console.log("search =-= ", search)
    getCompaniesRequest(params)
  }

  useEffect(() => {
    if (createRequestStatus === "loading") {
      alert.show("Sending")
    }
    if (createRequestStatus === "success") {
      alert.success("Company created!")
    }

    if (createRequestStatus === "failure") {
      // alert.error("Error creating Technician");
      Object.values(createRequestError).forEach(e => {
        alert.error(e)
      })
    }
    clearCreateCompanyStatus()
  }, [createRequestStatus])

  const handleEdit = () => {
    console.log("editedCompany = ", editedCompany)
    // editCompanyRequest(editedCompany, modals.edit.id);
    editCompanyRequest(
      {
        ...editedCompany,
        phone: editedCompany.phone.replace(/\W/g, ""),
      },
      modals.edit.id,
    )
  }

  const handleCreate = () => {
    console.log("newCompany = ", newCompany)
    // createCompanyRequest(newCompany);
    createCompanyRequest({
      ...newCompany,
      phone: newCompany.phone.replace(/\W/g, ""),
    })
  }

  const handleDelete = () => {
    deleteCompanyRequest(modals.delete.id)
  }

  const handleRestore = () => {
    restoreCompanyRequest(modals.restore.id)
  }

  const handleSearch = e => {
    const query = e.target.value
    setSearch(query)
    getData(null, query)
  }

  useEffect(() => {
    getData()
  }, [deleted.show])

  const handleChangePage = event => {
    setCurrentPageCompanies(event.selected, search)
    getData(event.selected, search)
  }

  return (
    <PageContainer>
      <RenderModal
        label="Add New Company"
        onConfirm={handleCreate}
        render={() => <AddModal callback={setNewCompany} />}
        active={props.modals.showAdd}
        setActive={props.setAddModal}
      />
      <RenderModal
        label={`Edit ${modals.edit.selected.name}`}
        render={() => (
          <AddModal
            callback={setEditedCompany}
            company={modals.edit.selected}
          />
        )}
        active={modals.edit.show}
        onConfirm={handleEdit}
        setActive={setEditModal}
      />
      <RenderModal
        label={`View ${modals.view.selected.name}`}
        padding={70}
        render={() => <ViewModal company={modals.view.selected} />}
        active={modals.view.show}
        setActive={setViewModal}
        type="view"
      />
      <RenderModal
        alignButtonsRight
        label="Confirmation"
        render={() => (
          <SimpleModal text="Are you sure you want to delete Company?" />
        )}
        onConfirm={handleDelete}
        active={modals.delete.show}
        setActive={setDeleteModal}
      />
      <RenderModal
        alignButtonsRight
        label="Confirmation"
        render={() => (
          <SimpleModal text="Are you sure you want to restore Company?" />
        )}
        active={modals.restore.show}
        setActive={setRestoreModal}
        onConfirm={handleRestore}
      />
      <PageTitle title="Companies" />
      <StyledToolBar>
        <MySearchInput value={search} onChange={handleSearch} />
        <ToolGroup>
          {/* <MyPagination
                        current={currentPage}
                        pageCount={companies.meta.last_page}
                        onChangePage={handleChangePage}
                    /> */}
        </ToolGroup>
        <MySwitch
          label="Show Deleted"
          active={deleted.show}
          setActive={setShowDeleted}
        />
        <NewButton onClick={() => props.setAddModal(true)} />
      </StyledToolBar>
      <Table
        onChangePage={handleChangePage}
        data={companies.array}
        status={getCompaniesStatus}
        companies={companies}
      />
    </PageContainer>
  )
}

export default Companies
