import { PageHeader } from "antd"
import React from "react"
import { useHistory } from "react-router-dom"

import styles from "styles/components/PageLayout.module.css"

const PageLayout = ({ children, title, withoutBackButton = false }) => {
  const history = useHistory()

  return (
    <div className={styles.container}>
      <PageHeader title={title} onBack={!withoutBackButton && history.goBack} />
      {children}
    </div>
  )
}

export default PageLayout
