import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { SimpleInput } from "../../../../components/Inputs"
import MySelect from "../../../../components/MySelect"
import theme from "../../../../style/theme"
import { makeOption } from "../../../../helpers"
import { SimpleButton } from "../../../../components/Buttons"
import ContentDisplay from "../../../../components/ContentDisplay"
import { connect } from "react-redux"
import {
  getVehiclesForSelectRequest,
  setCreateTemplateNameAndVehicle,
  getSingleTemplateRequest,
} from "../../../../store/actions"
import { setTemplatePackage } from "../../../../store/actions/template"
import { getItems } from "../../../../helpers/package"

const Container = styled.div`
  padding: 4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  flex: 1;
  height: 100%;
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  margin-top: 30px;
  height: 60%;
  border: 5px solid #f7f7f7;
  background: #f7f7f7;
  justify-content: start;
`

const Title = styled.h2`
  font-size: 1.8rem;
  color: ${theme.colors.blackMain};
  margin-bottom: 50px;
`

const Row = styled.div`
  display: flex;
  flex: ${props => (props.flex ? props.flex : 1)};
  flex-direction: row;
  width: 100%;
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
`

const ButtonContainer = styled.div`
  display: flex;
  // flex: 0.2;
  margin-top: 35px;

  align-items: flex-start;
  justify-content: center;
  justify-self: flex-end;
  align-slef: flex-end;
  width: 100%;
`

const ChooseName = ({
  history,
  getVehiclesForSelectRequest,
  setCreateTemplateNameAndVehicle,
  getVehiclesStatus,
  getSingleTemplate,
  vehicles,
  editData,
  auth,
  getSingleTemplateStatus,
  setTemplatePackage,
  packageBody,
  editTemplate,
}) => {
  const [name, setName] = useState(null)
  const [vehicle, setVehicle] = useState(null)
  const [packages, setPackages] = useState([])

  const isEdit = history.location.pathname.includes("edit")

  useEffect(() => {
    getVehiclesRawData()
  }, [])

  useEffect(() => {
    console.log("editData", editData)
    if (isEdit) {
      getSingleTemplate(editData.id)
    }
    getItems(auth.token).then(response => {
      setPackages(response)
    })
  }, [])

  useEffect(() => {
    if (isEdit && editTemplate) {
      setName(editTemplate.name)
    }
  }, [editTemplate])

  useEffect(() => {
    if (isEdit && editTemplate) {
      if (packages.length !== 0) {
        handleChangePackage(
          packages.find(e => e.id === editTemplate.package_id),
        )
      }
    }
  }, [editTemplate, packages])

  useEffect(() => {
    if (isEdit && editTemplate) {
      if (getVehiclesStatus === "success") {
        let opt
        opt = vehicles.find(e => e.id === editTemplate.package_id)
        if (opt) {
          opt = makeOption(opt.name, opt.id)
        }
        handleChangeVehicle(opt)
      }
    }
  }, [editTemplate, getVehiclesStatus])

  const handleChangeVehicle = e => {
    if (!e) {
      return
    }
    setVehicle(e)
  }

  const handleChangePackage = e => {
    if (!e) {
      return
    }
    setTemplatePackage(e)
  }

  const getVehiclesRawData = () => {
    getVehiclesForSelectRequest()
  }

  const handleSubmit = () => {
    if (!name || !vehicle) {
      alert("Please, fill all the data")
      return
    }
    history.push(nextUrl)
    const formattedVehicle = {
      name: vehicle.value,
      id: vehicle.id,
    }
    setCreateTemplateNameAndVehicle(name, formattedVehicle)
  }

  const handleSubmitBack = () => {
    history.push(backUrl)
  }

  const getVehiclesOptions = () => {
    const a = vehicles.map(e => makeOption(e.name, e.id))
    return a
  }

  const getPackagesOptions = () => {
    const a = packages.map(e => makeOption(e.name, e.id))
    return a
  }

  const nextUrl = history.location.pathname + "categories"
  const backUrl = "/templates"

  const content = (
    <InnerContainer>
      <Title> {isEdit ? "Edit" : "Create New"} Template </Title>
      <Col>
        <SimpleInput
          margin={false}
          title="Name of Template*"
          value={name}
          name="name"
          onChange={setName}
        />
        <MySelect
          options={getVehiclesStatus === "loading" ? [] : getVehiclesOptions()}
          isLoading={getVehiclesStatus === "loading"}
          title="Vehicle Type"
          value={vehicle}
          name="Vehicle Type"
          placeholder={"Choose a Vehicle Type"}
          onChange={handleChangeVehicle}
        ></MySelect>
        <MySelect
          options={packages.length === 0 ? [] : getPackagesOptions()}
          isLoading={getVehiclesStatus === "loading"}
          title="Package"
          name="Package"
          value={packageBody}
          placeholder={"Choose a Package"}
          onChange={handleChangePackage}
        ></MySelect>
      </Col>

      <ButtonContainer>
        <SimpleButton title="Back" color="#E2B855" onClick={handleSubmitBack} />
        <SimpleButton title="Next" color="#BBBBBB" onClick={handleSubmit} />
      </ButtonContainer>
    </InnerContainer>
  )
  return (
    <Container>
      <ContentDisplay
        status={getSingleTemplateStatus}
        data={content}
      ></ContentDisplay>
    </Container>
  )
}

const connectState = state => ({
  vehicles: state.vehicles.array,
  editData: state.modals.edit,
  getVehiclesStatus: state.statuses.getVehicles,
  auth: state.auth,
  packageBody: state.createTemplate.package_body,
  editTemplate: state.templates.template,
  getSingleTemplateStatus: state.statuses.getSingleTemplate,
})
const connectActions = {
  getVehiclesForSelectRequest,
  setCreateTemplateNameAndVehicle,
  setTemplatePackage,
  getSingleTemplate: getSingleTemplateRequest,
}

export default connect(connectState, connectActions)(ChooseName)
