import types from "../types"

const initialMeta = {
  last_page: 1,
  current_page: 0,
}
const initialState = {
  array: [],
  meta: initialMeta,
  status: null,
}

const formatResponseData = data => {
  const array = data.map(e => {
    return {
      id: e.id,
      name: e.name,
      phone: e.phone,
      email: e.email,
      type: e.is_bodyshop ? "Bodyshop" : "Not Bodyshop",
      company_id: e.company_id,
      company: e.company,

      address: e.address,
      city: e.city,
      street: e.street,
      zip: e.zip,
      appartment: e.appartment,
      actions: true,
      // not displayable
      photo: e.photo,
      is_active: e.is_active,
      open_time: e.open_time,
      close_time: e.close_time,
    }
  })
  return array
}

export default function technicians(state = initialState, action) {
  switch (action.type) {
    case types.GET_TECHNICIANS_SUCCESS:
      const array = formatResponseData(action.payload.data)
      console.log("payload = ", action.payload)
      return {
        ...state,
        array: array,
        meta: action.payload.meta,
        status: "success",
      }
    case types.GET_TECHNICIANS_FAILURE:
      return {
        ...state,
        // meta: initialMeta,
        status: "failure",
      }
    case types.GET_TECHNICIANS_REQUEST:
      return {
        ...state,
        // meta: initialMeta,
        status: "loading",
      }
    default:
      return state
  }
}
