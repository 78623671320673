import { put, call, select } from "redux-saga/effects"

import * as actions from "../../actions"
import axios from "axios"

const getToken = state => state.auth.token
const getPage = state => state.currentPages.companies
const getShowDeleted = state => state.deleted.show

const apiCall = (id, token) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_PATH,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const path = `/dashboard/companies/${id}`
  return instance
    .delete(path)
    .then(response => response)
    .catch(error => error.response)
}

export default function* deleteCompanyAsync(action) {
  try {
    const token = yield select(getToken)
    const { id } = action.payload

    // const getParams = {
    //     page: yield select(getPage),
    //     soft_deleted: yield select(getShowDeleted),
    //     search: null,
    //     sort: 'id'
    // };
    // yield put(actions.getCompaniesRequest(getParams));
    // const res = {};
    const res = yield call(apiCall, id, token)

    console.log(" res = ", res)
    if (res.status === 200) {
      yield put(actions.deleteCompanySuccess())
      const getParams = {
        page: yield select(getPage),
        soft_deleted: yield select(getShowDeleted),
        search: null,
        sort: "id",
      }
      yield put(actions.getCompaniesRequest(getParams))
    } else yield put(actions.deleteCompanyFailure(res.data))
  } catch (err) {
    yield put(actions.deleteCompanyFailure(err))
  }
}
