import React, { useState, useEffect } from "react"
import MySwitch from "../../../../components/MySwitch"
import MySearchInput from "../../../../components/MySearchInput"
import PageTitle from "../../../../components/PageTitle"
import MyPagination from "../../../../components/MyPagination"
import Table from "./Table"
import { PageContainer, StyledToolBar, ToolGroup } from "../../styledComponents"
import AssignModal from "../Actions/AssignModal"
import { useAlert } from "react-alert"
import SimpleModal from "../../../../components/SimpleModal"
import RenderModal from "../../../../components/RenderModal"

const Pending = props => {
  const [search, setSearch] = useState(null)
  const {
    setShowDeleted,
    getPendingRequest,
    pending,
    deleted,
    currentPage,
    setCurrentPagePending,
    modals,
    setAssignModal,
    setDeleteModal,
    setRestoreModal,
    assingPendingRequest,
    clearAssignPendingStatus,
    assignStatus,
    assignError,
    deletePendingRequest,
    restorePendingRequest,
  } = props

  const getData = (page = null, search = null) => {
    const params = {
      page: page !== null ? page : currentPage,
      soft_deleted: deleted.show,
      search,
      sort: "name",
    }
    getPendingRequest(params)
  }

  useEffect(() => {
    console.log("statuses", assignStatus)
    if (assignStatus === "loading") {
      alert.show("Sending")
    }
    if (assignStatus === "success") {
      alert.success("Technician Assinged!")
    }

    if (assignStatus === "failure") {
      Object.values(assignError).forEach(e => {
        alert.error(e)
      })
    }
    clearAssignPendingStatus()
  }, [assignStatus])

  useEffect(() => {
    getData()
  }, [deleted])

  const handleChangePage = event => {
    setCurrentPagePending(event.selected)
    getData(event.selected, search)
  }

  const [companyIdForAssign, setCompanyForAssign] = useState(null)
  const alert = useAlert()

  const handleAssign = () => {
    assingPendingRequest({
      id: modals.assign.id,
      company_id: companyIdForAssign,
    })
  }

  const handleDelete = () => {
    deletePendingRequest(modals.delete.id)
  }

  const handleSearch = e => {
    const query = e.target.value
    setSearch(query)
    getData(null, query)
  }

  const handleRestore = () => {
    restorePendingRequest(modals.restore.id)
  }

  return (
    <PageContainer>
      <RenderModal
        label="Assign to a Company"
        onConfirm={handleAssign}
        render={() => (
          <AssignModal
            callback={setCompanyForAssign}
            pending={modals.assign.selected}
          />
        )}
        active={modals.assign.show}
        setActive={setAssignModal}
      />
      <RenderModal
        alignButtonsRight
        label="Confirmation"
        render={() => (
          <SimpleModal text="Are you sure you want to delete Technician?" />
        )}
        active={modals.delete.show}
        onConfirm={handleDelete}
        setActive={setDeleteModal}
      />
      <RenderModal
        alignButtonsRight
        label="Confirmation"
        render={() => (
          <SimpleModal text="Are you sure you want to restore Technician?" />
        )}
        onConfirm={handleRestore}
        active={modals.restore.show}
        setActive={setRestoreModal}
      />
      <PageTitle title="Pending Technicians" />
      <StyledToolBar>
        <ToolGroup>
          <MySearchInput value={search} onChange={handleSearch} />
          {/* <MyPagination
                        current={currentPage}
                        pageCount={pending.meta.last_page}
                        onChangePage={handleChangePage}
                    /> */}
        </ToolGroup>
        <MySwitch
          label="Show Deleted"
          active={deleted.show}
          setActive={setShowDeleted}
        />
      </StyledToolBar>
      <Table
        onChangePage={handleChangePage}
        currentPage={currentPage}
        pending={pending}
      />
    </PageContainer>
  )
}

export default Pending
