import React from "react"
import { Switch, Route } from "react-router-dom"
import ChooseName from "./ChooseName"
import CategoryEditor from "./CategoryEditor"

const Templates = props => {
  return (
    <Switch>
      <Route exact path="/templates/edit/" component={ChooseName} />
      <Route
        exact
        path="/templates/edit/categories"
        component={CategoryEditor}
      />
      <Route exact path="/templates/create/" component={ChooseName} />
      <Route
        exact
        path="/templates/create/categories"
        component={CategoryEditor}
      />
      {/* <Route render={() => <div> Sorry, this page does not exist. </div>} /> */}
    </Switch>
  )
}

export default Templates
