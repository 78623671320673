import { put, call, select } from "redux-saga/effects"
import * as actions from "../../actions"
import axios from "axios"

const getToken = state => state.auth.token
const getPage = state => state.currentPages.vehicles
const getShowDeleted = state => state.deleted.show

const apiCall = (pack, token) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_PATH,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const path = `/dashboard/vehicle-types`
  return instance
    .post(path, pack)
    .then(response => response)
    .catch(error => error.response)
}

const formatParams = payload => {
  // formatted in add modal
  return payload
}
export default function* createVehicleAsync(action) {
  try {
    const token = yield select(getToken)
    const res = yield call(apiCall, formatParams(action.payload), token)

    console.log(" res = ", res)
    if (res.status === 200 || res.status === 201) {
      yield put(actions.createVehicleSuccess())
      const getParams = {
        page: yield select(getPage),
        soft_deleted: yield select(getShowDeleted),
        search: null,
        sort: "id",
      }
      yield put(actions.getVehiclesRequest(getParams))
    } else yield put(actions.createVehicleFailure(res.data))
  } catch (err) {
    yield put(actions.createVehicleFailure(err))
  }
}
