import React, { useEffect, useState } from "react"
import { Modal } from "antd"
import {
  setPackage,
  toggleEditModal,
} from "../../../../store/actions/paymentPackage"
import { Form, Input, Select, Button } from "antd"
import { getItems } from "../../../../helpers/template"
import { connect } from "react-redux"
import { updatePackage } from "../../../../helpers/package"

const FormPackage = props => {
  const [items, setItems] = useState([])

  useEffect(() => {
    getItems(props.auth.token).then(response => {
      // console.log('res', response);
      setItems(response)
    })
  }, [])

  const handleOk = () => {
    // console.log('props.modal', props.modal);
    updatePackage(
      props.auth.token,
      props.modal.package.id,
      props.modal.package,
    ).then(data => {
      props.toggleEditModal()
      // console.log(props.modal);
    })
  }

  const handleCancel = () => {
    props.toggleEditModal()
  }

  const handleChangeTemplate = () => {}

  const handleChange = (e, name) => {
    let pack = {
      name: "",
      price: "",
      description: "",
    }

    if (typeof props.modal !== "undefined") {
      pack = { ...props.modal.package }
    }

    pack[name] = e.currentTarget.value

    props.setPackage(pack)
    console.log(pack)
  }

  return (
    <Modal
      title="Edit Package"
      visible={typeof props.modal !== "undefined" ? props.modal.show : false}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button
          key="back"
          onClick={handleCancel}
          style={{
            background: "#559966",
            borderRadius: 20,
            height: 40,
            width: 120,
            fontSize: "0.8rem",
            margin: "0 6px",
            color: "#fff",
          }}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleOk}
          style={{
            background: "#D53E3E",
            borderRadius: 20,
            height: 40,
            width: 120,
            fontSize: "0.8rem",
            margin: "0 6px",
            color: "#fff",
            border: "none",
          }}
        >
          Save
        </Button>,
      ]}
    >
      <Form>
        <Form.Item label="Name">
          <Input
            value={
              typeof props.modal !== "undefined" && props.modal.package.name
            }
            onChange={e => handleChange(e, "name")}
          />
        </Form.Item>
        <Form.Item label="Price">
          <Input
            value={
              typeof props.modal !== "undefined" && props.modal.package.price
            }
            onChange={e => handleChange(e, "price")}
          />
        </Form.Item>
        {/*<Form.Item label="Template">*/}
        {/*    <Select mode="tags" style={{ width: '100%' }} onChange={handleChangeTemplate} tokenSeparators={[',']}>*/}
        {/*        {items.map(item => {*/}
        {/*            return (*/}
        {/*                <Select.Option key={item.id}>{item.name}</Select.Option>*/}
        {/*            )*/}
        {/*        })}*/}
        {/*    </Select>*/}
        {/*</Form.Item>*/}
        <Form.Item label="Description">
          <Input.TextArea
            value={
              typeof props.modal !== "undefined" &&
              props.modal.package.description
            }
            onChange={e => handleChange(e, "description")}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const mapState = state => ({
  auth: state.auth,
  modal: state.paymentPackage.modal,
})

const mapDispatch = dispatch => {
  return {
    toggleEditModal: () => dispatch(toggleEditModal()),
    setPackage: paymentPackage => dispatch(setPackage(paymentPackage)),
  }
}

export default connect(mapState, mapDispatch)(FormPackage)
