import styled from "styled-components"
import theme from "../../../../style/theme"

export const AddModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  height: auto;
  padding: 30px 0 30px 0;
  align-items: center;
`

export const Row = styled.div`
  display: flex;
  flex: ${props => (props.flex ? props.flex : 1)};
  flex-direction: row;
  width: 100%;
`
export const Center = styled.div`
  display: flex;
  flex: 1;
  lflex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`

export const StyledLine = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  width: 90%;
  align-items: center;
  justify-content: space-between;
`

export const Text = styled.p`
	font-size: ${theme.font.sm};
	font-weight: ${theme.font.regular}
	color: ${theme.colors.blackMain}
`

export const Vehicle = styled.p`
	font-size: ${theme.font.sm};
	font-weight: ${theme.font.regular}
	color: ${theme.colors.blackMain}
`

export const StyledTemplateSelect = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: 8px;
  align-items: space-between;
  justify-content: space-between;
`

export const ChooseTemplatesInner = styled.div`
  // overflow: hidden;
  padding: 10px 10px;
  flex: 1;
  margin-top: 10px;
  border-radius: 5px;
  background: whitesmoke;
`

export const ChooseTemplatesContainer = styled.div`
  width: 400px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  height: 420px;
`

export const Title = styled.h3`
	font-size: ${theme.font.md};
	font-weight: ${theme.font.bold}
	color: ${theme.colors.blackMain}
`

export const BlockTitle = styled.h2`
  font-size: ${theme.font.md2};
  color: ${theme.colors.blackMain};
  align-self: flex-start;
  margin-bottom: ${props =>
    props.marginBottom ? props.marginBottom + "px" : "20px"};
  font-weight: ${theme.font.regular};
`

export const StyledViewBlock = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 5%;
  // align-items: center;
  padding: 15px;
  box-sizing: border-box;
  flex-direction: column;
  // justify-content: center;
`

export const FirstView = styled(StyledViewBlock)``

export const SecondView = styled(StyledViewBlock)`
  max-height: 160px;
  overflow-y: auto;
  // box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.05);
`

export const ThirdView = styled(StyledViewBlock)``

export const ViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 40px 0 0px 0;
  align-items: center;
`
