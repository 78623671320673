import { put, call, select } from "redux-saga/effects"

import * as actions from "../../actions"
import axios from "axios"
// import {getFakePending} from './_getFake'

const getToken = state => state.auth.token

const apiCall = token => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_PATH,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const path = `/dashboard/all-companies`
  return instance
    .get(path)
    .then(response => response)
    .catch(error => error)
}

export default function* getAllCompaniesAsync(action) {
  try {
    const token = yield select(getToken)
    const data = yield call(apiCall, token)
    console.log(" data = ", data)
    if (data.status === 200)
      yield put(actions.getAllCompaniesSuccess(data.data))
    else yield put(actions.getAllCompaniesFailure())
  } catch (err) {
    yield put(actions.getAllCompaniesFailure())
  }
}
