import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Route, Switch } from "react-router-dom"
import { Modal } from "antd"

import HomeLayout from "components/HomeLayout"
import { signOut } from "store/actions"

import Pending from "./Pending"
import Technicians from "./Technicians"
import Companies from "./Companies"
import CompanyDetail from "./Companies/CompanyDetail"
import Customers from "./Customers"
import Factors from "./Factors"
import Categories from "./Categories"
import VehicleTypes from "./VehicleTypes"
import Templates from "./Templates"
import Packages from "./Packages/List"
import Dashboard from "./Dashboard"
import Appointments from "./Appointment/List"
import AppointmentForm from "./Appointment/Form"
import Notifications from "./Notifications"

const Home = ({ history }) => {
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)

  if (!auth?.token) {
    history.replace("/login")
    return null
  }

  // Since all users can log into admin panel (SIC!!!)
  // we at least should sign out user once we reconize
  // he is not an admin
  if (auth?.user?.role_id !== 1) {
    dispatch(signOut())
    setTimeout(
      () =>
        Modal.error({
          title: "Only admin can sign into admin panel",
          content: "Use admin account credencials",
        }),
      1000,
    )

    history.replace("/login")
    return null
  }

  return (
    <HomeLayout>
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route path="/pending" component={Pending} />
        <Route path="/technicians" component={Technicians} />
        <Route path="/companies" component={Companies} />
        <Route path="/company/detail/:id/:tab" component={CompanyDetail} />
        <Route path="/customers" component={Customers} />
        <Route path="/vehicle-types" component={VehicleTypes} />
        <Route path="/categories" component={Categories} />
        <Route path="/factors" component={Factors} />
        <Route path="/templates" component={Templates} />
        <Route path="/packages" component={Packages} />
        <Route path="/appointments" component={Appointments} />
        <Route
          exact
          path="/appointment/:appointmentId"
          component={AppointmentForm}
        />
        <Route
          exact
          path="/appointment/:appointmentId/:tab"
          component={AppointmentForm}
        />
        <Route exact path="/notifications" component={Notifications} />
      </Switch>
    </HomeLayout>
  )
}

export default Home
