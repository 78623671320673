import { put, call, select } from "redux-saga/effects"

import * as actions from "../../actions"
import axios from "axios"

const getToken = state => state.auth.token

const apiCall = (
  { search, page, sort, soft_deleted, vehicle_type_id },
  token,
) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_PATH,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const perPage = 8
  let path = `/dashboard/templates?vehicle_type_id=${vehicle_type_id}&paginate=${perPage}&page=${page}&sort=${sort}&soft_deleted=${soft_deleted}`
  if (search) path += `&search=${search}`
  return instance
    .get(path)
    .then(response => response)
    .catch(error => error)
}

export default function* getTemplatesAsync(action) {
  const { search, page, sort, soft_deleted, paginate, vehicle_type_id } =
    action.payload
  try {
    const token = yield select(getToken)
    const params = {
      search,
      page,
      sort,
      soft_deleted,
      paginate,
      vehicle_type_id,
    }
    const res = yield call(apiCall, params, token)

    console.log(" res = ", res)
    if (res.status === 200)
      yield put(
        actions.getTemplatesSuccess({
          data: res.data.data,
          meta: res.data.meta,
        }),
      )
    else yield put(actions.getTemplatesFailure())
  } catch (err) {
    yield put(actions.getTemplatesFailure())
  }
}
