import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { SimpleButton } from "../../../../../components/Buttons"
import MyPhotoInput from "../../../../../components/MyPhotoInput"
import MySwitch from "../../../../../components/MySwitch"
import MySelect from "../../../../../components/MySelect"
import RenderModal from "../../../../../components/RenderModal"
import ModelPicker from "./ModelPicker"
import { makeOptionWidthData } from "../../../../../helpers"

import {
  setTemplateCurrentCategory,
  updateTemplateCategories,
  getCategoriesOptionsRequest,
  removeTemplateCategories,
  resetCurrentSubcategories,
} from "../../../../../store/actions"

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  width: 100%;
`

const StyledEditing = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
  max-height: 330px;
  align-items: start;
  flex-direction: column;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.06);
  // border: 4px solid #f2f2f2;
  background: #eeeeee;
`
const Row = styled.div`
  width: 100%;
  margin-bottom: 5px;
`

const Block = styled.div`
  width: 100%;
  max-height: 210px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

const Editing = ({
  currentCategory,
  item,
  vehicleTypeName,
  setTemplateCurrentCategory,
  updateTemplateCategories,
  removeTemplateCategories,
  getCategoriesOptionsRequest,
  getCategoriesStatus,
  resetCurrentSubcategories,
  allSubcategoriesFinished,
  categories,
  storedCategories,
  finishEditing,
}) => {
  const [model, setModel] = useState(null)
  const [choosing, setChoosing] = useState(false)
  const [category, setCategory] = useState(item)
  const [submitEnabled, setSubmitEnabled] = useState(false)

  useEffect(() => {
    if (!category || category.id === null) return
    const { name, id, allowSub, isEmpty } = category
    const newCurrentCategory = {
      id,
      name,
      model: model ? model : item.model,
      allowSub,
      isEmpty,
      store_id: item.store_id,
    }
    setTemplateCurrentCategory(newCurrentCategory)
  }, [category, model])

  useEffect(() => {
    if (!item.model) return
    const v = item.model.model_path.split("/")[0]
    const t = item.model.model_path.split("/")[1]
    import(`./_models/${v}/${t}.jpg`).then(image => {
      console.log("changed")
      handleChangeModel({ ...item.model, picture: image.default })
    })
  }, [])

  useEffect(() => {
    if (
      !category ||
      category.id === null ||
      !currentCategory ||
      !currentCategory.model
    ) {
      setSubmitEnabled(false)
    } else if (currentCategory.allowSub) {
      console.log("currentCategory.allowSub", currentCategory.allowSub)
      setSubmitEnabled(allSubcategoriesFinished)
    } else setSubmitEnabled(true)
  }, [category, currentCategory, allSubcategoriesFinished])

  const handleSubmit = () => {
    console.log("submit cate= ", category)
    console.log("submit mode= ", model)

    finishEditing()
    updateTemplateCategories(currentCategory, item.store_id)
    setTemplateCurrentCategory(null)
  }

  const handleDelete = () => {
    removeTemplateCategories(item.store_id)
    setTemplateCurrentCategory(null)
  }

  const handleChangeCategory = option => {
    if (!option) {
      setCategory(null)
      return
    }

    // clean selected subcategories
    // setTemplateCurrentCategory(null);
    setTemplateCurrentCategory(item)

    setCategory(option.data)
  }

  const getCategoryOptions = () => {
    return categories
      .filter(f => !storedCategories.some(s => f.name === s.name)) // selected category cannot be reselected
      .map(e => makeOptionWidthData(e.name, e))
  }

  const getDefaultCategory = () => {
    if (!category || category.id === null) return null
    return makeOptionWidthData(category.name, category)
  }

  const getCategoriesRawData = () => {
    getCategoriesOptionsRequest()
  }

  const handleChangeModel = obj => {
    console.log("model = ", obj)
    setModel(obj)
  }

  const getVehicleType = () => {
    // must follow the structer of './_models'
    const availableTypes = [
      "Sedan",
      "Coupe",
      "Hatchback",
      "SUV",
      "Truck",
      "Van",
    ]

    return availableTypes.find(e => vehicleTypeName === e)
  }

  const handleConfirmModel = () => {}

  return (
    <>
      <RenderModal
        alignButtonsRight
        label="Select"
        render={() => (
          <ModelPicker
            selectedType={currentCategory.model && currentCategory.model.type}
            vehicleType={getVehicleType()}
            callback={handleChangeModel}
          />
        )}
        onConfirm={handleConfirmModel}
        active={choosing}
        setActive={setChoosing}
      />
      <StyledEditing>
        <Row>
          <MySelect
            defaultValue={
              getCategoriesStatus === "loading" ? [] : getDefaultCategory()
            }
            options={getCategoryOptions()}
            onMenuOpen={getCategoriesRawData}
            isLoading={getCategoriesStatus === "loading"}
            isClearable={false}
            title="Category"
            name="Category"
            placeholder="Select"
            onChange={handleChangeCategory}
          ></MySelect>
        </Row>
        {/* <Block>
                    <MyPhotoInput
                        square
                        value={photo}
                        onChange={setPhoto}
                    ></MyPhotoInput>
                </Block> */}
        <Block>
          <SimpleButton
            title="Choose Model"
            highlight={currentCategory && currentCategory.model}
            color="#3B86FF"
            scalable
            onClick={() =>
              category && category.id !== null && setChoosing(true)
            }
          />
        </Block>
        <BtnContainer>
          <SimpleButton title="Remove" color="crimson" onClick={handleDelete} />
          <SimpleButton
            disabled={!submitEnabled}
            title="Ok"
            color="#559966"
            onClick={handleSubmit}
          />
        </BtnContainer>
      </StyledEditing>
    </>
  )
}

const connectState = state => ({
  createTemplate: state.createTemplate,
  currentCategory: state.createTemplate.currentCategory,
  categories: state.categories.array,
  storedCategories: state.createTemplate.categories,
  getCategoriesStatus: state.statuses.getCategories,
  vehicleTypeName: state.createTemplate.vehicle.name,
  allSubcategoriesFinished: state.createTemplate.allSubcategoriesFinished,
})

const connectActions = {
  setTemplateCurrentCategory,
  updateTemplateCategories,
  removeTemplateCategories,
  getCategoriesOptionsRequest,
  resetCurrentSubcategories,
}

export default connect(connectState, connectActions)(Editing)
