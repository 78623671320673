import React from "react"
import { StyledTableItem, StyledField } from "../../styledComponents"
import Actions from "../Actions"

const Field = ({ field, index, type, pending }) => {
  let content
  let flexScale

  if (type === "photo" || type === "company") return null
  flexScale = 1.5
  if (index < 3) flexScale = 1.05
  if (index === 4) flexScale = 0.9

  if (type === "actions") {
    content = <Actions pending={pending} />
  } else content = field

  return <StyledField flex={flexScale}>{content}</StyledField>
}

const ItemContainer = ({ pending }) => {
  return (
    <StyledTableItem>
      {Object.keys(pending).map((e, index) => (
        <Field
          pending={pending}
          type={e}
          field={pending[e]}
          index={index}
          key={index}
        />
      ))}
    </StyledTableItem>
  )
}

export default ItemContainer
