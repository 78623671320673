import React from "react"
import { Table } from "antd"
import AppointmentsListActions from "./AppointmentsListActions"

const tableColumns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Company",
    dataIndex: "company",
    key: "company",
  },
  {
    title: "Technician",
    dataIndex: "technician",
    key: "technician",
  },
  {
    title: "Customer",
    dataIndex: "customer",
    key: "customer",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Inspection Date",
    dataIndex: "inspectionDate",
    key: "inspectionDate",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
]

const AppointmentsTable = ({ data, onItemOpen, onItemDelete, isLoading }) => {
  return (
    <Table
      columns={[
        ...tableColumns,
        {
          title: "Actions",
          key: "action",
          render: (_, record) => (
            <AppointmentsListActions
              onOpen={() => onItemOpen(record.id)}
              onDelete={() => onItemDelete(record.id)}
            />
          ),
        },
      ]}
      dataSource={data}
      pagination={false}
      loading={isLoading}
    />
  )
}

export default AppointmentsTable
