import React from "react"
import Actions from "../Actions"
import { StyledTableItem, StyledField } from "../../styledComponents"
import { Link } from "react-router-dom"

const Field = ({ company, field, index, type }) => {
  let content
  let flexScale

  if (
    type === "id" ||
    type === "phone" ||
    type === "technicians" ||
    type === "templates" ||
    type === "stateId"
  )
    return null
  flexScale = 1
  if (type === "actions") {
    content = <Actions company={company} />
  } else if (type === "name") {
    content = (
      <Link to={`/company/detail/${company.id}/templates`}>{field}</Link>
    )
  } else content = field

  return <StyledField flex={flexScale}>{content}</StyledField>
}

const ItemContainer = ({ company }) => {
  return (
    <StyledTableItem>
      {Object.keys(company).map((e, index) => (
        <Field
          company={company}
          type={e}
          field={company[e]}
          index={index}
          key={index}
        />
      ))}
    </StyledTableItem>
  )
}

export default ItemContainer
