import types from "../types"

const initialState = {
  createTechnician: null,
  createCompany: null,
  assignPending: null,
  allCompanies: null,
  getCompanyInfo: null,
  companies: null,
  setActiveTechnician: null,
  setActiveVehicle: null,
  createCustomer: null,
  getCustomers: null,
  createVehicle: null,
  getVehicles: null,
  createFactor: null,
  getFactors: null,
  createCategory: null,
  getCategories: null,
  createTemplate: null,
  getTemplates: null,
  createSubCategory: null,
  getSubCategories: null,
  createSubFactor: null,
  getSubFactors: null,
  getSingleTemplate: null,
  getOneFactor: null,
}

export default function statuses(state = initialState, action) {
  switch (action.type) {
    case types.CREATE_TECHNICIAN_SUCCESS:
      return {
        ...state,
        createTechnician: "success",
      }
    case types.CREATE_TECHNICIAN_REQUEST:
      return {
        ...state,
        createTechnician: "loading",
      }
    case types.CREATE_TECHNICIAN_FAILURE:
      return {
        ...state,
        createTechnician: "failure",
      }
    case types.CLEAR_CREATE_TECHNICIAN_STATUS:
      return {
        ...state,
        createTechnician: null,
      }
    case types.CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        createCompany: "success",
      }
    case types.CREATE_COMPANY_REQUEST:
      return {
        ...state,
        createCompany: "loading",
      }
    case types.CREATE_COMPANY_FAILURE:
      return {
        ...state,
        createCompany: "failure",
      }
    case types.CLEAR_CREATE_COMPANY_STATUS:
      return {
        ...state,
        createCompany: null,
      }
    case types.ASSIGN_PENDING_SUCCESS:
      return {
        ...state,
        assignPending: "success",
      }
    case types.ASSIGN_PENDING_REQUEST:
      return {
        ...state,
        assignPending: "loading",
      }
    case types.ASSIGN_PENDING_FAILURE:
      return {
        ...state,
        assignPending: "failure",
      }
    case types.CLEAR_ASSIGN_PENDING_STATUS:
      return {
        ...state,
        assignPending: null,
      }
    case types.GET_ALL_COMPANIES_SUCCESS:
      return {
        ...state,
        allCompanies: "success",
      }
    case types.GET_ALL_COMPANIES_REQUEST:
      return {
        ...state,
        allCompanies: "loading",
      }
    case types.GET_ALL_COMPANIES_FAILURE:
      return {
        ...state,
        allCompanies: "failure",
      }
    case types.GET_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: "success",
      }
    case types.GET_COMPANIES_REQUEST:
      return {
        ...state,
        companies: "loading",
      }
    case types.GET_COMPANIES_FAILURE:
      return {
        ...state,
        companies: "failure",
      }
    case types.SET_ACTIVE_TECHNICIAN_SUCCESS:
      return {
        ...state,
        setActiveTechnician: "success",
      }
    case types.SET_ACTIVE_TECHNICIAN_REQUEST:
      return {
        ...state,
        setActiveTechnician: "loading",
      }
    case types.SET_ACTIVE_TECHNICIAN_FAILURE:
      return {
        ...state,
        setActiveTechnician: "failure",
      }
    case types.CLEAR_SET_ACTIVE_TECHNICIAN_STATUS:
      return {
        ...state,
        setActiveTechnician: null,
      }
    case types.SET_ACTIVE_VEHICLE_SUCCESS:
      return {
        ...state,
        setActiveVehicle: "success",
      }
    case types.SET_ACTIVE_VEHICLE_REQUEST:
      return {
        ...state,
        setActiveVehicle: "loading",
      }
    case types.SET_ACTIVE_VEHICLE_FAILURE:
      return {
        ...state,
        setActiveVehicle: "failure",
      }
    case types.CLEAR_SET_ACTIVE_VEHICLE_STATUS:
      return {
        ...state,
        setActiveVehicle: null,
      }
    case types.CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        createCustomer: "success",
      }
    case types.CREATE_CUSTOMER_REQUEST:
      return {
        ...state,
        createCustomer: "loading",
      }
    case types.CREATE_CUSTOMER_FAILURE:
      return {
        ...state,
        createCustomer: "failure",
      }
    case types.CLEAR_CREATE_CUSTOMER_STATUS:
      return {
        ...state,
        createCustomer: null,
      }
    case types.GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        getCustomers: "success",
      }
    case types.GET_CUSTOMERS_REQUEST:
      return {
        ...state,
        getCustomers: "loading",
      }
    case types.GET_CUSTOMERS_FAILURE:
      return {
        ...state,
        getCustomers: "failure",
      }

    case types.CREATE_VEHICLE_SUCCESS:
      return {
        ...state,
        createVehicle: "success",
      }
    case types.CREATE_VEHICLE_REQUEST:
      return {
        ...state,
        createVehicle: "loading",
      }
    case types.CREATE_VEHICLE_FAILURE:
      return {
        ...state,
        createVehicle: "failure",
      }
    case types.CLEAR_CREATE_VEHICLE_STATUS:
      return {
        ...state,
        createVehicle: null,
      }
    case types.GET_VEHICLES_SUCCESS:
      return {
        ...state,
        getVehicles: "success",
      }
    case types.GET_VEHICLES_REQUEST:
      return {
        ...state,
        getVehicles: "loading",
      }
    case types.GET_VEHICLES_FAILURE:
      return {
        ...state,
        getVehicles: "failure",
      }

    case types.CREATE_FACTOR_SUCCESS:
      return {
        ...state,
        createFactor: "success",
      }
    case types.CREATE_FACTOR_REQUEST:
      return {
        ...state,
        createFactor: "loading",
      }
    case types.CREATE_FACTOR_FAILURE:
      return {
        ...state,
        createFactor: "failure",
      }
    case types.EDIT_FACTOR_SUCCESS:
      return {
        ...state,
        createFactor: "success",
      }
    case types.EDIT_FACTOR_REQUEST:
      return {
        ...state,
        createFactor: "loading",
      }
    case types.EDIT_FACTOR_FAILURE:
      return {
        ...state,
        createFactor: "failure",
      }
    case types.CLEAR_CREATE_FACTOR_STATUS:
      return {
        ...state,
        createFactor: null,
      }
    case types.GET_FACTORS_SUCCESS:
      return {
        ...state,
        getFactors: "success",
      }
    case types.GET_FACTORS_REQUEST:
      return {
        ...state,
        getFactors: "loading",
      }
    case types.GET_FACTORS_FAILURE:
      return {
        ...state,
        getFactors: "failure",
      }
    case types.GET_ONE_FACTOR_SUCCESS:
      return {
        ...state,
        getOneFactor: "success",
      }
    case types.GET_ONE_FACTOR_REQUEST:
      return {
        ...state,
        getOneFactor: "loading",
      }
    case types.GET_ONE_FACTOR_FAILURE:
      return {
        ...state,
        getOneFactor: "failure",
      }

    case types.CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        createCategory: "success",
      }
    case types.CREATE_CATEGORY_REQUEST:
      return {
        ...state,
        createCategory: "loading",
      }
    case types.CREATE_CATEGORY_FAILURE:
      return {
        ...state,
        createCategory: "failure",
      }
    case types.CLEAR_CREATE_CATEGORY_STATUS:
      return {
        ...state,
        createCategory: null,
      }
    case types.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        getCategories: "success",
      }
    case types.GET_CATEGORIES_REQUEST:
      return {
        ...state,
        getCategories: "loading",
      }
    case types.GET_CATEGORIES_FAILURE:
      return {
        ...state,
        getCategories: "failure",
      }

    case types.CREATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        createTemplate: "success",
      }
    case types.CREATE_TEMPLATE_REQUEST:
      return {
        ...state,
        createTemplate: "loading",
      }
    case types.CREATE_TEMPLATE_FAILURE:
      return {
        ...state,
        createTemplate: "failure",
      }
    case types.CLEAR_CREATE_TEMPLATE_STATUS:
      return {
        ...state,
        createTemplate: null,
      }
    case types.GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        getTemplates: "success",
      }
    case types.GET_TEMPLATES_REQUEST:
      return {
        ...state,
        getTemplates: "loading",
      }
    case types.GET_TEMPLATES_FAILURE:
      return {
        ...state,
        getTemplates: "failure",
      }

    case types.CREATE_SUB_CATEGORY_SUCCESS:
      return {
        ...state,
        createSubCategory: "success",
      }
    case types.CREATE_SUB_CATEGORY_REQUEST:
      return {
        ...state,
        createSubCategory: "loading",
      }
    case types.CREATE_SUB_CATEGORY_FAILURE:
      return {
        ...state,
        createSubCategory: "failure",
      }
    case types.CLEAR_CREATE_SUB_CATEGORY_STATUS:
      return {
        ...state,
        createSubCategory: null,
      }
    case types.GET_SUB_CATEGORIES_SUCCESS:
      return {
        ...state,
        getSubCategories: "success",
      }
    case types.GET_SUB_CATEGORIES_REQUEST:
      return {
        ...state,
        getSubCategories: "loading",
      }
    case types.GET_SUB_CATEGORIES_FAILURE:
      return {
        ...state,
        getSubCategories: "failure",
      }

    case types.CREATE_SUB_FACTOR_SUCCESS:
      return {
        ...state,
        createSubFactor: "success",
      }
    case types.CREATE_SUB_FACTOR_REQUEST:
      return {
        ...state,
        createSubFactor: "loading",
      }
    case types.CREATE_SUB_FACTOR_FAILURE:
      return {
        ...state,
        createSubFactor: "failure",
      }
    case types.CLEAR_CREATE_SUB_FACTOR_STATUS:
      return {
        ...state,
        createSubFactor: null,
      }
    case types.GET_SUB_FACTORS_SUCCESS:
      return {
        ...state,
        getSubFactors: "success",
      }
    case types.GET_SUB_FACTORS_REQUEST:
      return {
        ...state,
        getSubFactors: "loading",
      }
    case types.GET_SUB_FACTORS_FAILURE:
      return {
        ...state,
        getSubFactors: "failure",
      }
    case types.SINGLE_TEMPLATE_SUCCESS:
      return {
        ...state,
        getSingleTemplate: "success",
      }
    case types.SINGLE_TEMPLATE_REQUEST:
      return {
        ...state,
        getSingleTemplate: "loading",
      }
    case types.SINGLE_TEMPLATE_FAILURE:
      return {
        ...state,
        getSingleTemplate: "failure",
      }
    default:
      return state
  }
}
