import React from "react"
import styled from "styled-components"
import classes from "../../classes.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser } from "@fortawesome/free-solid-svg-icons"
import theme from "../../../../style/theme"
import MySelect from "../../../../components/MySelect"
import { mapCompaniesToOptions } from "../../../../helpers"
import { connect } from "react-redux"
import * as actions from "../../../../store/actions"

const AssignModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 340px;
  align-items: center;
  justify-content: center;
  height: 60vh;
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 30px 0 30px 0;
  align-items: center;
  justify-content: center;
`

const StyledKey = styled.div`
  font-size: ${theme.font.sm};
  color: ${theme.colors.blackMain};
  font-weight: 600;
  width: 20%;
  text-align: left;
`

const StyledVal = styled.div`
  font-size: ${theme.font.sm};
  color: ${theme.colors.blackMain};
  font-weight: 400;
  width: 50%;
  text-align: left;
`

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  width: 100%;
  margin-top: 15px;
  align-items: center;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  align-items: center;
  justify-content: space-around;
`

const SelectRow = styled(Row)`
  width: 85%;
`

const UserIcon = () => (
  <IconContainer>
    <FontAwesomeIcon icon={faUser} className={classes.pending} />
  </IconContainer>
)

const AssignModal = ({
  pending,
  getAllCompanies,
  companies,
  getAllCompaniesStatus,
  callback,
}) => {
  const handleChangeCompany = company => {
    callback(company.id)
  }

  const handleOpenCompaniesSelect = () => {
    getAllCompanies()
  }
  return (
    <AssignModalContainer>
      <InnerContainer>
        <UserIcon />
        <TextContainer>
          <Row>
            <StyledKey>Name: </StyledKey>
            <StyledVal>{pending.name}</StyledVal>
          </Row>
          <Row>
            <StyledKey>Phone: </StyledKey>
            <StyledVal>{pending.phone}</StyledVal>
          </Row>
          <Row>
            <StyledKey>Email: </StyledKey>
            <StyledVal>{pending.email}</StyledVal>
          </Row>
          <Row>
            <StyledKey>Type: </StyledKey>
            <StyledVal>{pending.type}</StyledVal>
          </Row>
          <Row>
            <StyledKey>Address: </StyledKey>
            <StyledVal>{pending.address}</StyledVal>
          </Row>
          <SelectRow>
            <MySelect
              title="Company"
              options={
                getAllCompaniesStatus === "loading"
                  ? []
                  : mapCompaniesToOptions(companies)
              }
              name="company"
              onMenuOpen={handleOpenCompaniesSelect}
              isLoading={getAllCompaniesStatus === "loading"}
              placeholder={"Select"}
              onChange={handleChangeCompany}
            ></MySelect>
          </SelectRow>
        </TextContainer>
      </InnerContainer>
    </AssignModalContainer>
  )
}

const connectState = state => ({
  companies: state.companies.listCompanies,
  getAllCompaniesStatus: state.statuses.allCompanies,
})
const connectActions = {
  getAllCompanies: actions.getAllCompaniesRequest,
}
export default connect(connectState, connectActions)(AssignModal)
