import React, { useState, useEffect } from "react"
import MySwitch from "../../../../components/MySwitch"
import MySearchInput from "../../../../components/MySearchInput"
import PageTitle from "../../../../components/PageTitle"
import Table from "./Table"
import { PageContainer, StyledToolBar, ToolGroup } from "../../styledComponents"
import { NewButton } from "../../../../components/Buttons"
import RenderModal from "../../../../components/RenderModal"
// import MyPagination from "../../../../components/MyPagination";
import { useAlert } from "react-alert"
import SimpleModal from "../../../../components/SimpleModal"
import VehiclePicker from "./VehiclePicker"
const Templates = props => {
  const [search, setSearch] = useState(null)
  const [vehicle, setVehicle] = useState(null)
  // const [newTemplate, setNewTemplate] = useState(null);
  // const [editedTemplate, setEditedTemplate] = useState(null);
  const alert = useAlert()

  const {
    setShowDeleted,
    deleted,
    getTemplatesRequest,
    getTemplatesStatus,
    templates,
    currentPage,
    setCurrentPageTemplates,
    setDeleteModal,
    setRestoreModal,
    resetCreateTemplate,
    modals,
    restoreTemplateRequest,
    deleteTemplateRequest,
    clearCreateTemplateStatus,
    createRequestStatus,
    createRequestError,
    getVehiclesStatus,
  } = props

  const getData = (page = null, search = null) => {
    const params = {
      page: page !== null ? page : currentPage,
      soft_deleted: deleted.show,
      search,
      sort: "name",
      vehicle_type_id: vehicle,
    }
    getTemplatesRequest(params)
  }

  const handleDelete = () => {
    deleteTemplateRequest(modals.delete.id, vehicle)
  }

  const handleRestore = () => {
    restoreTemplateRequest(modals.restore.id, vehicle)
  }

  const navigateToCreate = () => {
    const createPath = props.history.location.pathname + "/create/"
    resetCreateTemplate()
    props.history.push(createPath)
  }
  const navigateToEdit = () => {
    console.log("hello")
    const createPath = props.history.location.pathname + "/edit/"
    resetCreateTemplate()
    props.history.push(createPath)
  }

  useEffect(() => {
    getData()
  }, [deleted.show, vehicle])

  const handleChangePage = event => {
    setCurrentPageTemplates(event.selected)
    getData(event.selected, search)
  }

  const handleSearch = e => {
    const query = e.target.value
    setSearch(query)
    getData(null, query)
  }

  return (
    <>
      <VehiclePicker selected={vehicle} setSelected={setVehicle} />
      <PageContainer>
        <RenderModal
          alignButtonsRight
          label="Confirmation"
          render={() => (
            <SimpleModal text="Are you sure you want to delete Template?" />
          )}
          onConfirm={handleDelete}
          active={modals.delete.show}
          setActive={setDeleteModal}
        />
        <RenderModal
          alignButtonsRight
          label="Confirmation"
          render={() => (
            <SimpleModal text="Are you sure you want to restore Template?" />
          )}
          active={modals.restore.show}
          setActive={setRestoreModal}
          onConfirm={handleRestore}
        />
        <PageTitle title={"Templates"} />

        <StyledToolBar>
          <MySearchInput value={search} onChange={handleSearch} />
          <ToolGroup>
            {/* <MyPagination
              current={currentPage}
              pageCount={templates.meta.last_page}
              onChangePage={handleChangePage}
            /> */}
          </ToolGroup>
          <MySwitch
            label="Show Deleted"
            active={deleted.show}
            setActive={setShowDeleted}
          />
          <NewButton onClick={navigateToCreate} />
        </StyledToolBar>
        <Table
          navigateToEdit={navigateToEdit}
          data={templates.array}
          status={[getTemplatesStatus, getVehiclesStatus]}
          currentPage={currentPage}
          templates={templates}
        />
      </PageContainer>
    </>
  )
}

export default Templates
