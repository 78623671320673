import React, { useEffect, useState } from "react"
import PageTitle from "../../../../../../components/PageTitle"
import * as template from "../../../../../../helpers/template"
import Table from "./Table"
import { Pagination } from "antd"
import styled from "styled-components"
import { connect } from "react-redux"

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: ${props => (props.noPadding ? 0 : "2%")};
  box-sizing: border-box;
  overflow-y: scroll;
`

const PaginationContainer = styled.div`
  margin-top: 50px;
  text-align: right;
`

const Templates = props => {
  const [items, setItems] = useState([])
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPage: 0,
  })

  useEffect(() => {
    load()
  }, [])

  const load = () => {
    template.getItems(props.auth.token).then(response => {
      setItems(response)
    })
  }

  const handleAssignTemplate = (companyId, templateId, assign = true) => {
    if (assign) {
      template
        .assignCompany(props.auth.token, props.companyId, templateId)
        .then(response => {
          load()
        })
    } else {
      template
        .unassignCompany(props.auth.token, props.companyId, templateId)
        .then(response => {
          load()
        })
    }
  }

  return (
    <PageContainer>
      <PageTitle title="Templates" />

      <Table
        items={items}
        pagination
        {...pagination}
        companyId={props.companyId}
        onChangeAssign={handleAssignTemplate}
      />
      <PaginationContainer>
        <Pagination
          current={pagination.currentPage}
          total={pagination.totalPage}
          onChange={page => setPagination({ ...pagination, currentPage: page })}
        />
      </PaginationContainer>
    </PageContainer>
  )
}

const mapState = state => ({
  auth: state.auth,
})

export default connect(mapState)(Templates)
