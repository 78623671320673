import React, { useState, useEffect } from "react"
import "rc-steps/assets/index.css"
import "rc-steps/assets/iconfont.css"
import Steps, { Step } from "rc-steps"
import styled from "styled-components"

const StyledSteps = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 80%;
  overflow-y: auto;
  box-sizing: border-box;
  max-height: 460px !important;
  padding: ${props => (props.fullSize ? "25px" : "15px")};
  border: 2px solid #eee;
  border-radius: 10px;
  background: white;
`

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const Message = styled.div`
  color: #ddd;
  font-weight: 400;
  font-size: 20px;
`

const StepBlock = ({ fullSize = false, steps, onRemove }) => {
  let content
  if (steps.length === 0) {
    content = (
      <Center>
        <Message> No Steps to Display</Message>
      </Center>
    )
  } else {
    content = steps.map((e, index) => {
      return (
        <Step
          key={index}
          status={"wait"}
          title={e}
          style={{ cursor: "pointer" }}
          onClick={() => onRemove({ id: index, name: e })}
          description={"Some description"}
        />
      )
    })
  }
  return (
    <StyledSteps fullSize={fullSize}>
      <Steps direction="vertical" size="small">
        {content}
      </Steps>
    </StyledSteps>
  )
}

export default StepBlock
