import React from "react"
import Actions from "./Actions"
import { StyledTableItem, StyledField } from "../../styledComponents"

const Field = ({ field, index, navigateToEdit, type, template }) => {
  let content
  let flexScale

  flexScale = 1
  if (type === "data") {
    return null
  }

  if (type === "actions") {
    content = <Actions template={template} navigateToEdit={navigateToEdit} />
  } else content = field

  return <StyledField flex={flexScale}>{content}</StyledField>
}

const TableItem = ({ template, navigateToEdit }) => {
  return (
    <StyledTableItem>
      {Object.keys(template).map((e, index) => (
        <Field
          navigateToEdit={navigateToEdit}
          type={e}
          field={template[e]}
          index={index}
          key={index}
          template={template}
        />
      ))}
    </StyledTableItem>
  )
}

export default TableItem
