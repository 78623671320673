import types from "../types"

const initialState = {
  testError: null,
  loginError: null,
  createTechnician: { error: "Unknown Error" },
  createCompany: { error: "Unknown Error" },
  createCustomer: { error: "Unknown Error" },
  createVehicle: { error: "Unknown Error" },
  createFactor: { error: "Unknown Error" },
  assignPending: { error: "Unknown Error" },
  createCategory: { error: "Unknown Error" },
  createTemplate: { error: "Unknown Error" },
  createSubCategory: { error: "Unknown Error" },
  createSubFactor: { error: "Unknown Error" },
  getSingleTemplate: { error: "Unknown Error" },
  getOneFactor: { error: "Unknown Error" },
}

const mapErrorToObject = res => {
  console.log("error ==== ", res)
  if (!res) return { error: "Unknown Error" }
  if (res.error && typeof res.error === "object") return res.error
  else if (res.errors && typeof res.errors === "object") return res.errors
  if (typeof res === "string") return { error: res }
  return { error: "Unknown Error" }
}

export default function errors(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_FAILURE:
      return {
        ...state,
        loginError: action.payload,
      }
    case types.CREATE_TECHNICIAN_FAILURE:
      return {
        ...state,
        createTechnician: mapErrorToObject(action.payload),
      }
    case types.ASSIGN_PENDING_FAILURE:
      return {
        ...state,
        assignPending: mapErrorToObject(action.payload),
      }
    case types.EDIT_COMPANY_FAILURE:
      return {
        ...state,
        editCompany: mapErrorToObject(action.payload),
      }
    case types.CREATE_CUSTOMER_FAILURE:
      return {
        ...state,
        createCustomer: mapErrorToObject(action.payload),
      }
    case types.CREATE_VEHICLE_FAILURE:
      return {
        ...state,
        createVehicle: mapErrorToObject(action.payload),
      }
    case types.CREATE_FACTOR_FAILURE:
      return {
        ...state,
        createFactor: mapErrorToObject(action.payload),
      }
    case types.GET_ONE_FACTOR_FAILURE:
      return {
        ...state,
        getOneFactor: mapErrorToObject(action.payload),
      }
    case types.CREATE_CATEGORY_FAILURE:
      return {
        ...state,
        createCategory: mapErrorToObject(action.payload),
      }
    case types.CREATE_TEMPLATE_FAILURE:
      return {
        ...state,
        createTemplate: mapErrorToObject(action.payload),
      }
    case types.CREATE_SUB_CATEGORY_FAILURE:
      return {
        ...state,
        createSubCategory: mapErrorToObject(action.payload),
      }
    case types.CREATE_SUB_FACTOR_FAILURE:
      return {
        ...state,
        createSubFactor: mapErrorToObject(action.payload),
      }
    case types.SINGLE_TEMPLATE_FAILURE:
      return {
        ...state,
        getSingleTemplate: mapErrorToObject(action.payload),
      }
    case types.GET_TEST_FAILURE:
      return {
        ...state,
        testError: action.payload,
      }
    default:
      return state
  }
}
