import types from "../types"

const initialMeta = {
  last_page: 1,
}
const initialState = {
  array: [],
  meta: initialMeta,
}

// "id": 2,
// "first_name": "TESTyy",
// "last_name": "TEST",
// "email": "test8@test8.com",
// "phone": "5858585857",
// "plate": "12444",
// "vin": "22888888888wwwww3",
// "state": "Indiana",
// "state_id": 14

const formatResponseData = data => {
  const array = data.map(e => {
    return {
      id: e.id,
      name: e.name,
      image: e.image,
      is_active: e.is_active,
      actions: true,
    }
  })
  return array
}

export default function vehicles(state = initialState, action) {
  switch (action.type) {
    case types.GET_VEHICLES_SUCCESS:
      const array = formatResponseData(action.payload.data)
      console.log("array = ", array)
      return {
        ...state,
        array: array,
        meta: action.payload.meta,
      }
    case types.UPDATE_VEHICLE_TYPE_STATUS:
      return {
        ...state,
        array: state.array.map(e =>
          e.id === action.payload.id
            ? {
                ...e,
                is_active: !e.is_active,
              }
            : e,
        ),
      }

    default:
      return state
  }
}
