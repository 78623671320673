import React, { useEffect } from "react"
import { connect } from "react-redux"

import { Popconfirm, message } from "antd"
import { useHistory } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import classes from "../../classes.module.css"
import { getItem } from "../../../../helpers/package"
import {
  toggleEditModal,
  setPackage,
} from "../../../../store/actions/paymentPackage"
import auth from "../../../../store/reducers/auth.reducer"

const style = {
  width: "25px",
  height: "25px",
  boxShadow: "0 0 5px rgba(0,0,0,0.5)",
}

const Actions = props => {
  const history = useHistory()

  return (
    <>
      <FontAwesomeIcon
        icon={faEdit}
        style={style}
        className={classes.eye}
        onClick={() => {
          getItem(props.auth.token, props.packageId).then(response => {
            console.log(response)
            props.setPackage(response)
          })
          props.toggleEditModal()
        }}
      />
    </>
  )
}

const mapState = state => ({
  auth: state.auth,
})

const mapDispatch = dispatch => {
  return {
    toggleEditModal: () => dispatch(toggleEditModal()),
    setPackage: paymentPackage => dispatch(setPackage(paymentPackage)),
  }
}

export default connect(mapState, mapDispatch)(Actions)
