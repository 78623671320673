import React, { useState } from "react"
import { connect } from "react-redux"
import { SimpleButton } from "../../../../components/Buttons"
import {
  setEditModal,
  setDeleteModal,
  setRestoreModal,
  setViewModal,
} from "../../../../store/actions"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash, faPenSquare, faEye } from "@fortawesome/free-solid-svg-icons"
import classes from "../../classes.module.css"
import { StyledAction } from "../../styledComponents"

const Actions = ({
  deleted,
  company,
  setEditModal,
  setDeleteModal,
  setViewModal,
  setRestoreModal,
}) => {
  if (deleted.show) {
    return (
      <StyledAction>
        <SimpleButton
          title="Restore"
          onClick={() => setRestoreModal(true, company.id)}
        />
      </StyledAction>
    )
  }

  return (
    <StyledAction>
      <FontAwesomeIcon
        icon={faEye}
        className={classes.eye}
        onClick={() => setViewModal(true, company.id, company)}
      />
      <FontAwesomeIcon
        icon={faPenSquare}
        className={classes.edit}
        onClick={() => setEditModal(true, company.id, company)}
      />
      <FontAwesomeIcon
        icon={faTrash}
        className={classes.trash}
        onClick={() => setDeleteModal(true, company.id)}
      />
    </StyledAction>
  )
}

const mapDispatch = {
  setEditModal,
  setDeleteModal,
  setRestoreModal,
  setViewModal,
}

const mapState = state => ({
  deleted: state.deleted,
})

export default connect(mapState, mapDispatch)(Actions)
