import types from "../types"

const initialMeta = {
  last_page: 1,
}
const initialState = {
  array: [],
  meta: initialMeta,
  template: null,
}

const formatResponseData = data => {
  const array = data.map(e => {
    return {
      id: e.id,
      name: e.name,
      nCompanies: e.number_of_assigned_companies,
      // stupidEmptyColumn: null,
      // parent_id: e.parent_id,
      actions: true,
      // not displayable
    }
  })
  return array
}

export default function templates(state = initialState, action) {
  switch (action.type) {
    case types.GET_TEMPLATES_SUCCESS:
      const array = formatResponseData(action.payload.data)
      console.log("array = ", array)
      return {
        ...state,
        array: array,
        meta: action.payload.meta,
      }
    case types.SINGLE_TEMPLATE_SUCCESS:
      return {
        ...state,
        template: action.payload,
      }
    default:
      return state
  }
}
