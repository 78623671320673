import React, { useReducer, useEffect, useState } from "react"
import { connect } from "react-redux"
import * as actions from "../../../../../store/actions"
import {
  Row,
  SecondInputBlock,
  PhotoInputBlock,
  Col,
  AddModalContainer,
  CompanyInputBlock,
  SelectRow,
  LastInputBlock,
  RadioForm,
  RadioLabel,
  RadioInput,
  Title,
  SwitchRow,
} from "./styles"
import MyPhotoInput from "../../../../../components/MyPhotoInput"
import { SimpleInput } from "../../../../../components/Inputs"
import MySwitch from "../../../../../components/MySwitch"
import MySelect from "../../../../../components/MySelect"
import defaultTechnician from "./_defaultTechnician"
import { mapCompaniesToOptions, makeOption } from "../../../../../helpers"
import styled from "styled-components"
import { TimePicker } from "antd"
import moment from "moment"
import InputMask from "react-input-mask"

const Time = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const Label = styled.label`
  font-weight: bold;
`
const phoneInputStyles = {
  width: "180px",
  marginRight: "5%",
  marginTop: "22px",
  height: "30px",
  borderRadius: "2px",
  padding: "10px",
  boxSizing: "borderBox",
  border: "0.5px solid #dde",
  position: "relative",
}

const PhoneTitle = {
  position: "absolute",
  left: "0",
  color: "#565656",
  fontSize: "0.8rem",
}

// TODO: move alerts to saga
const NameInputs = ({ handlers, values, hideLastName }) => {
  return (
    <SecondInputBlock>
      <Row>
        {hideLastName ? (
          <SimpleInput
            title="Name*"
            value={values[0]}
            name="name"
            onChange={handlers[0]}
          />
        ) : (
          <>
            <SimpleInput
              title="First Name*"
              value={values[0]}
              name="name"
              onChange={handlers[0]}
            />
            <SimpleInput
              title="Last Name*"
              value={values[1]}
              name="lastName"
              onChange={handlers[1]}
            />
          </>
        )}
      </Row>
      <Row style={{ position: "relative" }}>
        {/* <SimpleInput
          title="Phone*"
          value={values[2]}
          placeholder="10 digits"
          name="phone"
          onChange={handlers[2]}
        /> */}

        <InputMask
          mask="999-999-9999"
          placeholder="999-999-9999"
          style={phoneInputStyles}
          onChange={e => {
            handlers[2](e.target.value)
          }}
          value={values[2]}
          name="phone"
        />
        <div style={PhoneTitle}>Phone*</div>

        <SimpleInput
          title="Email*"
          value={values[3]}
          name="email"
          onChange={handlers[3]}
        />
      </Row>
    </SecondInputBlock>
  )
}

function reducer(state, action) {
  switch (action.type) {
    case "set_street":
      return { ...state, street: action.payload }
    case "set_appartment":
      return { ...state, appartment: action.payload }
    case "set_zip":
      return { ...state, zip: action.payload }
    case "set_city":
      return { ...state, city: action.payload }
    case "set_photo":
      return { ...state, photo: action.payload }
    case "set_phone":
      return { ...state, phone: action.payload }
    case "set_company":
      return { ...state, company: action.payload }
    case "set_name":
      return { ...state, name: action.payload }
    case "set_last_name":
      return { ...state, last_name: action.payload }
    case "set_notification":
      return { ...state, notification: action.payload }
    case "set_email":
      return { ...state, email: action.payload }
    case "set_is_bodyshop":
      return { ...state, is_bodyshop: action.payload }
    case "set_company_id":
      return { ...state, company_id: action.payload }
    case "set_open_time":
      return { ...state, open_time: action.payload }
    case "set_close_time":
      return { ...state, close_time: action.payload }
    default:
      throw new Error()
  }
}

const getInitialState = technicianFromEdit => {
  if (technicianFromEdit) {
    return {
      name: technicianFromEdit.name.split(" ")[0],
      last_name: technicianFromEdit.name.split(" ")[1],
      email: technicianFromEdit.email,
      phone: technicianFromEdit.phone,
      city: technicianFromEdit.city,
      street: technicianFromEdit.street,
      appartment:
        typeof technicianFromEdit.appartment !== "undefined"
          ? technicianFromEdit.appartment
          : "",
      zip: technicianFromEdit.zip,
      notification: true,
      company_id: technicianFromEdit.company_id,
      company_option: null,
      is_bodyshop: technicianFromEdit.type === "Bodyshop",
      photo: [],
    }
  }
  const def = {
    name: "",
    last_name: "",
    email: "",
    phone: "",
    city: "",
    street: "",
    appartment: "",
    zip: "",
    notification: true,
    company_id: null,
    company_option: null,
    is_bodyshop: false,
    photo: [],
    open_time: moment("5:00 am", "h:mm a"),
    close_time: moment("6:00 pm", "h:mm a"),
  }

  return def
}

const AddModal = ({
  callback,
  storedCompanies,
  getAllCompanies,
  getAllCompaniesStatus,
  technician,
}) => {
  const [state, dispatch] = useReducer(reducer, getInitialState(technician))
  const [companyOptions, setCompanyOptions] = useState([])
  const [openTime, setOpenTime] = useState("")
  const [closeTime, setCloseTime] = useState("")

  useEffect(() => {
    getAllCompanies()
  }, [])

  useEffect(() => {
    setCompanyOptions(mapCompaniesToOptions(storedCompanies))
  }, [storedCompanies])

  useEffect(() => {
    if (!state.company_id) return
    console.log("companyOptions", companyOptions)
    const defaultCompany = companyOptions.find(e => e.id === state.company_id)
    dispatch({ type: "set_company", payload: defaultCompany })
    console.log("defaultCompany", defaultCompany)
  }, [companyOptions])

  console.log("technician === ", technician)
  useEffect(() => {
    const newTechnician = {
      first_name: state.name,
      last_name: state.last_name,
      company_id: state.company_id,
      email: state.email,
      phone: state.phone,
      send_mail: state.notification,
      is_bodyshop: state.is_bodyshop,
      open_time: state.open_time,
      close_time: state.close_time,
    }
    if (state.is_bodyshop) {
      newTechnician.city = state.city
      newTechnician.appartment = state.appartment
      newTechnician.street = state.street
      newTechnician.zip = state.zip
      delete newTechnician.last_name
    }
    if (false) {
      newTechnician.image = state.photo
    }
    callback(newTechnician)
  }, [state])

  const handleSetPhoto = e => {
    dispatch({ type: "set_photo", payload: e })
  }

  const handleChangePhone = e => {
    const reg = /[0-9]/
    const char = e.charAt(e.length - 1)
    if (reg.test(char) || char === "") {
      dispatch({ type: "set_phone", payload: e })
    }
  }

  const handleChangeCompany = e => {
    dispatch({ type: "set_company", payload: e })
    dispatch({ type: "set_company_id", payload: e ? e.id : null })
  }

  const handleChangeOpenTime = (time, timeString) => {
    // console.log(timeString);
    setOpenTime(moment(timeString, "h:mm a"))
    dispatch({
      type: "set_open_time",
      payload: moment(timeString, "h:mm a").format("h:mm a"),
    })
  }

  const handleChangeCloseTime = (time, timeString) => {
    setCloseTime(moment(timeString, "h:mm a"))
    dispatch({
      type: "set_close_time",
      payload: moment(timeString, "h:mm a").format("h:mm a"),
    })
  }

  return (() => {
    console.log("aps", state.close_time)
    let open_time =
      typeof state.open_time === "undefined"
        ? moment("5:00 am", "h:mm a")
        : moment(state.open_time, "h:mm a")
    let close_time =
      typeof state.open_time === "undefined"
        ? moment("6:00 pm", "h:mm a")
        : moment(state.close_time, "h:mm a")

    if (
      typeof state.open_time === "undefined" &&
      typeof technician.open_time !== "undefined"
    ) {
      open_time = moment(technician.open_time, "h:mm a")
    }

    if (
      typeof state.close_time === "undefined" &&
      typeof technician.close_time !== "undefined"
    ) {
      close_time = moment(technician.close_time, "h:mm a")
    }

    return (
      <AddModalContainer>
        <PhotoInputBlock>
          <Col flex={0.5}>
            <Title> Add Photo: </Title>
          </Col>
          <Col>
            <MyPhotoInput
              value={state.photo}
              onChange={handleSetPhoto}
            ></MyPhotoInput>
          </Col>
        </PhotoInputBlock>
        <NameInputs
          values={[state.name, state.last_name, state.phone, state.email]}
          handlers={[
            e => dispatch({ type: "set_name", payload: e }),
            e => dispatch({ type: "set_last_name", payload: e }),
            // handleChangePhone,
            e => dispatch({ type: "set_phone", payload: e }),
            e => dispatch({ type: "set_email", payload: e }),
          ]}
          hideLastName={state.is_bodyshop}
        />
        <CompanyInputBlock>
          <Col>
            <SelectRow>
              <MySelect
                title="Company"
                value={state.company}
                options={
                  getAllCompaniesStatus === "loading" ? [] : companyOptions
                }
                name="company"
                //   onMenuOpen={handleOpenCompaniesSelect}
                isLoading={getAllCompaniesStatus === "loading"}
                placeholder={"Select"}
                onChange={handleChangeCompany}
              ></MySelect>
            </SelectRow>
            <RadioForm>
              <RadioLabel>
                <RadioInput
                  type="radio"
                  value="Bodyshop"
                  onClick={() =>
                    dispatch({ type: "set_is_bodyshop", payload: true })
                  }
                  checked={state.is_bodyshop}
                />
                Bodyshop
              </RadioLabel>
              <RadioLabel>
                <RadioInput
                  type="radio"
                  value="Technician"
                  onClick={() =>
                    dispatch({ type: "set_is_bodyshop", payload: false })
                  }
                  checked={!state.is_bodyshop}
                />
                Technician
              </RadioLabel>
            </RadioForm>
          </Col>
          <Col>
            <SwitchRow>
              <Title>Send Notification</Title>
              <MySwitch
                active={state.notification}
                setActive={e =>
                  dispatch({ type: "set_notification", payload: e })
                }
              />
            </SwitchRow>
          </Col>
        </CompanyInputBlock>
        <LastInputBlock>
          <Col>
            <SimpleInput
              title="Street"
              value={state.street}
              name="street"
              onChange={e => dispatch({ type: "set_street", payload: e })}
              disabled={!state.is_bodyshop}
            />
            <SimpleInput
              title="City"
              value={state.city}
              name="city"
              onChange={e => dispatch({ type: "set_city", payload: e })}
              disabled={!state.is_bodyshop}
            />
          </Col>
          <Col>
            <SimpleInput
              title="Appartment"
              value={state.appartment}
              name="apt"
              onChange={e => dispatch({ type: "set_appartment", payload: e })}
              disabled={!state.is_bodyshop}
            />
            <SimpleInput
              title="Zip Code"
              value={state.zip}
              placeholder="5 digits"
              name="zip"
              onChange={e => dispatch({ type: "set_zip", payload: e })}
              disabled={!state.is_bodyshop}
            />
          </Col>
        </LastInputBlock>
        <Row>
          <Time style={{ marginBottom: "10px" }}>
            <div>
              <Label htmlFor="">Open Time</Label>
              <TimePicker
                label="Open Time"
                use12Hours
                defaultValue={moment("5:00 am", "h:mm a")}
                format={`h:mm a`}
                minuteStep={15}
                style={{ width: "100%" }}
                name="openTime"
                value={open_time}
                onChange={handleChangeOpenTime}
              />
            </div>
            <div>
              <Label htmlFor="">Close Time</Label>
              <TimePicker
                label="Close Time"
                use12Hours
                defaultValue={moment("6:00 pm", "h:mm a")}
                format={`h:mm a`}
                minuteStep={15}
                style={{ width: "100%" }}
                name="closeTime"
                value={close_time}
                onChange={handleChangeCloseTime}
              />
            </div>
          </Time>
        </Row>
      </AddModalContainer>
    )
  })()
}

const connectState = state => ({
  storedCompanies: state.companies.listCompanies,
  getAllCompaniesStatus: state.statuses.allCompanies,
})

const connectActions = {
  getAllCompanies: actions.getAllCompaniesRequest,
}

export default connect(connectState, connectActions)(AddModal)
