import { fromJS, isImmutable } from "immutable"
import types from "../types"

const initialState = fromJS({
  modal: {
    show: false,
    package: {
      name: "",
      price: "",
      templates: [],
    },
  },
})

const paymentPackage = (state = initialState, action) => {
  if (!isImmutable(state)) {
    state = fromJS(state)
  }

  switch (action.type) {
    case types.TOGGLE_EDIT_MODAL:
      return state
        .setIn(["modal", "show"], !state.getIn(["modal", "show"]))
        .toJS()
    case types.SET_PAYMENT_PACKAGE:
      return state.setIn(["modal", "package"], action.paymentPackage).toJS()
    default:
      return state
  }
}

export default paymentPackage
