import { put, call, select } from "redux-saga/effects"

import * as actions from "../../actions"
import axios from "axios"

const getToken = state => state.auth.token
const getPage = state => state.currentPages.technicians
const getShowDeleted = state => state.deleted.show

const apiCall = (pack, id, token) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_PATH,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const path = `/dashboard/technicians/${id}`
  return instance
    .patch(path, pack)
    .then(response => response)
    .catch(error => error.response)
}

const formatParams = payload => {
  const {
    image,
    first_name,
    last_name,
    phone,
    email,
    company_id,
    is_bodyshop,
    street,
    appartment,
    city,
    zip,
    send_mail,
  } = payload
  // formatted in add modal
  return payload
}
export default function* editTechnicianAsync(action) {
  try {
    const token = yield select(getToken)
    const res = yield call(
      apiCall,
      formatParams(action.payload.formData),
      action.payload.id,
      token,
    )

    console.log(" res = ", res)
    if (res.status === 200) {
      yield put(actions.editTechnicianSuccess())
      const getParams = {
        page: yield select(getPage),
        soft_deleted: yield select(getShowDeleted),
        search: null,
        sort: null,
      }
      yield put(actions.getTechniciansRequest(getParams))
    } else {
      for (let prop in res.data.error) {
        alert(res.data.error[prop])
      }
      yield put(actions.editTechnicianFailure(res.data))
    }
  } catch (err) {
    yield put(actions.editTechnicianFailure(err))
  }
}
