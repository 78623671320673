import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import {
  faBox,
  faUsers,
  faPager,
  faListOl,
  faPaperPlane,
  faWrench,
  faUserClock,
  faUser,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons"
import { Layout, Menu } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const { Sider } = Layout

const SiderMenu = () => {
  const [isSiderCollapsed, setIsSiderCollapsed] = useState(false)
  const history = useHistory()

  const getPathByKey = (key, array) => {
    let path = null
    if (!array) array = menuItems

    array.forEach(item => {
      if (path) return

      if (item.children) {
        const childrenPath = getPathByKey(key, item.children)

        if (childrenPath) return (path = childrenPath)
      }

      if (item.key === key) path = item.path
    })

    return path
  }

  const getKeyByPath = (path, array) => {
    let key = null
    if (!array) array = menuItems

    array.forEach(item => {
      if (key) return

      if (item.children) {
        const childrenKey = getKeyByPath(path, item.children)

        if (childrenKey) return (key = childrenKey)
      }

      if (item.path === path) key = item.key
    })

    return key
  }

  const handleMenuItemClick = ({ key }) => {
    const path = getPathByKey(key)

    history.push(path)
  }

  return (
    <Sider
      collapsible
      collapsed={isSiderCollapsed}
      onCollapse={value => setIsSiderCollapsed(value)}
      theme="light"
    >
      <Menu
        theme="light"
        defaultSelectedKeys={[getKeyByPath(history.location.pathname)]}
        mode="inline"
        items={menuItems}
        onClick={handleMenuItemClick}
      />
    </Sider>
  )
}
const menuItems = [
  {
    label: "Dashboard",
    key: "1",
    icon: <FontAwesomeIcon icon={faPager} />,
    path: "/",
  },
  {
    label: "Users",
    key: "2",
    icon: <FontAwesomeIcon icon={faUsers} />,
    children: [
      {
        label: "Technicians",
        key: "3",
        icon: <FontAwesomeIcon icon={faWrench} />,
        path: "/technicians",
      },
      {
        label: "Pending Technicians",
        key: "4",
        icon: <FontAwesomeIcon icon={faUserClock} />,
        path: "/pending",
      },
      {
        label: "Customers",
        key: "5",
        icon: <FontAwesomeIcon icon={faUser} />,
        path: "/customers",
      },
      {
        label: "Companies",
        key: "14",
        icon: <FontAwesomeIcon icon={faBuilding} />,
        path: "/companies",
      },
    ],
  },
  {
    label: "Appointments",
    key: "6",
    icon: <FontAwesomeIcon icon={faListOl} />,
    path: "/appointments",
  },
  {
    label: "Templates",
    key: "7",
    icon: <FontAwesomeIcon icon={faBox} />,
    children: [
      {
        label: "Vehicle Types",
        key: "8",
        path: "/vehicle-types",
      },
      {
        label: "Factors",
        key: "9",
        path: "/factors",
      },
      {
        label: "Categories",
        key: "10",
        path: "/vehicle-types",
      },
      {
        label: "Templates",
        key: "11",
        path: "/categories",
      },
      {
        label: "Packages",
        key: "12",
        path: "/packages",
      },
    ],
  },
  {
    label: "Notifications",
    key: "13",
    icon: <FontAwesomeIcon icon={faPaperPlane} />,
    path: "/notifications",
  },
]

export default SiderMenu
