import { put, call, select } from "redux-saga/effects"

import * as actions from "../../actions"
import axios from "axios"

const getToken = state => state.auth.token
const getCurrentFactor = state => state.sub.selected.id

// {
// 	"name":"Updated 123",
// 	"parent_id": 1223,
// 	"panels": "qwe1",
// 	"score": "qwe0.3"
// }

// /dashboard/subfactor/1

const apiCall = (pack, id, token) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_PATH,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const path = `/dashboard/subfactor/${id}`
  return instance
    .put(path, pack)
    .then(response => response)
    .catch(error => error.response)
}

const formatParams = (payload, parentId) => {
  // formatted in add modal

  return {
    ...payload,
    parent_id: parentId,
  }
}
export default function* editSubFactorAsync(action) {
  try {
    const token = yield select(getToken)
    const parentId = yield select(getCurrentFactor)
    const res = yield call(
      apiCall,
      formatParams(action.payload.formData, parentId),
      action.payload.id,
      token,
    )

    console.log(" res = ", res)
    if (res.status === 200) {
      yield put(actions.editSubFactorSuccess())
      yield put(actions.getSubFactorsRequest())
    } else {
      alert("Error Editing Sub Factor")
      yield put(actions.editSubFactorFailure(res.data))
    }
  } catch (err) {
    alert("Error Editing SubFactor")
    yield put(actions.editSubFactorFailure(err))
  }
}
