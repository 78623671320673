import { createStore, applyMiddleware } from "redux"
import { logger } from "redux-logger"
import createSagaMiddleware from "redux-saga"
import rootReducer from "./reducers"
import rootSaga from "./sagas"

/* Keep store data in LocalStorage */
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2"
import { persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage"

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ["auth", "createTemplate"],
}

const sagaMiddleware = createSagaMiddleware()

const devTools = process.env.NODE_ENV === "development" ? logger : null
const middlewares = [sagaMiddleware, devTools].filter(i => i)

const store = createStore(
  persistReducer(persistConfig, rootReducer),
  applyMiddleware(...middlewares),
)

sagaMiddleware.run(rootSaga)

export default store
export const persistor = persistStore(store)
