import types from "./../types"

export const toggleEditModal = () => {
  return {
    type: types.TOGGLE_EDIT_MODAL,
  }
}

export const setPackage = paymentPackage => {
  return {
    type: types.SET_PAYMENT_PACKAGE,
    paymentPackage,
  }
}
