import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { SimpleButton } from "../../../../components/Buttons"
import {
  setAssignModal,
  setDeleteModal,
  setRestoreModal,
  setEditModal,
  setActiveTechnicianRequest,
  clearSetActiveTechnicianStatus,
} from "../../../../store/actions"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash, faPenSquare } from "@fortawesome/free-solid-svg-icons"
import MySwitch from "../../../../components/MySwitch"
import classes from "../../classes.module.css"
import { StyledAction } from "../../styledComponents"

const Actions = ({
  id,
  isActive,
  deleted,
  setEditModal,
  setDeleteModal,
  setRestoreModal,
  setActiveTechnicianRequest,
  setActiveTechnicianStatus,
  clearSetActiveTechnicianStatus,
}) => {
  const [active, setActive] = useState(isActive)
  const [loading, setLoading] = useState(false)

  const handleChangeActive = e => {
    setActiveTechnicianRequest(id)
    setLoading(true)
  }

  useEffect(() => {
    if (setActiveTechnicianStatus === "success" && loading) {
      setActive(!active)
      setLoading(false)
      clearSetActiveTechnicianStatus()
    }
  }, [setActiveTechnicianStatus])

  if (deleted.show) {
    return (
      <StyledAction>
        <SimpleButton
          title="Restore"
          onClick={() => setRestoreModal(true, id)}
        />
      </StyledAction>
    )
  }
  return (
    <StyledAction>
      <MySwitch active={active} setActive={handleChangeActive} />
      <FontAwesomeIcon
        icon={faPenSquare}
        className={classes.edit}
        onClick={() => setEditModal(true, id)}
      />
      <FontAwesomeIcon
        icon={faTrash}
        className={classes.trash}
        onClick={() => setDeleteModal(true, id)}
      />
    </StyledAction>
  )
}

const mapDispatch = {
  setAssignModal,
  setDeleteModal,
  setRestoreModal,
  setEditModal,
  setActiveTechnicianRequest,
  clearSetActiveTechnicianStatus,
}

const mapState = state => ({
  deleted: state.deleted,
  setActiveTechnicianStatus: state.statuses.setActiveTechnician,
})

export default connect(mapState, mapDispatch)(Actions)
