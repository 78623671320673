const theme = {
  colors: {
    greenMain: "#559966",
    blackMain: "#565656",
    borderMain: "#dde",
    greyLight: "#F2F2F2",
    greyReg: "#E4EAE6",
    blueGrey: "#6A6A6A",
    blueLight: "#F2F2F2",
    borderDarkBlue: "#C4C4C4",
  },
  font: {
    familyMain: "'Roboto', sans-serif",
    s1: "5rem",
    s2: "2.5rem",
    lg: "1.5rem",
    sm: "0.8rem",
    xs: "0.65rem",
    md: "0.9rem",
    md2: "1.1rem",
    regular: 400,
    semi: 500,
    bold: 700,
  },
  size: {
    minWidth: "150px",
    asideWidth: "220px",
    headerHeight: "50px",
  },
}

export default theme
