import appointmentToolbar from "./types/appointmentToolbar"
import paymentPackage from "./types/paymentPackage"
import template from "./types/template"

const types = {
  CHECK_AUTH: "CHECK_AUTH",
  GET_TEST_FAILURE: "GET_TEST_FAILURE",
  GET_TEST_REQUEST: "GET_TEST_REQUEST",
  GET_TEST_SUCCESS: "GET_TEST_SUCCESS",
  REGISTER_REQUEST: "REGISTER_REQUEST",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_FAILURE: "REGISTER_FAILURE",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  CLEAR_LOGIN_STATUS: "CLEAR_LOGIN_STATUS",
  SIGN_OUT: "SIGN_OUT",
  SET_SHOW_DELETED: "SET_SHOW_DELETED",
  SET_SHOW_SUB: "SET_SHOW_SUB",
  SET_DELETE_MODAL: "SET_DELETE_MODAL",
  SET_ASSIGN_MODAL: "SET_ASSIGN_MODAL",
  SET_RESTORE_MODAL: "SET_RESTORE_MODAL",
  SET_ADD_MODAL: "SET_ADD_MODAL",
  SET_EDIT_MODAL: "SET_EDIT_MODAL",
  SET_VIEW_MODAL: "SET_VIEW_MODAL",
  SET_ADD_COMPANY_MODAL: "SET_ADD_COMPANY_MODAL",
  SET_TEMPLATE_CURRENT_CATEGORY: "SET_TEMPLATE_CURRENT_CATEGORY",
  PUSH_TEMPLATE_CATEGORY: "PUSH_TEMPLATE_CATEGORY",
  RESET_TEMPLATE_CATEGORIES: "RESET_TEMPLATE_CATEGORIES",
  UPDATE_TEMPLATE_CATEGORIES: "UPDATE_TEMPLATE_CATEGORIES",
  REMOVE_TEMPLATE_CATEGORIES: "REMOVE_TEMPLATE_CATEGORIES",
  GET_PENDING_SUCCESS: "GET_PENDING_SUCCESS",
  GET_PENDING_FAILURE: "GET_PENDING_FAILURE",
  GET_PENDING_REQUEST: "GET_PENDING_REQUEST",
  GET_TECHNICIANS_SUCCESS: "GET_TECHNICIANS_SUCCESS",
  GET_TECHNICIANS_FAILURE: "GET_TECHNICIANS_FAILURE",
  GET_TECHNICIANS_REQUEST: "GET_TECHNICIANS_REQUEST",
  SET_CURRENT_PAGE_PENDING: "SET_CURRENT_PAGE_PENDING",
  SET_CURRENT_PAGE_TECHNICIANS: "SET_CURRENT_PAGE_TECHNICIANS",
  GET_ALL_COMPANIES_REQUEST: "GET_ALL_COMPANIES_REQUEST",
  GET_ALL_COMPANIES_SUCCESS: "GET_ALL_COMPANIES_SUCCESS",
  GET_ALL_COMPANIES_FAILURE: "GET_ALL_COMPANIES_FAILURE",
  GET_COMPANIES_REQUEST: "GET_COMPANIES_REQUEST",
  GET_COMPANIES_SUCCESS: "GET_COMPANIES_SUCCESS",
  GET_COMPANIES_FAILURE: "GET_COMPANIES_FAILURE",
  SET_CURRENT_PAGE_COMPANIES: "SET_CURRENT_PAGE_COMPANIES",
  CREATE_TECHNICIAN_REQUEST: "CREATE_TECHNICIAN_REQUEST",
  CREATE_TECHNICIAN_SUCCESS: "CREATE_TECHNICIAN_SUCCESS",
  CREATE_TECHNICIAN_FAILURE: "CREATE_TECHNICIAN_FAILURE",
  CREATE_COMPANY_REQUEST: "CREATE_COMPANY_REQUEST",
  CREATE_COMPANY_SUCCESS: "CREATE_COMPANY_SUCCESS",
  CREATE_COMPANY_FAILURE: "CREATE_COMPANY_FAILURE",
  EDIT_TECHNICIAN_REQUEST: "EDIT_TECHNICIAN_REQUEST",
  CLEAR_CREATE_COMPANY_STATUS: "CLEAR_CREATE_COMPANY_STATUS",
  EDIT_TECHNICIAN_SUCCESS: "EDIT_TECHNICIAN_SUCCESS",
  EDIT_TECHNICIAN_FAILURE: "EDIT_TECHNICIAN_FAILURE",
  CLEAR_CREATE_TECHNICIAN_STATUS: "CLEAR_CREATE_TECHNICIAN_STATUS",
  ASSIGN_PENDING_REQUEST: "ASSIGN_PENDING_REQUEST",
  ASSIGN_PENDING_SUCCESS: "ASSIGN_PENDING_SUCCESS",
  ASSIGN_PENDING_FAILURE: "ASSIGN_PENDING_FAILURE",
  CLEAR_ASSIGN_PENDING_STATUS: "CLEAR_ASSIGN_PENDING_STATUS",
  SET_ACTIVE_TECHNICIAN_REQUEST: "SET_ACTIVE_TECHNICIAN_REQUEST",
  SET_ACTIVE_TECHNICIAN_SUCCESS: "SET_ACTIVE_TECHNICIAN_SUCCESS",
  SET_ACTIVE_TECHNICIAN_FAILURE: "SET_ACTIVE_TECHNICIAN_FAILURE",
  CLEAR_SET_ACTIVE_TECHNICIAN_STATUS: "CLEAR_SET_ACTIVE_TECHNICIAN_STATUS",
  DELETE_TECHNICIAN_REQUEST: "DELETE_TECHNICIAN_REQUEST",
  DELETE_TECHNICIAN_SUCCESS: "DELETE_TECHNICIAN_SUCCESS",
  DELETE_TECHNICIAN_FAILURE: "DELETE_TECHNICIAN_FAILURE",
  RESTORE_TECHNICIAN_REQUEST: "RESTORE_TECHNICIAN_REQUEST",
  RESTORE_TECHNICIAN_SUCCESS: "RESTORE_TECHNICIAN_SUCCESS",
  RESTORE_TECHNICIAN_FAILURE: "RESTORE_TECHNICIAN_FAILURE",
  DELETE_COMPANY_REQUEST: "DELETE_COMPANY_REQUEST",
  DELETE_COMPANY_SUCCESS: "DELETE_COMPANY_SUCCESS",
  DELETE_COMPANY_FAILURE: "DELETE_COMPANY_FAILURE",
  RESTORE_COMPANY_REQUEST: "RESTORE_COMPANY_REQUEST",
  RESTORE_COMPANY_SUCCESS: "RESTORE_COMPANY_SUCCESS",
  RESTORE_COMPANY_FAILURE: "RESTORE_COMPANY_FAILURE",
  DELETE_PENDING_REQUEST: "DELETE_PENDING_REQUEST",
  DELETE_PENDING_SUCCESS: "DELETE_PENDING_SUCCESS",
  DELETE_PENDING_FAILURE: "DELETE_PENDING_FAILURE",
  RESTORE_PENDING_REQUEST: "RESTORE_PENDING_REQUEST",
  RESTORE_PENDING_SUCCESS: "RESTORE_PENDING_SUCCESS",
  RESTORE_PENDING_FAILURE: "RESTORE_PENDING_FAILURE",
  EDIT_COMPANY_REQUEST: "EDIT_COMPANY_REQUEST",
  EDIT_COMPANY_SUCCESS: "EDIT_COMPANY_SUCCESS",
  EDIT_COMPANY_FAILURE: "EDIT_COMPANY_FAILURE",
  GET_COMPANY_INFO_REQUEST: "GET_COMPANY_INFO_REQUEST",
  GET_COMPANY_INFO_SUCCESS: "GET_COMPANY_INFO_SUCCESS",
  GET_COMPANY_INFO_FAILURE: "GET_COMPANY_INFO_FAILURE",
  GET_CUSTOMERS_REQUEST: "GET_CUSTOMERS_REQUEST",
  GET_CUSTOMERS_SUCCESS: "GET_CUSTOMERS_SUCCESS",
  GET_CUSTOMERS_FAILURE: "GET_CUSTOMERS_FAILURE",
  CREATE_CUSTOMER_REQUEST: "CREATE_CUSTOMER_REQUEST",
  CREATE_CUSTOMER_SUCCESS: "CREATE_CUSTOMER_SUCCESS",
  CREATE_CUSTOMER_FAILURE: "CREATE_CUSTOMER_FAILURE",
  DELETE_CUSTOMER_REQUEST: "DELETE_CUSTOMER_REQUEST",
  DELETE_CUSTOMER_SUCCESS: "DELETE_CUSTOMER_SUCCESS",
  DELETE_CUSTOMER_FAILURE: "DELETE_CUSTOMER_FAILURE",
  RESTORE_CUSTOMER_REQUEST: "RESTORE_CUSTOMER_REQUEST",
  RESTORE_CUSTOMER_SUCCESS: "RESTORE_CUSTOMER_SUCCESS",
  RESTORE_CUSTOMER_FAILURE: "RESTORE_CUSTOMER_FAILURE",
  EDIT_CUSTOMER_REQUEST: "EDIT_CUSTOMER_REQUEST",
  EDIT_CUSTOMER_SUCCESS: "EDIT_CUSTOMER_SUCCESS",
  EDIT_CUSTOMER_FAILURE: "EDIT_CUSTOMER_FAILURE",
  SET_CURRENT_PAGE_CUSTOMERS: "SET_CURRENT_PAGE_CUSTOMERS",
  CLEAR_CREATE_CUSTOMER_STATUS: "CLEAR_CREATE_CUSTOMER_STATUS",

  GET_VEHICLES_REQUEST: "GET_VEHICLES_REQUEST",
  GET_VEHICLES_SUCCESS: "GET_VEHICLES_SUCCESS",
  GET_VEHICLES_FAILURE: "GET_VEHICLES_FAILURE",
  CREATE_VEHICLE_REQUEST: "CREATE_VEHICLE_REQUEST",
  CREATE_VEHICLE_SUCCESS: "CREATE_VEHICLE_SUCCESS",
  CREATE_VEHICLE_FAILURE: "CREATE_VEHICLE_FAILURE",
  DELETE_VEHICLE_REQUEST: "DELETE_VEHICLE_REQUEST",
  DELETE_VEHICLE_SUCCESS: "DELETE_VEHICLE_SUCCESS",
  DELETE_VEHICLE_FAILURE: "DELETE_VEHICLE_FAILURE",
  RESTORE_VEHICLE_REQUEST: "RESTORE_VEHICLE_REQUEST",
  RESTORE_VEHICLE_SUCCESS: "RESTORE_VEHICLE_SUCCESS",
  RESTORE_VEHICLE_FAILURE: "RESTORE_VEHICLE_FAILURE",
  EDIT_VEHICLE_REQUEST: "EDIT_VEHICLE_REQUEST",
  EDIT_VEHICLE_SUCCESS: "EDIT_VEHICLE_SUCCESS",
  EDIT_VEHICLE_FAILURE: "EDIT_VEHICLE_FAILURE",
  SET_CURRENT_PAGE_VEHICLES: "SET_CURRENT_PAGE_VEHICLES",
  CLEAR_CREATE_VEHICLE_STATUS: "CLEAR_CREATE_VEHICLE_STATUS",

  SET_ACTIVE_VEHICLE_REQUEST: "SET_ACTIVE_VEHICLE_REQUEST",
  SET_ACTIVE_VEHICLE_SUCCESS: "SET_ACTIVE_VEHICLE_SUCCESS",
  SET_ACTIVE_VEHICLE_FAILURE: "SET_ACTIVE_VEHICLE_FAILURE",
  CLEAR_SET_ACTIVE_VEHICLE_STATUS: "CLEAR_SET_ACTIVE_VEHICLE_STATUS",
  UPDATE_VEHICLE_TYPE_STATUS: "UPDATE_VEHICLE_TYPE_STATUS",

  GET_FACTORS_REQUEST: "GET_FACTORS_REQUEST",
  GET_FACTORS_SUCCESS: "GET_FACTORS_SUCCESS",
  GET_FACTORS_FAILURE: "GET_FACTORS_FAILURE",
  GET_ONE_FACTOR_REQUEST: "GET_ONE_FACTOR_REQUEST",
  GET_ONE_FACTOR_SUCCESS: "GET_ONE_FACTOR_SUCCESS",
  GET_ONE_FACTOR_FAILURE: "GET_ONE_FACTOR_FAILURE",
  CREATE_FACTOR_REQUEST: "CREATE_FACTOR_REQUEST",
  CREATE_FACTOR_SUCCESS: "CREATE_FACTOR_SUCCESS",
  CREATE_FACTOR_FAILURE: "CREATE_FACTOR_FAILURE",
  DELETE_FACTOR_REQUEST: "DELETE_FACTOR_REQUEST",
  DELETE_FACTOR_SUCCESS: "DELETE_FACTOR_SUCCESS",
  DELETE_FACTOR_FAILURE: "DELETE_FACTOR_FAILURE",
  RESTORE_FACTOR_REQUEST: "RESTORE_FACTOR_REQUEST",
  RESTORE_FACTOR_SUCCESS: "RESTORE_FACTOR_SUCCESS",
  RESTORE_FACTOR_FAILURE: "RESTORE_FACTOR_FAILURE",
  EDIT_FACTOR_REQUEST: "EDIT_FACTOR_REQUEST",
  EDIT_FACTOR_SUCCESS: "EDIT_FACTOR_SUCCESS",
  EDIT_FACTOR_FAILURE: "EDIT_FACTOR_FAILURE",
  SET_CURRENT_PAGE_FACTORS: "SET_CURRENT_PAGE_FACTORS",
  CLEAR_CREATE_FACTOR_STATUS: "CLEAR_CREATE_FACTOR_STATUS",

  GET_CATEGORIES_REQUEST: "GET_CATEGORIES_REQUEST",
  GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
  GET_CATEGORIES_FAILURE: "GET_CATEGORIES_FAILURE",
  CREATE_CATEGORY_REQUEST: "CREATE_CATEGORY_REQUEST",
  CREATE_CATEGORY_SUCCESS: "CREATE_CATEGORY_SUCCESS",
  CREATE_CATEGORY_FAILURE: "CREATE_CATEGORY_FAILURE",
  DELETE_CATEGORY_REQUEST: "DELETE_CATEGORY_REQUEST",
  DELETE_CATEGORY_SUCCESS: "DELETE_CATEGORY_SUCCESS",
  DELETE_CATEGORY_FAILURE: "DELETE_CATEGORY_FAILURE",
  RESTORE_CATEGORY_REQUEST: "RESTORE_CATEGORY_REQUEST",
  RESTORE_CATEGORY_SUCCESS: "RESTORE_CATEGORY_SUCCESS",
  RESTORE_CATEGORY_FAILURE: "RESTORE_CATEGORY_FAILURE",
  EDIT_CATEGORY_REQUEST: "EDIT_CATEGORY_REQUEST",
  EDIT_CATEGORY_SUCCESS: "EDIT_CATEGORY_SUCCESS",
  EDIT_CATEGORY_FAILURE: "EDIT_CATEGORY_FAILURE",
  SET_CURRENT_PAGE_CATEGORIES: "SET_CURRENT_PAGE_CATEGORIES",
  CLEAR_CREATE_CATEGORY_STATUS: "CLEAR_CREATE_CATEGORY_STATUS",

  GET_TEMPLATES_REQUEST: "GET_TEMPLATES_REQUEST",
  GET_TEMPLATES_SUCCESS: "GET_TEMPLATES_SUCCESS",
  GET_TEMPLATES_FAILURE: "GET_TEMPLATES_FAILURE",
  CREATE_TEMPLATE_REQUEST: "CREATE_TEMPLATE_REQUEST",
  CREATE_TEMPLATE_SUCCESS: "CREATE_TEMPLATE_SUCCESS",
  CREATE_TEMPLATE_FAILURE: "CREATE_TEMPLATE_FAILURE",
  DELETE_TEMPLATE_REQUEST: "DELETE_TEMPLATE_REQUEST",
  DELETE_TEMPLATE_SUCCESS: "DELETE_TEMPLATE_SUCCESS",
  DELETE_TEMPLATE_FAILURE: "DELETE_TEMPLATE_FAILURE",
  RESTORE_TEMPLATE_REQUEST: "RESTORE_TEMPLATE_REQUEST",
  RESTORE_TEMPLATE_SUCCESS: "RESTORE_TEMPLATE_SUCCESS",
  RESTORE_TEMPLATE_FAILURE: "RESTORE_TEMPLATE_FAILURE",
  EDIT_TEMPLATE_REQUEST: "EDIT_TEMPLATE_REQUEST",
  EDIT_TEMPLATE_SUCCESS: "EDIT_TEMPLATE_SUCCESS",
  EDIT_TEMPLATE_FAILURE: "EDIT_TEMPLATE_FAILURE",
  SET_CURRENT_PAGE_TEMPLATES: "SET_CURRENT_PAGE_TEMPLATES",
  CLEAR_CREATE_TEMPLATE_STATUS: "CLEAR_CREATE_TEMPLATE_STATUS",

  GET_SUB_CATEGORIES_REQUEST: "GET_SUB_CATEGORIES_REQUEST",
  GET_SUB_CATEGORIES_SUCCESS: "GET_SUB_CATEGORIES_SUCCESS",
  GET_SUB_CATEGORIES_FAILURE: "GET_SUB_CATEGORIES_FAILURE",
  CREATE_SUB_CATEGORY_REQUEST: "CREATE_SUB_CATEGORY_REQUEST",
  CREATE_SUB_CATEGORY_SUCCESS: "CREATE_SUB_CATEGORY_SUCCESS",
  CREATE_SUB_CATEGORY_FAILURE: "CREATE_SUB_CATEGORY_FAILURE",
  DELETE_SUB_CATEGORY_REQUEST: "DELETE_SUB_CATEGORY_REQUEST",
  DELETE_SUB_CATEGORY_SUCCESS: "DELETE_SUB_CATEGORY_SUCCESS",
  DELETE_SUB_CATEGORY_FAILURE: "DELETE_SUB_CATEGORY_FAILURE",
  RESTORE_SUB_CATEGORY_REQUEST: "RESTORE_SUB_CATEGORY_REQUEST",
  RESTORE_SUB_CATEGORY_SUCCESS: "RESTORE_SUB_CATEGORY_SUCCESS",
  RESTORE_SUB_CATEGORY_FAILURE: "RESTORE_SUB_CATEGORY_FAILURE",
  EDIT_SUB_CATEGORY_REQUEST: "EDIT_SUB_CATEGORY_REQUEST",
  EDIT_SUB_CATEGORY_SUCCESS: "EDIT_SUB_CATEGORY_SUCCESS",
  EDIT_SUB_CATEGORY_FAILURE: "EDIT_SUB_CATEGORY_FAILURE",
  SET_CURRENT_PAGE_SUB_CATEGORIES: "SET_CURRENT_PAGE_SUB_CATEGORIES",
  CLEAR_CREATE_SUB_CATEGORY_STATUS: "CLEAR_CREATE_SUB_CATEGORY_STATUS",

  GET_SUB_FACTORS_REQUEST: "GET_SUB_FACTORS_REQUEST",
  GET_SUB_FACTORS_SUCCESS: "GET_SUB_FACTORS_SUCCESS",
  GET_SUB_FACTORS_FAILURE: "GET_SUB_FACTORS_FAILURE",
  CREATE_SUB_FACTOR_REQUEST: "CREATE_SUB_FACTOR_REQUEST",
  CREATE_SUB_FACTOR_SUCCESS: "CREATE_SUB_FACTOR_SUCCESS",
  CREATE_SUB_FACTOR_FAILURE: "CREATE_SUB_FACTOR_FAILURE",
  DELETE_SUB_FACTOR_REQUEST: "DELETE_SUB_FACTOR_REQUEST",
  DELETE_SUB_FACTOR_SUCCESS: "DELETE_SUB_FACTOR_SUCCESS",
  DELETE_SUB_FACTOR_FAILURE: "DELETE_SUB_FACTOR_FAILURE",
  RESTORE_SUB_FACTOR_REQUEST: "RESTORE_SUB_FACTOR_REQUEST",
  RESTORE_SUB_FACTOR_SUCCESS: "RESTORE_SUB_FACTOR_SUCCESS",
  RESTORE_SUB_FACTOR_FAILURE: "RESTORE_SUB_FACTOR_FAILURE",
  EDIT_SUB_FACTOR_REQUEST: "EDIT_SUB_FACTOR_REQUEST",
  EDIT_SUB_FACTOR_SUCCESS: "EDIT_SUB_FACTOR_SUCCESS",
  EDIT_SUB_FACTOR_FAILURE: "EDIT_SUB_FACTOR_FAILURE",
  SINGLE_TEMPLATE_REQUEST: "SINGLE_TEMPLATE_REQUEST",
  SINGLE_TEMPLATE_SUCCESS: "SINGLE_TEMPLATE_SUCCESS",
  SINGLE_TEMPLATE_FAILURE: "SINGLE_TEMPLATE_FAILURE",
  SET_CURRENT_PAGE_SUB_FACTORS: "SET_CURRENT_PAGE_SUB_FACTORS",
  CLEAR_CREATE_SUB_FACTOR_STATUS: "CLEAR_CREATE_SUB_FACTOR_STATUS",
  RESET_CREATE_TEMPLATE: "RESET_CREATE_TEMPLATE",
  RESET_CURRENT_SUBCATEGORIES: "RESET_CURRENT_SUBCATEGORIES",
  SET_CREATE_TEMPLATE_NAME_AND_VEHICLE: "SET_CREATE_TEMPLATE_NAME_AND_VEHICLE",
  PUSH_STEPS_CURRENT_CATEGORY: "PUSH_STEPS_CURRENT_CATEGORY",
  PUSH_SUB_CURRENT_CATEGORY: "PUSH_SUB_CURRENT_CATEGORY",
  SET_ALL_SUBCATEGORIES_FINISHED: "SET_ALL_SUBCATEGORIES_FINISHED",
  RETRIEVE_CATEGORIES_EDIT: "RETRIEVE_CATEGORIES_EDIT",

  ...appointmentToolbar,
  ...paymentPackage,
  ...template,
}

export default types
