import React from "react"
import Switch from "react-switch"
import styled from "styled-components"
import theme from "../style/theme"

const SwitchContainer = styled.div`
    display: flex;
    flex-direction: ${props => (props.vertical ? "column" : "row")}
    align-items: center;
    justify-content: center;
`

const ScalableContainer = styled(SwitchContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: auto;
`

const SwitchLabel = styled.h4`
  display: flex;
  font-size: ${theme.font.md};
  font-weight: 400;
  margin-right: 15px;
  color: ${theme.colors.blackMain};
`

const MySwitch = ({ label, setActive, active, scalable = false }) => {
  let content = (
    <>
      <SwitchLabel>{label}</SwitchLabel>
      <Switch
        uncheckedIcon={null}
        checkedIcon={null}
        onChange={setActive}
        checked={active}
        onColor={theme.colors.greenMain}
        height={22}
        width={44}
      />
    </>
  )

  if (scalable) {
    return <ScalableContainer>{content}</ScalableContainer>
  }
  return <SwitchContainer>{content}</SwitchContainer>
}

export default MySwitch
