import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { SimpleButton } from "../../../../components/Buttons"
import {
  setEditModal,
  setDeleteModal,
  setRestoreModal,
  setActiveVehicleRequest,
  clearSetActiveVehicleStatus,
} from "../../../../store/actions"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash, faPenSquare } from "@fortawesome/free-solid-svg-icons"
import classes from "../../classes.module.css"
import { StyledAction } from "../../styledComponents"
import MySwitch from "../../../../components/MySwitch"

const Actions = ({
  setActiveStatus,
  vehicle,
  deleted,
  setEditModal,
  setDeleteModal,
  setRestoreModal,
  setActiveVehicleRequest,
  clearSetActiveVehicleStatus,
}) => {
  const [active, setActive] = useState(vehicle.is_active)
  const [loading, setLoading] = useState(false)

  const handleChangeActive = e => {
    setActiveVehicleRequest(vehicle.id)
    setLoading(true)
  }

  useEffect(() => {
    if (setActiveStatus === "success" && loading) {
      setActive(!active)
      setLoading(false)
      clearSetActiveVehicleStatus()
      vehicle.is_active = !active
    }
  }, [setActiveStatus])

  if (deleted.show) {
    return (
      <StyledAction>
        <SimpleButton
          title="Restore"
          onClick={() => setRestoreModal(true, vehicle.id)}
        />
      </StyledAction>
    )
  }

  return (
    <StyledAction>
      <MySwitch active={active} setActive={handleChangeActive} />
      <FontAwesomeIcon
        icon={faPenSquare}
        className={classes.edit}
        onClick={() => setEditModal(true, vehicle.id, vehicle)}
      />
      <FontAwesomeIcon
        icon={faTrash}
        className={classes.trash}
        onClick={() => setDeleteModal(true, vehicle.id)}
      />
    </StyledAction>
  )
}

const mapDispatch = {
  setEditModal,
  setDeleteModal,
  setRestoreModal,
  setActiveVehicleRequest,
  clearSetActiveVehicleStatus,
}

const mapState = state => ({
  deleted: state.deleted,
  setActiveStatus: state.statuses.setActiveVehicle,
})

export default connect(mapState, mapDispatch)(Actions)
