import React from "react"
import { StyledTableHeader, StyledLabel } from "../../styledComponents"

const labels = [
  "Name",
  "Email",
  "Contact Person",
  "Number Of Tecnhicians",
  "Actions",
]

const PendingTableHeader = () => {
  const getFlexScale = index => {
    return 1
  }
  return (
    <StyledTableHeader>
      {labels.map((e, index) => (
        <StyledLabel key={index} flex={getFlexScale(index)}>
          {e}
        </StyledLabel>
      ))}
    </StyledTableHeader>
  )
}

export default PendingTableHeader
