import { put, call, select } from "redux-saga/effects"

import * as actions from "../../actions"
import axios from "axios"

const getToken = state => state.auth.token

const apiCall = (id, token) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_PATH,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  let path
  path = `/dashboard/factors/${id}d`

  return instance
    .get(path)
    .then(response => response)
    .catch(error => error)
}

export default function* getOneFactorAsync(action) {
  const { id } = action.payload
  try {
    const token = yield select(getToken)

    const res = yield call(apiCall, id, token)

    console.log(" res = ", res)
    if (res.status === 200)
      yield put(
        actions.getOneFactorSuccess({ data: res.data.data[0], meta: null }),
      )
    else yield put(actions.getOneFactorFailure())
  } catch (err) {
    yield put(actions.getOneFactorFailure())
  }
}
