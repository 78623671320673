import VehicleTypes from "./Body"
import {
  setShowDeleted,
  setAddModal,
  getVehiclesRequest,
  setCurrentPageVehicles,
  setDeleteModal,
  setEditModal,
  setRestoreModal,
  editVehicleRequest,
  restoreVehicleRequest,
  deleteVehicleRequest,
  createVehicleRequest,
  clearCreateVehicleStatus,
} from "../../../store/actions"
import { connect } from "react-redux"

const mapState = state => ({
  deleted: state.deleted,
  modals: state.modals,
  getVehiclesStatus: state.statuses.getVehicles,
  vehicles: state.vehicles,
  currentPage: state.currentPages.vehicles,
  createRequestStatus: state.statuses.createVehicle,
  createRequestError: state.errors.createVehicle,
})

const mapDispatch = {
  setShowDeleted,
  setAddModal,
  getVehiclesRequest,
  setCurrentPageVehicles,
  setDeleteModal,
  setEditModal,
  setRestoreModal,
  editVehicleRequest,
  restoreVehicleRequest,
  deleteVehicleRequest,
  createVehicleRequest,
  clearCreateVehicleStatus,
}

export default connect(mapState, mapDispatch)(VehicleTypes)
