import Customers from "./Body"
import {
  setDeleteModal,
  setRestoreModal,
  setEditModal,
  setShowDeleted,
  setAddModal,
  getCustomersRequest,
  createCustomerRequest,
  deleteCustomerRequest,
  setCurrentPageCustomers,
  restoreCustomerRequest,
  editCustomerRequest,
  clearCreateCustomerStatus,
} from "../../../store/actions"
import { connect } from "react-redux"

const mapState = state => ({
  deleted: state.deleted,
  modals: state.modals,
  customers: state.customers,
  getCustomersStatus: state.statuses.getCustomers,
  createRequestStatus: state.statuses.createCustomer,
  createRequestError: state.errors.createCustomer,
  currentPage: state.currentPages.customers,
})

const mapDispatch = {
  setDeleteModal,
  setRestoreModal,
  setEditModal,
  setShowDeleted,
  setAddModal,
  getCustomersRequest,
  createCustomerRequest,
  deleteCustomerRequest,
  restoreCustomerRequest,
  editCustomerRequest,
  setCurrentPageCustomers,
  clearCreateCustomerStatus,
}

export default connect(mapState, mapDispatch)(Customers)
