import React from "react"
import Actions from "../Actions"
import { StyledTableItem, StyledField } from "../../styledComponents"

const Field = ({ isActive, id, field, index, type }) => {
  const fields = [
    { id: "id", width: "5%" },
    { id: "name", width: "14%" },
    { id: "phone", width: "14%" },
    { id: "email", width: "14%" },
    { id: "type", width: "14%" },
    { id: "company", width: "23%" },
    { id: "actions", width: "14%" },
  ]

  const fieldFind = fields.find(field => field.id === type)

  if (typeof fieldFind !== "undefined") {
    if (fieldFind.id !== "actions") {
      return (
        <StyledField style={{ width: fieldFind.width }}>{field}</StyledField>
      )
    } else {
      return (
        <StyledField style={{ width: fieldFind.width }}>
          <Actions id={id} isActive={isActive} />
        </StyledField>
      )
    }
  }

  return null
}

const TableItem = ({ technician }) => {
  return (
    <StyledTableItem>
      {Object.keys(technician).map((e, index) => (
        <Field
          id={technician.id}
          type={e}
          isActive={technician.is_active}
          field={technician[e]}
          index={index}
          key={index}
        />
      ))}
    </StyledTableItem>
  )
}

export default TableItem
