import styled from "styled-components"

export const StyledField = styled.div`
  cursor: pointer;
  display: flex;
  height: 30px !important;
  min-height: 30px !important;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: #666;
  font-size: 0.75rem;
  font-weight: 500;
  background: ${props => (props.type === "white" ? "white" : "#f3f3f3")};
  // border: 4px solid #f4f4f4;
  box-sizing: border-box;
  margin-top: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  &:hover {
    background: #f8f8f8;
  }
`

export const NoItemsMessage = styled.div`
  display: flex;
  height: 80%;
  min-height: 80%;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: #777;
  font-size: 1.3rem;
  font-weight: 600;
  box-sizing: border-box;
`
