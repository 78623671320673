import React from "react"
import { Provider } from "react-redux"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Login from "./screens/LoginScreen"
import Home from "./screens/Home"
import { FullPageSpinner } from "./components/MySpinner"
import store, { persistor } from "./store"
import { PersistGate } from "redux-persist/lib/integration/react"
import RequireAuth from "./helpers/RequireAuth"
// import 'antd/dist/antd.css';

import { transitions, positions, Provider as AlertProvider } from "react-alert"
// import AlertTemplate from 'react-alert-template-basic'
import AlertTemplate from "./components/MyAlert"

const alertOptions = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 100000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
}

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<FullPageSpinner />} persistor={persistor}>
        <AlertProvider template={AlertTemplate} {...alertOptions}>
          <div className="app">
            <Router>
              <Switch>
                <Route exact path="/login" component={Login} />
                <Route path="/" component={RequireAuth(Home)} />
                <Route
                  render={() => <div>Sorry, this page does not exist. </div>}
                />
              </Switch>
            </Router>
          </div>
        </AlertProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
