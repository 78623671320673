import React, { useState, useEffect } from "react"
import styled from "styled-components"

const ModelContainer = styled.div`
  //   width: 100%;
  width: 1000px;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin: 20px;
  display: flex;
  flex-direction: column;
`

const Body = styled.div`
  align-items: center;
  justify-content: center;
  margin: 20px;
  width: 80%;
  padding: 5px;
  background: whitesmoke;
  display: flex;
  flex-wrap: wrap;
`

const Message = styled.h2`
  font-size: 1rem;
  color: #555;
  font-weight: 400;
`

const Container = styled.div`
  width: 1000px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ErrorMessage = styled.div`
  font-size: 1.5rem;
  padding: 0 30px 0 30px;
  color: #555;
  font-weight: 600;
`

const StyledModelImage = styled.img`
  height: 150px;
  margin: 10px;
  cursor: pointer;
  border: ${props =>
    props.selected ? "solid yellow 2px" : "solid 2px transparent"};
`

const ModelImage = props => {
  return props.src && <StyledModelImage {...props} />
}

const types = {
  body: null,
  frame: null,
  interior: null,
  tires: null,
}

const ModelPicker = ({ vehicleType, selectedType, callback }) => {
  const [models, setModels] = useState(types)
  const [selected, setSelected] = useState(selectedType || null)

  console.log("selectedType = ", selectedType)
  const loadModels = vehicle => {
    if (!vehicleType) {
      console.log("Cannot find model!")
      return
    }
    Object.keys(types).forEach(type => {
      import(`./_models/${vehicle}/${type}.jpg`).then(image => {
        setModels(prev => ({ ...prev, [type]: image.default }))
      })
    })
  }

  useEffect(() => {
    loadModels(vehicleType)
  }, [])

  useEffect(() => {
    if (!selected || !models[selected]) return
    const modelObject = {
      picture: models[selected],
      type: selected,
      model_path: `${vehicleType}/${selected}`,
    }
    callback(modelObject)
  }, [selected])

  if (!vehicleType)
    return (
      <Container>
        <ErrorMessage>No Models Available for this Vehicle Type</ErrorMessage>
      </Container>
    )
  return (
    <ModelContainer>
      <Message>Selected Type: {selected || "None "}</Message>
      <Body>
        {Object.keys(models).map(e => (
          <ModelImage
            selected={selected === e}
            onClick={() => setSelected(e)}
            src={models[e]}
            alt=""
          />
        ))}
      </Body>
    </ModelContainer>
  )
}

export default ModelPicker
