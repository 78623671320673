import React from "react"
import moment from "moment"
import { Form, Input } from "antd"
import styled from "styled-components"
import { getInspectionPeriodString } from "../../../../../helpers/inspectionTimePeriod"

const formItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 20 },
}

const InspectionDetails = ({ appointment = {} }) => {
  if (!appointment) return null

  const {
    id,
    inspection_date_time,
    application_date,
    inspection_time_from,
    inspection_time_end,
  } = appointment

  const { id: technicianId, is_bodyshop, street } = appointment.technician || {}

  const { phone, first_name, last_name, email } =
    appointment.technician?.user || {}

  const { address } = appointment.location || {}

  return (
    <TechnicianInfoContainer>
      <Detail>
        <GroupHeader>Technician Details</GroupHeader>

        {technicianId && (
          <DetailItem>
            <DetailItemHeader>Technician ID</DetailItemHeader>
            <div>{technicianId}</div>
          </DetailItem>
        )}

        {first_name && last_name && (
          <DetailItem>
            <DetailItemHeader>Name</DetailItemHeader>
            <div>
              {first_name}
              {last_name}
            </div>
          </DetailItem>
        )}

        {phone && (
          <DetailItem>
            <DetailItemHeader>Phone</DetailItemHeader>
            <div>{phone}</div>
          </DetailItem>
        )}
        {email && (
          <DetailItem>
            <DetailItemHeader>Email</DetailItemHeader>
            <div>{email}</div>
          </DetailItem>
        )}

        <DetailItem>
          <DetailItemHeader>Type</DetailItemHeader>
          <div>{is_bodyshop ? "Bodyshop" : "Not Bodyshop"}</div>
        </DetailItem>

        {street && (
          <DetailItem>
            <DetailItemHeader>Address</DetailItemHeader>
            <div>{street}</div>
          </DetailItem>
        )}
      </Detail>

      <VerticalDivider />

      <Detail>
        <Form layout="horizontal">
          <GroupHeader>Inspection Detail</GroupHeader>
          {address && (
            <Form.Item label="Inspection Location" {...formItemLayout}>
              <Input disabled={true} value={address} />
            </Form.Item>
          )}

          {id && application_date && inspection_date_time && (
            <>
              <DetailItem>
                <DetailItemHeader>Appointment created at:</DetailItemHeader>
                <div>{moment(application_date).format("L LT")}</div>
              </DetailItem>
              <DetailItem>
                <DetailItemHeader>Inspection ordered to:</DetailItemHeader>
                <div>
                  {moment(inspection_date_time).format("L")}{" "}
                  {getInspectionPeriodString(
                    inspection_time_from,
                    inspection_time_end,
                  )}
                </div>
              </DetailItem>
            </>
          )}
        </Form>
      </Detail>

      <VerticalDivider />

      <Detail>
        <GroupHeader>Factors Checklist</GroupHeader>
      </Detail>
    </TechnicianInfoContainer>
  )
}

const TechnicianInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
`

const Detail = styled.div`
  width: 480px;
  @media (max-width: 1470px) {
    width: 335px;
  }
`

const DetailItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
`

const DetailItemHeader = styled.div`
  font-weight: bold;
`

const GroupHeader = styled.div`
  font-weight: bold;
  background-color: #f2f2f2;
  padding: 10px;
  margin-bottom: 10px;
`

const VerticalDivider = styled.div`
  width: 2px;
  background-color: #f2f2f2;
  margin-left: 7px;
  margin-right: 7px;
`

const AppointmentHistoryContainer = styled.div`
  width: 50%;
`

export default InspectionDetails
