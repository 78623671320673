import types from "../types"

const closed = {
  show: false,
  id: null,
  selected: {
    name: "",
  },
}

const initialState = {
  assign: closed,
  delete: closed,
  restore: closed,
  edit: closed,
  view: closed,
  showAdd: false,
}

const selectItemForModal = payload => {
  return {
    show: payload.show,
    id: payload.id,
    selected: payload.selected ? payload.selected : closed,
  }
}

export default function modals(state = initialState, action) {
  switch (action.type) {
    case types.SET_ADD_MODAL:
      return {
        ...state,
        showAdd: action.payload,
      }
    case types.SET_DELETE_MODAL:
      return {
        ...state,
        delete: selectItemForModal(action.payload),
      }
    case types.SET_EDIT_MODAL:
      return {
        ...state,
        edit: selectItemForModal(action.payload),
      }
    case types.SET_RESTORE_MODAL:
      return {
        ...state,
        restore: selectItemForModal(action.payload),
      }
    case types.SET_VIEW_MODAL:
      return {
        ...state,
        view: selectItemForModal(action.payload),
      }
    case types.SET_ASSIGN_MODAL:
      return {
        ...state,
        assign: selectItemForModal(action.payload),
      }
    default:
      return state
  }
}
