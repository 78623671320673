import { put, call, select } from "redux-saga/effects"
import * as actions from "../../actions"
import axios from "axios"

const getToken = state => state.auth.token
const getCurrentFactor = state => state.sub.selected.id

// "name":"Updated",
// "parent_id": 1,
// "panels_from": 1,
// "panels_to": 3,
// "score": 0.3

const apiCall = (pack, token) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_PATH,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const path = `/dashboard/subfactors`
  return instance
    .post(path, pack)
    .then(response => response)
    .catch(error => error.response)
}

const formatParams = (payload, parentId) => {
  // formatted in add modal
  return {
    ...payload,
    parent_id: parentId,
  }
}
export default function* createSubFactorAsync(action) {
  try {
    const parentId = yield select(getCurrentFactor)
    const token = yield select(getToken)
    const params = formatParams(action.payload, parentId)
    console.log("params = ", params)
    const res = yield call(apiCall, params, token)

    console.log(" res = ", res)
    if (res.status === 200 || res.status === 201) {
      yield put(actions.createSubFactorSuccess())
      yield put(actions.getSubFactorsRequest())
    } else yield put(actions.createSubFactorFailure(res.data))
  } catch (err) {
    yield put(actions.createSubFactorFailure(err))
  }
}
