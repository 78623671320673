import { put, call, select } from "redux-saga/effects"

import * as actions from "../../actions"
import axios from "axios"

const getToken = state => state.auth.token
const getCurrentCategory = state => state.sub.selected.id

const apiCall = (
  { search, page, sort, soft_deleted, category, paginate },
  token,
) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_PATH,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  let path = `/dashboard/categories/subcategories?category=${category}&paginate=${paginate}&page=${page}&sort=${sort}&soft_deleted=${soft_deleted}`
  if (search) path += `&search=${search}`
  return instance
    .get(path)
    .then(response => response)
    .catch(error => error)
}

export default function* getSubCategoriesAsync(action) {
  const { search, page, sort, soft_deleted, paginate } = action.payload
  const category = action.payload.category
    ? action.payload.category
    : yield select(getCurrentCategory)
  try {
    const token = yield select(getToken)
    const params = {
      search,
      page,
      sort,
      category,
      paginate,
      soft_deleted,
    }
    const res = yield call(apiCall, params, token)

    console.log(" res = ", res)
    if (res.status === 200)
      yield put(
        actions.getSubCategoriesSuccess({
          data: res.data.data,
          meta: res.data.meta,
        }),
      )
    else yield put(actions.getSubCategoriesFailure())
  } catch (err) {
    yield put(actions.getSubCategoriesFailure())
  }
}
