import React, { useState, useEffect } from "react"
import { AddModalContainer, Row } from "./styled"
import RenderModal from "../../../../components/RenderModal"
import { SimpleInput } from "../../../../components/Inputs"
import { SimpleButton } from "../../../../components/Buttons"
import MySelect from "../../../../components/MySelect"
import AssignTemplates from "./AssignTemplates"
import { makeOption } from "../../../../helpers"
import states from "../../../../helpers/states"
import { connect } from "react-redux"
import { getPendingRequest } from "../../../../store/actions"
import InputMask from "react-input-mask"

const getName = company => (company ? company.name : "")
const getEmail = company => (company ? company.email : "")
const getPhone = company => (company ? company.phone : "")
const getContactPerson = company => (company ? company.contactPerson : "")
const getStateId = company => (company ? company.stateId : null)

const AddModal = ({
  callback,
  company,
  getPendingRequest,
  getPendingStatus,
  getVehicles,
  storedVehicles,
  pending,
}) => {
  console.log("company ", company)
  const [openAssignModal, setOpenAssignModal] = useState(false)
  const [companyName, setCompanyName] = useState(getName(company))
  const [contactPerson, setContactPerson] = useState(getContactPerson(company))
  const [email, setEmail] = useState(getEmail(company))
  const [phone, setPhone] = useState(getPhone(company))
  const [technicianAssigned, setTechnicianAssigned] = useState("")
  const [stateId, setStateId] = useState(getStateId(company))
  const [selectedTemplates, setSelectedTemplates] = useState([])

  useEffect(() => {
    const newCompany = {
      name: companyName,
      contact_person: contactPerson,
      email,
      technicians_assigned: technicianAssigned,
      phone,
      templates: selectedTemplates,
      state_id: stateId + 1,
    }
    callback(newCompany)
  }, [
    companyName,
    contactPerson,
    email,
    phone,
    stateId,
    callback,
    technicianAssigned,
    selectedTemplates,
  ])

  const handleChangePhone = e => {
    const reg = /[0-9]/
    const char = e.charAt(e.length - 1)
    if (reg.test(char) || char === "") {
      setPhone(e)
    }
  }

  const getStateDefault = () => {
    console.log("states = ", states[stateId])
    if (stateId !== null) {
      return makeOption(states[stateId], stateId)
    }
    return null
  }

  const mapStates = arr => {
    return arr.map((e, index) => makeOption(e, index))
  }

  const handleChangeState = e => {
    if (!e) return
    setStateId(e.id)
  }

  const getTechniciansRawData = () => {
    const params = {
      page: 0,
      soft_deleted: 0,
      search: null,
      sort: "id",
      paginate: 10000,
    }
    getPendingRequest(params)
  }

  const getTechniciansOptions = () => {
    return pending.map(e => makeOption(`${e.id} - ${e.name}`, e.id))
  }

  const handleChangeTechnicians = option => {
    if (!option) return
    setTechnicianAssigned(option.map(e => e.id))
  }
  const phoneInputStyles = {
    width: "180px",
    marginRight: "5%",
    marginTop: "22px",
    height: "30px",
    borderRadius: "2px",
    padding: "10px",
    boxSizing: "borderBox",
    border: "0.5px solid #dde",
    position: "relative",
  }
  const PhoneTitle = {
    position: "absolute",
    left: "50%",
    color: "#565656",
    fontSize: "0.8rem",
  }

  return (
    <AddModalContainer>
      <Row>
        <SimpleInput
          title="Company Name*"
          value={companyName}
          name="companyName"
          onChange={setCompanyName}
        />
        <SimpleInput
          title="Email*"
          value={email}
          name="email"
          onChange={setEmail}
        />
      </Row>
      <Row>
        <SimpleInput
          title="Contact Person*"
          value={contactPerson}
          name="contactPerson"
          onChange={setContactPerson}
        />
      </Row>
      <Row>
        <MySelect
          title="Technicians Assigned"
          options={getTechniciansOptions()}
          onMenuOpen={
            getPendingStatus === "loading" ? [] : getTechniciansRawData
          }
          isLoading={getPendingStatus === "loading"}
          isMulti
          flexibleHeight
          name="technicianAssigned"
          placeholder={"Select"}
          onChange={handleChangeTechnicians}
        ></MySelect>
      </Row>
      <Row>
        <MySelect
          title="State*"
          defaultValue={getStateDefault()}
          options={mapStates(states)}
          name="state"
          placeholder={"Select"}
          onChange={handleChangeState}
        ></MySelect>

        {/* <SimpleInput
          title="Phone*"
          value={phone}
          name="phone"
          onChange={handleChangePhone}
        /> */}
        <InputMask
          mask="999-999-9999"
          placeholder="999-999-9999"
          style={phoneInputStyles}
          value={phone}
          name="phone"
          onChange={event => {
            setPhone(event.target.value)
          }}
        />
        <div style={PhoneTitle}>Phone*</div>
      </Row>

      {/* <BlockTitle> Assign Templates </BlockTitle> */}
      <Row>
        <SimpleButton
          color="#3B86FF"
          scalable
          title="Assign Templates"
          onClick={() => setOpenAssignModal(true)}
        />
        <RenderModal
          label={`Assign Templates`}
          render={() => (
            <AssignTemplates
              selectedTemplates={selectedTemplates}
              setSelectedTemplates={setSelectedTemplates}
            />
          )}
          active={openAssignModal}
          setActive={setOpenAssignModal}
        />
      </Row>
    </AddModalContainer>
  )
}

const connectState = state => ({
  getPendingStatus: state.statuses.getPending,
  pending: state.pending.array,
})

const connectActions = {
  getPendingRequest,
}

export default connect(connectState, connectActions)(AddModal)
