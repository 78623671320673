import React, { useState, useEffect } from "react"

import { useParams, useHistory } from "react-router-dom"
import {
  PageContainer,
  StyledToolBar,
  StyledTableBody,
} from "../../styledComponents"

import PageTitle from "../../../../components/PageTitle"
import {
  getOneFactorRequest,
  editFactorRequest,
  createFactorRequest,
} from "../../../../store/actions"

import { useDispatch, useSelector } from "react-redux"
import { Button, Input, Select, Table, Popconfirm, Skeleton } from "antd"
import { factorTypes } from "../../../../constants/index"
import Add from "./AddOption"

const { Column } = Table

const PrimaryType = ({
  setModal,
  options,
  handleDelete,
  handleEdit,
  onAdd,
}) => {
  const formatOptions = arr => {
    if (!Array.isArray(arr)) {
      return []
    }
    return arr.map((e, index) => ({
      key: e.index,
      name: e.name,
      panels: {
        from: e.panelsFrom,
        to: e.panelsTo,
      },
      score: e.score,
    }))
  }

  return (
    <React.Fragment>
      <div style={{ marginBottom: 16, marginTop: 30 }}>
        <Button onClick={onAdd}>Add Option</Button>
      </div>
      <Table dataSource={formatOptions(options)}>
        <Column title="Name" key="name" dataIndex="name" />
        <Column title="Score" key="score" dataIndex="score" />
        <Column
          title={"Panels"}
          dataIndex={"panels"}
          key={"panels"}
          render={panels => `${panels.from} - ${panels.to}`}
        />
        <Column
          title=""
          key="action"
          dataIndex="name"
          render={name => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Popconfirm
                placement="top"
                title={"Are you sure?"}
                onConfirm={() => handleDelete(name)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  // onClick={() => handleDelete(name)}
                  style={{ marginRight: 10 }}
                >
                  Delete
                </Button>
              </Popconfirm>

              <Button onClick={() => handleEdit(name)}>Edit</Button>
            </div>
          )}
        />
      </Table>
    </React.Fragment>
  )
}

const Types = ({ selected, onChange }) => {
  return (
    <Select
      value={selected}
      style={{ flex: 1, marginLeft: 20 }}
      onChange={onChange}
    >
      {factorTypes.map((e, index) => (
        <Select.Option key={index} value={e}>
          {e}
        </Select.Option>
      ))}
    </Select>
  )
}

const ViewOptions = () => {
  let { factorID } = useParams()
  const dispatch = useDispatch()
  const [modal, setModal] = useState(false)
  const [currentOptionName, setCurrentOptionName] = useState(null)
  const [name, setName] = useState("")

  const getStatus = useSelector(state => state.statuses.getOneFactor)
  const createStatus = useSelector(state => state.statuses.createFactor)
  const [options, setOptions] = useState([])
  const [type, setType] = useState(factorTypes[0])
  const isEdit = factorID && factorID !== "new"
  const history = useHistory()

  let currentFactor = useSelector(state => state.factors.currentFactor)

  useEffect(() => {
    if (isEdit && currentFactor) {
      setName(currentFactor.name)
      setType(currentFactor.type)
      setOptions(currentFactor.options ? currentFactor.options : [])
    }
    console.log("currentFactor =", currentFactor)
  }, [currentFactor, isEdit])

  useEffect(() => {
    if (isEdit) {
      dispatch(getOneFactorRequest(factorID))
    }
  }, [isEdit])

  useEffect(() => {
    if (createStatus === "success") {
      history.push("/factors")
    }
  }, [createStatus])

  const handleConfirmAddEdit = n => {
    if (currentOptionName) {
      setOptions(p => p.map(e => (currentOptionName === e.name ? n : e)))
    } else {
      setOptions(p => [...p, n])
    }
  }
  const handleDelete = name => {
    setOptions(p => p.filter(e => e.name !== name))
  }
  const handleEdit = name => {
    setCurrentOptionName(name)
    setModal(true)
  }
  const handleAdd = name => {
    setCurrentOptionName(null)
    setModal(true)
  }

  const handleSubmit = () => {
    if (name === "") {
      return
    }
    if (isEdit) {
      dispatch(editFactorRequest({ name, type, options }, factorID))
    } else {
      dispatch(createFactorRequest({ name, type, options }))
    }
  }
  let content

  switch (type) {
    case "damage":
    case "primary":
      content = (
        <PrimaryType
          options={options}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          onAdd={handleAdd}
        />
      )
      break
    default:
      content = (
        <h2 style={{ marginTop: 20, marginLeft: 10 }}>
          Options are assigned automatically
        </h2>
      )
  }

  return (
    <PageContainer>
      {isEdit && getStatus !== "success" ? (
        <Skeleton active />
      ) : (
        <React.Fragment>
          <Add
            modal={modal}
            setModal={setModal}
            onConfirm={handleConfirmAddEdit}
            editTarget={
              currentOptionName &&
              options.find(o => o.name === currentOptionName)
            }
          />
          <StyledToolBar>
            <PageTitle
              title={isEdit ? `EDIT FACTOR / ${factorID}` : "NEW FACTOR"}
              showBack={true}
              onClickBack={() => history.push("/factors")}
            />
            <Button
              type="primary"
              onClick={handleSubmit}
              style={{
                background: "#559966",
                borderRadius: 20,
                height: 40,
                width: 120,
                fontSize: "0.8rem",
                margin: "0 6px",
                color: "#fff",
                border: "none",
              }}
            >
              Save Changes
            </Button>
          </StyledToolBar>
          <div style={{ display: "flex", alignItems: "center", marginTop: 30 }}>
            <Input
              placeholder="Name"
              style={{ flex: 1 }}
              onChange={e => setName(e.target.value)}
              value={name}
            />
            <Types selected={type} onChange={setType} />
          </div>

          {content}
        </React.Fragment>
      )}
    </PageContainer>
  )
}

export default ViewOptions
