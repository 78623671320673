import axios from "axios"

/**
 * Get summary statistic.
 * @param token
 * @param params
 * @returns {PromiseLike<*> | Promise<*>}
 */
export const getSummaryStatistic = (token, params) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/statistic/summary`, params, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(response => {
      return response.data
    })
}
