import { put, call, select } from "redux-saga/effects"

import * as actions from "../../actions"
import axios from "axios"

const getToken = state => state.auth.token

const apiCall = ({ search, page, sort, soft_deleted, paginate }, token) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_PATH,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  let path = `/dashboard/vehicle-types?paginate=${paginate}&page=${page}&sort=${sort}&soft_deleted=${soft_deleted}`
  if (search) path += `&search=${search}`
  return instance
    .get(path)
    .then(response => response)
    .catch(error => error)
}

export default function* getVehiclesAsync(action) {
  const { search, page, sort, soft_deleted, paginate } = action.payload
  try {
    const token = yield select(getToken)
    const params = {
      search,
      page,
      sort,
      soft_deleted,
      paginate,
    }
    const res = yield call(apiCall, params, token)

    console.log(" res = ", res)
    if (res.status === 200)
      yield put(
        actions.getVehiclesSuccess({
          data: res.data.data,
          meta: res.data.meta,
        }),
      )
    else yield put(actions.getVehiclesFailure())
  } catch (err) {
    yield put(actions.getVehiclesFailure())
  }
}
