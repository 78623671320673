import { put, call, select } from "redux-saga/effects"

import * as actions from "../../actions"
import axios from "axios"

const getToken = state => state.auth.token
const getPage = state => state.currentPages.vehicles
const getShowDeleted = state => state.deleted.show

const apiCall = (id, token) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_PATH,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const path = `dashboard/vehicle-types/set-active/${id}`
  return instance
    .patch(path)
    .then(response => response)
    .catch(error => error.response)
}

export default function* setActiveVehicleAsync(action) {
  try {
    const token = yield select(getToken)
    const { id } = action.payload

    const res = yield call(apiCall, id, token)

    console.log(" res = ", res)
    if (res.status === 200) {
      yield put(actions.setActiveVehicleSuccess())
      yield put(actions.updateVehicleTypeStatus(id))
    } else yield put(actions.setActiveVehicleFailure(res.data))
  } catch (err) {
    yield put(actions.setActiveVehicleFailure(err))
  }
}
