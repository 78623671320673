import React, { useState, useEffect } from "react"
import styled from "styled-components"
import theme from "../../../../style/theme"
import { SimpleInput } from "../../../../components/Inputs"
import MySwitch from "../../../../components/MySwitch"

import { makeOption } from "../../../../helpers"
import states from "../../../../helpers/states"
import MySelect from "../../../../components/MySelect"
import InputMask from "react-input-mask"

const AddModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  padding: 30px 0 30px 0;
  align-items: center;
`

const Title = styled.h3`
	margin-bottom: 2%;
	font-size: ${theme.font.sm};
	font-weight: ${theme.font.regular}
	color: ${theme.colors.blackMain}
`

const Row = styled.div`
  display: flex;
  flex: ${props => (props.flex ? props.flex : 1)};
  flex-direction: row;
  width: 100%;
`

const SwitchRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 50%;
  height: 45px;
  justify-content: flex-start;
`

const phoneInputStyles = {
  width: "180px",
  marginRight: "5%",
  marginTop: "22px",
  height: "30px",
  borderRadius: "2px",
  padding: "10px",
  boxSizing: "borderBox",
  border: "0.5px solid #dde",
  position: "relative",
}
const PhoneTitle = {
  position: "absolute",
  left: "50%",
  color: "#565656",
  fontSize: "0.8rem",
}

const getFirstName = customer => (customer ? customer.first_name : "John")
const getLastName = customer => (customer ? customer.last_name : "Smith")
const getEmail = customer => (customer ? customer.email : "jsmith@gmail.com")
const getPlateNumber = customer => (customer ? customer.plate : "12222")
const getVin = customer => (customer ? customer.vin : "99888888888wwwww8")
const getPhone = customer => (customer ? customer.phone : "")
const getStateId = customer => (customer ? customer.state_id : null)

const AddModal = ({ customer, callback }) => {
  const [firstName, setFirstName] = useState(getFirstName(customer))
  const [lastName, setLastName] = useState(getLastName(customer))
  const [email, setEmail] = useState(getEmail(customer))
  const [phone, setPhone] = useState(getPhone(customer))
  const [plateNumber, setPlateNumber] = useState(getPlateNumber(customer))
  const [vin, setVin] = useState(getVin(customer))
  const [stateId, setStateId] = useState(getStateId(customer))
  const [notification, setNotification] = useState(false)

  console.log("customer", customer)
  useEffect(() => {
    const newCustomer = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone,
      plate: plateNumber,
      vin,
      state_id: stateId + 1, // all state id's must be 1-indexed for back end
      send_mail: notification,
    }
    callback(newCustomer)
  }, [
    firstName,
    lastName,
    email,
    phone,
    plateNumber,
    vin,
    stateId,
    notification,
    callback,
  ])

  const handleChangeState = e => {
    setStateId(e.id)
  }

  const getStateDefault = () => {
    if (stateId !== null) {
      return makeOption(states[stateId], stateId)
    }
    return null
  }

  const mapStates = arr => {
    return arr.map((e, index) => makeOption(e, index))
  }

  //  const inputMaskStyle = {
  //     style={containerStyle}
  //  }

  return (
    <AddModalContainer>
      <Row>
        <SimpleInput
          title="First Name*"
          value={firstName}
          name="firstName"
          onChange={setFirstName}
        />
        <SimpleInput
          title="Last Name*"
          value={lastName}
          name="lastName"
          onChange={setLastName}
        />
      </Row>
      <Row>
        <SimpleInput
          title="Email*"
          value={email}
          name="email"
          onChange={setEmail}
        />
        {/* <SimpleInput
                    title="Phone*"
                    value={phone}
                    name="phone"
                    onChange={setPhone}
                /> */}

        <InputMask
          mask="999-999-9999"
          placeholder="999-999-9999"
          style={phoneInputStyles}
          value={phone}
          name="phone"
          onChange={event => {
            setPhone(event.target.value)
          }}
        />
        <div style={PhoneTitle}>Phone*</div>
      </Row>
      {/*<Row>*/}
      {/*    <SimpleInput*/}
      {/*        title="Plate Number*"*/}
      {/*        value={plateNumber}*/}
      {/*        name="plateNumber"*/}
      {/*        onChange={setPlateNumber}*/}
      {/*    />*/}
      {/*    <SimpleInput*/}
      {/*        title="VIN*"*/}
      {/*        value={vin}*/}
      {/*        name="VIN"*/}
      {/*        onChange={setVin}*/}
      {/*    />*/}
      {/*</Row>*/}
      <Row>
        <MySelect
          title="State*"
          defaultValue={getStateDefault()}
          options={mapStates(states)}
          name="state"
          placeholder={"Select"}
          onChange={handleChangeState}
        ></MySelect>

        <SwitchRow>
          <Title>Send Notification</Title>
          <MySwitch active={notification} setActive={setNotification} />
        </SwitchRow>
      </Row>
    </AddModalContainer>
  )
}

export default AddModal
