import styled from "styled-components"
import theme from "../../../../../style/theme"

export const StyledBlock = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 5%;

  align-items: center;
  justify-content: center;
`
export const AddModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  // height: 100%;
  height: 640px;
  // overflow-y: scroll;
  align-items: center;
  // justify-content: center;
  // height: 12vh;
`

export const PhotoInputBlock = styled(StyledBlock)`
  height: 160px;
`

export const SecondInputBlock = styled(StyledBlock)`
  display: flex;
  align-items: space-around;
  justify-content: space-around;
  flex-direction: column;
  margin-bottom: 1%;
  width: 100%;
`

export const CompanyInputBlock = styled(StyledBlock)``

export const LastInputBlock = styled(StyledBlock)``

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${props => (props.flex ? props.flex : 1)};
  // margin: 10px;
`

export const RadioForm = styled.form`
  margin-top: 10px;
`

export const Title = styled.h3`
	margin-bottom: 2%;
	font-size: ${theme.font.sm};
	font-weight: ${theme.font.regular}
	color: ${theme.colors.blackMain}
`

export const RadioLabel = styled(Title)`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: flex-start;
`

export const RadioInput = styled.input`
  background: white;
  color: white;
  width: 15px;
  height: 15px;
  margin-right: 5px;
`

export const Row = styled.div`
  display: flex;
  flex: ${props => (props.flex ? props.flex : 1)};
  flex-direction: row;
  // margin: 10px;
  width: 100%;
`

export const SelectRow = styled(Row)`
  margin-bottom: 4px;
  width: 95%;
`
export const SwitchRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 45px;
  justify-content: flex-start;
`
