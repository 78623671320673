import React from "react"
import moment from "moment"
import { Form, Input } from "antd"
import styled from "styled-components"
import AppointmentsHistory from "./AppointmentsHistory"

const ClientInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
`

const Detail = styled.div`
  width: 300px;
`

const DetailItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
`

const DetailItemHeader = styled.div`
  font-weight: bold;
`

const CarInfo = styled.div`
  width: 350px;
`

const GroupHeader = styled.div`
  font-weight: bold;
  background-color: #f2f2f2;
  padding: 10px;
`

const VerticalDivider = styled.div`
  width: 2px;
  background-color: #f2f2f2;
  margin-left: 7px;
  margin-right: 7px;
`

const AppointmentHistoryContainer = styled.div`
  width: 50%;
`

const ClientInfo = props => {
  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 17 },
  }

  const appointmentType = props?.appointment?.technician
    ? "customer"
    : "technician"
  const appointmentReportUrl = `${process.env.REACT_APP_REPORT_URL}/${props?.appointment?.id}?type=${appointmentType}`

  return (
    <ClientInfoContainer>
      <Detail>
        <GroupHeader>Client Information</GroupHeader>
        <DetailItem>
          <DetailItemHeader>Customer ID</DetailItemHeader>
          <div>{props.appointment && props.appointment.customer.id}</div>
        </DetailItem>
        <DetailItem>
          <DetailItemHeader>Date Of Registration</DetailItemHeader>
          <div>
            {props.appointment &&
              moment(props.appointment.customer.created_at).format("L")}
          </div>
        </DetailItem>
        <DetailItem>
          <DetailItemHeader>Customer</DetailItemHeader>
          <div>
            {props.appointment &&
              `${props.appointment.customer.user.first_name} ${props.appointment.customer.user.last_name}`}
          </div>
        </DetailItem>
        <DetailItem>
          <DetailItemHeader>Email</DetailItemHeader>
          <div>
            {props.appointment && props.appointment.customer.user.email}
          </div>
        </DetailItem>
        <DetailItem>
          <DetailItemHeader>Phone</DetailItemHeader>
          <div>
            {props.appointment && props.appointment.customer.user.phone}
          </div>
        </DetailItem>
      </Detail>
      <VerticalDivider />
      <CarInfo>
        <Form layout="horizontal">
          <GroupHeader style={{ marginBottom: 6 }}>Car Information</GroupHeader>
          <Form.Item label="Model" {...formItemLayout}>
            <Input
              disabled={true}
              value={props.appointment && props.appointment.car_info.model}
            />
          </Form.Item>
          <Form.Item label="Vehicle type" {...formItemLayout}>
            <Input
              placeholder="Vehicle type"
              value={
                props.appointment && props.appointment.car_info.vehicle_type
              }
            />
          </Form.Item>
          <Form.Item label="Plate Number" {...formItemLayout}>
            <Input
              placeholder="Plate Number"
              value={
                props.appointment && props.appointment.car_info.plate_number
              }
            />
          </Form.Item>
          <Form.Item label="VIN" {...formItemLayout}>
            <Input
              placeholder="Vin"
              value={props.appointment && props.appointment.car_info.vin}
            />
          </Form.Item>
          {props.appointment?.status === 2 && (
            <Form.Item label="Report" {...formItemLayout}>
              <a href={appointmentReportUrl}>{appointmentReportUrl}</a>
            </Form.Item>
          )}
        </Form>
      </CarInfo>
      <VerticalDivider />
      <AppointmentHistoryContainer>
        <GroupHeader>Appointment History</GroupHeader>
        <AppointmentsHistory appointment={props.appointment} />
      </AppointmentHistoryContainer>
    </ClientInfoContainer>
  )
}

export default ClientInfo
