import React, { Suspense, useEffect } from "react"
import { useRouteMatch, Switch, Route } from "react-router-dom"
import {
  setDeleteModal,
  setRestoreModal,
  setEditModal,
  setShowDeleted,
  setAddModal,
  getFactorsRequest,
  createFactorRequest,
  deleteFactorRequest,
  setCurrentPageFactors,
  restoreFactorRequest,
  editFactorRequest,
  clearCreateFactorStatus,
  setShowSub,
} from "../../../store/actions"
import { connect } from "react-redux"
import ViewFactors from "./ViewFactors"

const Body = React.lazy(() => import("./Body"))

const mapState = state => ({
  deleted: state.deleted,
  modals: state.modals,
  factors: state.factors,
  getFactorsStatus: state.statuses.getFactors,
  createRequestStatus: state.statuses.createFactor,
  createRequestError: state.errors.createFactor,
  currentPage: state.currentPages.factors,
})

const mapDispatch = {
  setDeleteModal,
  setRestoreModal,
  setEditModal,
  setShowDeleted,
  setAddModal,
  getFactorsRequest,
  createFactorRequest,
  deleteFactorRequest,
  restoreFactorRequest,
  editFactorRequest,
  setCurrentPageFactors,
  clearCreateFactorStatus,
  setShowSub,
}

const Factors = props => {
  let { path } = useRouteMatch()

  useEffect(() => {
    // cleanUp of the reducer after categories
    props.setShowDeleted(false)
  }, [])

  return (
    <Suspense fallback={null}>
      <Switch>
        <Route exact path={path}>
          <Body {...props} />
        </Route>
        <Route path={`${path}/:factorID`}>
          <ViewFactors />
        </Route>
      </Switch>
    </Suspense>
  )
}

export default connect(mapState, mapDispatch)(Factors)
