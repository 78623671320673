import types from "./types"

export const postLoginRequest = (email, pass) => {
  return {
    type: types.LOGIN_REQUEST,
    payload: { email, pass },
  }
}

export const checkAuth = () => {
  return {
    type: types.CHECK_AUTH,
  }
}

export const setAllSubcategoriesFinished = bool => {
  return {
    type: types.SET_ALL_SUBCATEGORIES_FINISHED,
    payload: { bool },
  }
}

export const signOut = () => {
  return {
    type: types.SIGN_OUT,
  }
}

export const loginSuceess = payload => {
  return {
    type: types.LOGIN_SUCCESS,
    payload,
  }
}

export const loginFailure = payload => {
  return {
    type: types.LOGIN_FAILURE,
    payload,
  }
}

export const clearLoginStatus = () => {
  return {
    type: types.CLEAR_LOGIN_STATUS,
  }
}

export const getTestRequest = () => {
  return {
    type: types.GET_TEST_REQUEST,
  }
}

export const getTestSuccess = payload => {
  return {
    type: types.GET_TEST_SUCCESS,
    payload,
  }
}

export const getTestFailure = payload => {
  return {
    type: types.GET_TEST_FAILURE,
    payload,
  }
}

export const setShowDeleted = bool => {
  return {
    type: types.SET_SHOW_DELETED,
    payload: bool,
  }
}
export const setShowSub = (bool, obj) => {
  return {
    type: types.SET_SHOW_SUB,
    payload: { bool, obj },
  }
}

export const setAssignModal = (bool, id, selected) => {
  return {
    type: types.SET_ASSIGN_MODAL,
    payload: { show: bool, id, selected },
  }
}
export const setDeleteModal = (bool, id = null, selected = null) => {
  return {
    type: types.SET_DELETE_MODAL,
    payload: { show: bool, id, selected },
  }
}
export const setRestoreModal = (bool, id = null, selected = null) => {
  return {
    type: types.SET_RESTORE_MODAL,
    payload: { show: bool, id, selected },
  }
}
export const setEditModal = (bool, id = null, selected = null) => {
  return {
    type: types.SET_EDIT_MODAL,
    payload: { show: bool, id, selected },
  }
}

export const setViewModal = (bool, id = null, selected = null) => {
  return {
    type: types.SET_VIEW_MODAL,
    payload: { show: bool, id, selected },
  }
}

export const setAddModal = bool => {
  return {
    type: types.SET_ADD_MODAL,
    payload: bool,
  }
}

export const setAddCompanyModal = bool => {
  return {
    type: types.SET_ADD_COMPANY_MODAL,
    payload: bool,
  }
}

export const setTemplateCurrentCategory = item => {
  return {
    type: types.SET_TEMPLATE_CURRENT_CATEGORY,
    payload: item,
  }
}

export const pushTemplateCategories = item => {
  return {
    type: types.PUSH_TEMPLATE_CATEGORY,
    payload: item, // null for empty category
  }
}

export const pushSubsCurrentCategory = subcategories => {
  return {
    type: types.PUSH_SUB_CURRENT_CATEGORY,
    payload: { subcategories },
  }
}

export const updateTemplateCategories = (item, store_id) => {
  return {
    type: types.UPDATE_TEMPLATE_CATEGORIES,
    payload: { item, store_id },
  }
}
export const removeTemplateCategories = store_id => {
  return {
    type: types.REMOVE_TEMPLATE_CATEGORIES,
    payload: { store_id },
  }
}

export const getPendingSuccess = ({ data, meta }) => {
  return {
    type: types.GET_PENDING_SUCCESS,
    payload: { data, meta },
  }
}
export const getPendingRequest = ({ page, soft_deleted, search, sort }) => {
  return {
    type: types.GET_PENDING_REQUEST,
    payload: {
      search,
      page: page + 1,
      sort,
      soft_deleted: soft_deleted ? 1 : 0,
    },
  }
}
export const getPendingFailure = err => {
  return {
    type: types.GET_PENDING_FAILURE,
    payload: err,
  }
}

export const getTechniciansSuccess = ({ data, meta }) => {
  return {
    type: types.GET_TECHNICIANS_SUCCESS,
    payload: { data, meta },
  }
}
export const getTechniciansRequest = ({ page, soft_deleted, search, sort }) => {
  return {
    type: types.GET_TECHNICIANS_REQUEST,
    payload: {
      search,
      page: page + 1,
      sort,
      soft_deleted: soft_deleted ? 1 : 0,
    },
  }
}
export const getTechniciansFailure = err => {
  return {
    type: types.GET_TECHNICIANS_FAILURE,
    payload: err,
  }
}

export const setCurrentPagePending = int => {
  return {
    type: types.SET_CURRENT_PAGE_PENDING,
    payload: int,
  }
}
export const setCurrentPageTechnicians = int => {
  return {
    type: types.SET_CURRENT_PAGE_TECHNICIANS,
    payload: int,
  }
}

export const getAllCompaniesSuccess = data => {
  return {
    type: types.GET_ALL_COMPANIES_SUCCESS,
    payload: data,
  }
}

export const getAllCompaniesRequest = () => {
  return {
    type: types.GET_ALL_COMPANIES_REQUEST,
  }
}

export const getAllCompaniesFailure = err => {
  return {
    type: types.GET_ALL_COMPANIES_FAILURE,
    payload: err,
  }
}

export const getCompaniesSuccess = data => {
  return {
    type: types.GET_COMPANIES_SUCCESS,
    payload: data,
  }
}

export const getCompaniesRequest = ({ search, page, sort, soft_deleted }) => {
  return {
    type: types.GET_COMPANIES_REQUEST,
    payload: {
      search,
      page: page + 1,
      sort,
      soft_deleted: soft_deleted ? 1 : 0,
    },
  }
}

export const setCurrentPageCompanies = int => {
  return {
    type: types.SET_CURRENT_PAGE_COMPANIES,
    payload: int,
  }
}

export const getCompaniesFailure = err => {
  return {
    type: types.GET_COMPANIES_FAILURE,
    payload: err,
  }
}

export const createTechnicianRequest = technician => {
  return {
    type: types.CREATE_TECHNICIAN_REQUEST,
    payload: technician,
  }
}

export const createTechnicianSuccess = technician => {
  return {
    type: types.CREATE_TECHNICIAN_SUCCESS,
    payload: technician,
  }
}

export const createTechnicianFailure = err => {
  return {
    type: types.CREATE_TECHNICIAN_FAILURE,
    payload: err,
  }
}
export const editTechnicianRequest = (technician, id) => {
  return {
    type: types.EDIT_TECHNICIAN_REQUEST,
    payload: { formData: technician, id },
  }
}

export const editTechnicianSuccess = data => {
  return {
    type: types.EDIT_TECHNICIAN_SUCCESS,
    payload: data,
  }
}

export const editTechnicianFailure = err => {
  return {
    type: types.EDIT_TECHNICIAN_FAILURE,
    payload: err,
  }
}

export const clearCreateTechnicianStatus = () => {
  return {
    type: types.CLEAR_CREATE_TECHNICIAN_STATUS,
  }
}
export const clearCreateCompanyStatus = () => {
  return {
    type: types.CLEAR_CREATE_COMPANY_STATUS,
  }
}

export const assingPendingRequest = ({ id, company_id }) => {
  return {
    type: types.ASSIGN_PENDING_REQUEST,
    payload: { id, company_id },
  }
}

export const assignPendingSuccess = () => {
  return {
    type: types.ASSIGN_PENDING_SUCCESS,
  }
}

export const assignPendingFailure = payload => {
  return {
    type: types.ASSIGN_PENDING_FAILURE,
    payload,
  }
}
export const setActiveTechnicianRequest = id => {
  return {
    type: types.SET_ACTIVE_TECHNICIAN_REQUEST,
    payload: { id },
  }
}

export const setActiveTechnicianSuccess = () => {
  return {
    type: types.SET_ACTIVE_TECHNICIAN_SUCCESS,
  }
}

export const setActiveTechnicianFailure = payload => {
  return {
    type: types.SET_ACTIVE_TECHNICIAN_FAILURE,
    payload,
  }
}

export const clearSetActiveTechnicianStatus = () => {
  return {
    type: types.CLEAR_SET_ACTIVE_TECHNICIAN_STATUS,
  }
}

export const clearAssignPendingStatus = () => {
  return {
    type: types.CLEAR_ASSIGN_PENDING_STATUS,
  }
}

export const deleteTechnicianRequest = id => {
  return {
    type: types.DELETE_TECHNICIAN_REQUEST,
    payload: { id, type: "tecnician" },
  }
}

export const deleteTechnicianSuccess = () => {
  return {
    type: types.DELETE_TECHNICIAN_SUCCESS,
  }
}

export const deleteTechnicianFailure = payload => {
  return {
    type: types.DELETE_TECHNICIAN_FAILURE,
    payload,
  }
}

export const restoreTechnicianRequest = id => {
  return {
    type: types.RESTORE_TECHNICIAN_REQUEST,
    payload: { id, type: "pending" },
  }
}

export const restoreTechnicianSuccess = () => {
  return {
    type: types.RESTORE_TECHNICIAN_SUCCESS,
  }
}

export const restoreTechnicianFailure = payload => {
  return {
    type: types.RESTORE_TECHNICIAN_FAILURE,
    payload,
  }
}

export const deletePendingRequest = id => {
  return {
    type: types.DELETE_PENDING_REQUEST,
    payload: { id, type: "pending" },
  }
}

export const deletePendingSuccess = () => {
  return {
    type: types.DELETE_PENDING_SUCCESS,
  }
}

export const deletePendingFailure = payload => {
  return {
    type: types.DELETE_PENDING_FAILURE,
    payload,
  }
}

export const restorePendingRequest = id => {
  return {
    type: types.RESTORE_PENDING_REQUEST,
    payload: { id, type: "pending" },
  }
}

export const restorePendingSuccess = () => {
  return {
    type: types.RESTORE_PENDING_SUCCESS,
  }
}

export const restorePendingFailure = payload => {
  return {
    type: types.RESTORE_PENDING_FAILURE,
    payload,
  }
}

export const deleteCompanyRequest = id => {
  return {
    type: types.DELETE_COMPANY_REQUEST,
    payload: { id },
  }
}

export const deleteCompanySuccess = () => {
  return {
    type: types.DELETE_COMPANY_SUCCESS,
  }
}

export const deleteCompanyFailure = payload => {
  return {
    type: types.DELETE_COMPANY_FAILURE,
    payload,
  }
}

export const restoreCompanyRequest = id => {
  return {
    type: types.RESTORE_COMPANY_REQUEST,
    payload: { id },
  }
}

export const restoreCompanySuccess = () => {
  return {
    type: types.RESTORE_COMPANY_SUCCESS,
  }
}

export const restoreCompanyFailure = payload => {
  return {
    type: types.RESTORE_COMPANY_FAILURE,
    payload,
  }
}

export const editCompanyRequest = (company, id) => {
  return {
    type: types.EDIT_COMPANY_REQUEST,
    payload: { formData: company, id },
  }
}

export const editCompanySuccess = data => {
  return {
    type: types.EDIT_COMPANY_SUCCESS,
    payload: data,
  }
}

export const editCompanyFailure = err => {
  return {
    type: types.EDIT_COMPANY_FAILURE,
    payload: err,
  }
}

export const createCompanyRequest = company => {
  return {
    type: types.CREATE_COMPANY_REQUEST,
    payload: company,
  }
}

export const createCompanySuccess = company => {
  return {
    type: types.CREATE_COMPANY_SUCCESS,
    payload: company,
  }
}

export const createCompanyFailure = err => {
  return {
    type: types.CREATE_COMPANY_FAILURE,
    payload: err,
  }
}

export const getCompanyInfoFailure = err => {
  return {
    type: types.GET_COMPANY_INFO_FAILURE,
    payload: err,
  }
}

export const getCompanyInfoRequest = id => {
  return {
    type: types.GET_COMPANY_INFO_REQUEST,
    payload: { id },
  }
}

export const getCompanyInfoSuccess = data => {
  return {
    type: types.GET_COMPANY_INFO_SUCCESS,
    payload: data,
  }
}

// #singleTemplate

export const getSingleTemplateFailure = err => {
  return {
    type: types.SINGLE_TEMPLATE_FAILURE,
    payload: err,
  }
}

export const getSingleTemplateRequest = id => {
  return {
    type: types.SINGLE_TEMPLATE_REQUEST,
    payload: { id },
  }
}

export const getSingleTemplateSuccess = data => {
  return {
    type: types.SINGLE_TEMPLATE_SUCCESS,
    payload: data,
  }
}

// #customers,

export const getCustomersSuccess = data => {
  return {
    type: types.GET_CUSTOMERS_SUCCESS,
    payload: data,
  }
}
export const retriveCategoriesEdit = data => {
  return {
    type: types.RETRIEVE_CATEGORIES_EDIT,
    payload: data,
  }
}

export const getCustomersRequest = ({ search, page, sort, soft_deleted }) => {
  return {
    type: types.GET_CUSTOMERS_REQUEST,
    payload: {
      search,
      page: page + 1,
      sort: sort ? sort : "id",
      soft_deleted: soft_deleted ? 1 : 0,
    },
  }
}

export const getCustomersFailure = err => {
  return {
    type: types.GET_CUSTOMERS_FAILURE,
    payload: err,
  }
}

export const setCurrentPageCustomers = int => {
  return {
    type: types.SET_CURRENT_PAGE_CUSTOMERS,
    payload: int,
  }
}

export const deleteCustomerRequest = id => {
  return {
    type: types.DELETE_CUSTOMER_REQUEST,
    payload: { id },
  }
}

export const deleteCustomerSuccess = () => {
  return {
    type: types.DELETE_CUSTOMER_SUCCESS,
  }
}

export const deleteCustomerFailure = payload => {
  return {
    type: types.DELETE_CUSTOMER_FAILURE,
    payload,
  }
}

export const restoreCustomerRequest = id => {
  return {
    type: types.RESTORE_CUSTOMER_REQUEST,
    payload: { id },
  }
}

export const restoreCustomerSuccess = () => {
  return {
    type: types.RESTORE_CUSTOMER_SUCCESS,
  }
}

export const restoreCustomerFailure = payload => {
  return {
    type: types.RESTORE_CUSTOMER_FAILURE,
    payload,
  }
}

export const editCustomerRequest = (Customer, id) => {
  return {
    type: types.EDIT_CUSTOMER_REQUEST,
    payload: { formData: Customer, id },
  }
}

export const editCustomerSuccess = data => {
  return {
    type: types.EDIT_CUSTOMER_SUCCESS,
    payload: data,
  }
}

export const editCustomerFailure = err => {
  return {
    type: types.EDIT_CUSTOMER_FAILURE,
    payload: err,
  }
}

export const createCustomerRequest = Customer => {
  return {
    type: types.CREATE_CUSTOMER_REQUEST,
    payload: Customer,
  }
}

export const createCustomerSuccess = Customer => {
  return {
    type: types.CREATE_CUSTOMER_SUCCESS,
    payload: Customer,
  }
}

export const createCustomerFailure = err => {
  return {
    type: types.CREATE_CUSTOMER_FAILURE,
    payload: err,
  }
}

export const clearCreateCustomerStatus = () => {
  return {
    type: types.CLEAR_CREATE_CUSTOMER_STATUS,
  }
}

//#vehicles

export const getVehiclesSuccess = data => {
  return {
    type: types.GET_VEHICLES_SUCCESS,
    payload: data,
  }
}

export const getVehiclesRequest = ({
  paginate = 8,
  search,
  page,
  sort,
  soft_deleted,
}) => {
  return {
    type: types.GET_VEHICLES_REQUEST,
    payload: {
      paginate,
      search,
      page: page + 1,
      sort: sort ? sort : "id",
      soft_deleted: soft_deleted ? 1 : 0,
    },
  }
}

export const getVehiclesForSelectRequest = () => {
  return {
    type: types.GET_VEHICLES_REQUEST,
    payload: {
      paginate: 10000,
      search: null,
      page: 1,
      sort: "id",
      soft_deleted: 0,
    },
  }
}

export const getVehiclesFailure = err => {
  return {
    type: types.GET_VEHICLES_FAILURE,
    payload: err,
  }
}

export const setCurrentPageVehicles = int => {
  return {
    type: types.SET_CURRENT_PAGE_VEHICLES,
    payload: int,
  }
}

export const deleteVehicleRequest = id => {
  return {
    type: types.DELETE_VEHICLE_REQUEST,
    payload: { id },
  }
}

export const deleteVehicleSuccess = () => {
  return {
    type: types.DELETE_VEHICLE_SUCCESS,
  }
}

export const deleteVehicleFailure = payload => {
  return {
    type: types.DELETE_VEHICLE_FAILURE,
    payload,
  }
}

export const restoreVehicleRequest = id => {
  return {
    type: types.RESTORE_VEHICLE_REQUEST,
    payload: { id },
  }
}

export const restoreVehicleSuccess = () => {
  return {
    type: types.RESTORE_VEHICLE_SUCCESS,
  }
}

export const restoreVehicleFailure = payload => {
  return {
    type: types.RESTORE_VEHICLE_FAILURE,
    payload,
  }
}

export const editVehicleRequest = (Vehicle, id) => {
  return {
    type: types.EDIT_VEHICLE_REQUEST,
    payload: { formData: Vehicle, id },
  }
}

export const editVehicleSuccess = data => {
  return {
    type: types.EDIT_VEHICLE_SUCCESS,
    payload: data,
  }
}

export const editVehicleFailure = err => {
  return {
    type: types.EDIT_VEHICLE_FAILURE,
    payload: err,
  }
}

export const createVehicleRequest = Vehicle => {
  return {
    type: types.CREATE_VEHICLE_REQUEST,
    payload: Vehicle,
  }
}

export const createVehicleSuccess = Vehicle => {
  return {
    type: types.CREATE_VEHICLE_SUCCESS,
    payload: Vehicle,
  }
}

export const createVehicleFailure = err => {
  return {
    type: types.CREATE_VEHICLE_FAILURE,
    payload: err,
  }
}

export const clearCreateVehicleStatus = () => {
  return {
    type: types.CLEAR_CREATE_VEHICLE_STATUS,
  }
}

export const setActiveVehicleRequest = id => {
  return {
    type: types.SET_ACTIVE_VEHICLE_REQUEST,
    payload: { id },
  }
}

export const setActiveVehicleSuccess = () => {
  return {
    type: types.SET_ACTIVE_VEHICLE_SUCCESS,
  }
}

export const setActiveVehicleFailure = payload => {
  return {
    type: types.SET_ACTIVE_VEHICLE_FAILURE,
    payload,
  }
}

export const clearSetActiveVehicleStatus = () => {
  return {
    type: types.CLEAR_SET_ACTIVE_VEHICLE_STATUS,
  }
}

export const updateVehicleTypeStatus = id => {
  return {
    type: types.UPDATE_VEHICLE_TYPE_STATUS,
    payload: { id },
  }
}

//#factors

export const getFactorsSuccess = data => {
  return {
    type: types.GET_FACTORS_SUCCESS,
    payload: data,
  }
}

export const getFactorsRequest = ({
  search,
  sort,
  soft_deleted,
  type,
  paginate = 8,
}) => {
  return {
    type: types.GET_FACTORS_REQUEST,
    payload: {
      search,
      type,
      sort: sort ? sort : "id",
      soft_deleted: soft_deleted ? 1 : 0,
      paginate,
    },
  }
}

export const getFactorsFailure = err => {
  return {
    type: types.GET_FACTORS_FAILURE,
    payload: err,
  }
}

export const getOneFactorSuccess = data => {
  return {
    type: types.GET_ONE_FACTOR_SUCCESS,
    payload: data,
  }
}
export const getOneFactorFailure = err => {
  return {
    type: types.GET_ONE_FACTOR_FAILURE,
    payload: err,
  }
}
export const getOneFactorRequest = id => {
  return {
    type: types.GET_ONE_FACTOR_REQUEST,
    payload: { id },
  }
}

export const setCurrentPageFactors = int => {
  return {
    type: types.SET_CURRENT_PAGE_FACTORS,
    payload: int,
  }
}

export const deleteFactorRequest = id => {
  return {
    type: types.DELETE_FACTOR_REQUEST,
    payload: { id },
  }
}

export const deleteFactorSuccess = () => {
  return {
    type: types.DELETE_FACTOR_SUCCESS,
  }
}

export const deleteFactorFailure = payload => {
  return {
    type: types.DELETE_FACTOR_FAILURE,
    payload,
  }
}

export const restoreFactorRequest = id => {
  return {
    type: types.RESTORE_FACTOR_REQUEST,
    payload: { id },
  }
}

export const restoreFactorSuccess = () => {
  return {
    type: types.RESTORE_FACTOR_SUCCESS,
  }
}

export const restoreFactorFailure = payload => {
  return {
    type: types.RESTORE_FACTOR_FAILURE,
    payload,
  }
}

export const editFactorRequest = (Factor, id) => {
  return {
    type: types.EDIT_FACTOR_REQUEST,
    payload: { formData: Factor, id },
  }
}

export const editFactorSuccess = data => {
  return {
    type: types.EDIT_FACTOR_SUCCESS,
    payload: data,
  }
}

export const editFactorFailure = err => {
  return {
    type: types.EDIT_FACTOR_FAILURE,
    payload: err,
  }
}

export const createFactorRequest = Factor => {
  return {
    type: types.CREATE_FACTOR_REQUEST,
    payload: Factor,
  }
}

export const createFactorSuccess = Factor => {
  return {
    type: types.CREATE_FACTOR_SUCCESS,
    payload: Factor,
  }
}

export const createFactorFailure = err => {
  return {
    type: types.CREATE_FACTOR_FAILURE,
    payload: err,
  }
}

export const clearCreateFactorStatus = () => {
  return {
    type: types.CLEAR_CREATE_FACTOR_STATUS,
  }
}

//#categories

export const getCategoriesSuccess = data => {
  return {
    type: types.GET_CATEGORIES_SUCCESS,
    payload: data,
  }
}

export const getCategoriesRequest = ({
  search,
  page,
  sort,
  soft_deleted,
  paginate = 8,
}) => {
  return {
    type: types.GET_CATEGORIES_REQUEST,
    payload: {
      search,
      paginate,
      page: page + 1,
      sort: sort ? sort : "id",
      soft_deleted: soft_deleted ? 1 : 0,
    },
  }
}
export const getCategoriesOptionsRequest = () => {
  return {
    type: types.GET_CATEGORIES_REQUEST,
    payload: {
      search: null,
      page: 1,
      sort: "id",
      soft_deleted: 0,
      paginate: 100000,
    },
  }
}

export const getSubCategoriesOptionsRequest = (category = null) => {
  return {
    type: types.GET_SUB_CATEGORIES_REQUEST,
    payload: {
      category,
      search: null,
      page: 1,
      sort: "id",
      soft_deleted: 0,
      paginate: 100000,
    },
  }
}

export const getCategoriesFailure = err => {
  return {
    type: types.GET_CATEGORIES_FAILURE,
    payload: err,
  }
}

export const setCurrentPageCategories = int => {
  return {
    type: types.SET_CURRENT_PAGE_CATEGORIES,
    payload: int,
  }
}

export const deleteCategoryRequest = id => {
  return {
    type: types.DELETE_CATEGORY_REQUEST,
    payload: { id, type: "category" },
  }
}

export const deleteCategorySuccess = () => {
  return {
    type: types.DELETE_CATEGORY_SUCCESS,
  }
}

export const deleteCategoryFailure = payload => {
  return {
    type: types.DELETE_CATEGORY_FAILURE,
    payload,
  }
}

export const restoreCategoryRequest = id => {
  return {
    type: types.RESTORE_CATEGORY_REQUEST,
    payload: { id, type: "category" },
  }
}

export const restoreCategorySuccess = () => {
  return {
    type: types.RESTORE_CATEGORY_SUCCESS,
  }
}

export const restoreCategoryFailure = payload => {
  return {
    type: types.RESTORE_CATEGORY_FAILURE,
    payload,
  }
}

export const editCategoryRequest = (Category, id) => {
  return {
    type: types.EDIT_CATEGORY_REQUEST,
    payload: { formData: Category, id },
  }
}

export const editCategorySuccess = data => {
  return {
    type: types.EDIT_CATEGORY_SUCCESS,
    payload: data,
  }
}

export const editCategoryFailure = err => {
  return {
    type: types.EDIT_CATEGORY_FAILURE,
    payload: err,
  }
}

export const createCategoryRequest = Category => {
  return {
    type: types.CREATE_CATEGORY_REQUEST,
    payload: Category,
  }
}

export const createCategorySuccess = Category => {
  return {
    type: types.CREATE_CATEGORY_SUCCESS,
    payload: Category,
  }
}

export const createCategoryFailure = err => {
  return {
    type: types.CREATE_CATEGORY_FAILURE,
    payload: err,
  }
}

export const clearCreateCategoryStatus = () => {
  return {
    type: types.CLEAR_CREATE_CATEGORY_STATUS,
  }
}

// #templates

export const getTemplatesSuccess = data => {
  return {
    type: types.GET_TEMPLATES_SUCCESS,
    payload: data,
  }
}

export const pushStepsCurrentCategory = steps => {
  return {
    type: types.PUSH_STEPS_CURRENT_CATEGORY,
    payload: { steps },
  }
}

export const getTemplatesRequest = ({
  vehicle_type_id,
  search,
  page,
  sort,
  soft_deleted,
}) => {
  if (vehicle_type_id === null || !vehicle_type_id)
    return { type: "ERROR_VEHICLE_ID_NULL" }
  return {
    type: types.GET_TEMPLATES_REQUEST,
    payload: {
      vehicle_type_id,
      search,
      page: page + 1,
      sort: sort ? sort : "id",
      soft_deleted: soft_deleted ? 1 : 0,
    },
  }
}

export const getTemplatesFailure = err => {
  return {
    type: types.GET_TEMPLATES_FAILURE,
    payload: err,
  }
}

export const setCurrentPageTemplates = int => {
  return {
    type: types.SET_CURRENT_PAGE_TEMPLATES,
    payload: int,
  }
}

export const deleteTemplateRequest = (id, vehicle_type_id) => {
  console.log("vehicle_type_id = ", vehicle_type_id)
  return {
    type: types.DELETE_TEMPLATE_REQUEST,
    payload: { id, vehicle_type_id },
  }
}

export const resetCreateTemplate = () => {
  return {
    type: types.RESET_CREATE_TEMPLATE,
  }
}

export const resetCurrentSubcategories = () => {
  return {
    type: types.RESET_CURRENT_SUBCATEGORIES,
  }
}

export const setCreateTemplateNameAndVehicle = (name, vehicle) => {
  return {
    type: types.SET_CREATE_TEMPLATE_NAME_AND_VEHICLE,
    payload: { name, vehicle },
  }
}

export const deleteTemplateSuccess = () => {
  return {
    type: types.DELETE_TEMPLATE_SUCCESS,
  }
}

export const deleteTemplateFailure = payload => {
  return {
    type: types.DELETE_TEMPLATE_FAILURE,
    payload,
  }
}

export const restoreTemplateRequest = (id, vehicle) => {
  return {
    type: types.RESTORE_TEMPLATE_REQUEST,
    payload: { id, vehicle },
  }
}

export const restoreTemplateSuccess = () => {
  return {
    type: types.RESTORE_TEMPLATE_SUCCESS,
  }
}

export const restoreTemplateFailure = payload => {
  return {
    type: types.RESTORE_TEMPLATE_FAILURE,
    payload,
  }
}

// export const editTemplateRequest = (Template, id) => {
//   return {
//     type: types.EDIT_TEMPLATE_REQUEST,
//     payload: { formData: Template, id }
//   };
// };

// export const editTemplateSuccess = data => {
//   return {
//     type: types.EDIT_TEMPLATE_SUCCESS,
//     payload: data
//   };
// };

// export const editTemplateFailure = err => {
//   return {
//     type: types.EDIT_TEMPLATE_FAILURE,
//     payload: err
//   };
// };

export const createTemplateRequest = Template => {
  return {
    type: types.CREATE_TEMPLATE_REQUEST,
    payload: Template,
  }
}

export const editTemplateRequest = templateID => {
  return {
    type: types.CREATE_TEMPLATE_REQUEST,
    templateID,
    isEdit: true,
  }
}

export const createTemplateSuccess = Template => {
  return {
    type: types.CREATE_TEMPLATE_SUCCESS,
    payload: Template,
  }
}

export const createTemplateFailure = err => {
  return {
    type: types.CREATE_TEMPLATE_FAILURE,
    payload: err,
  }
}

export const clearCreateTemplateStatus = () => {
  return {
    type: types.CLEAR_CREATE_TEMPLATE_STATUS,
  }
}

//#subcategories

export const getSubCategoriesSuccess = data => {
  return {
    type: types.GET_SUB_CATEGORIES_SUCCESS,
    payload: data,
  }
}

export const getSubCategoriesRequest = ({
  category,
  search,
  page,
  sort,
  soft_deleted,
  paginate = 8,
}) => {
  return {
    type: types.GET_SUB_CATEGORIES_REQUEST,
    payload: {
      category, // undefined. selected in saga
      search,
      paginate,
      page: page + 1,
      sort: sort ? sort : "id",
      soft_deleted: soft_deleted ? 1 : 0,
    },
  }
}

export const getSubCategoriesFailure = err => {
  return {
    type: types.GET_SUB_CATEGORIES_FAILURE,
    payload: err,
  }
}

export const setCurrentPageSubCategories = int => {
  return {
    type: types.SET_CURRENT_PAGE_SUB_CATEGORIES,
    payload: int,
  }
}

export const deleteSubCategoryRequest = id => {
  return {
    type: types.DELETE_SUB_CATEGORY_REQUEST,
    payload: { id, type: "sub" },
  }
}

export const deleteSubCategorySuccess = () => {
  return {
    type: types.DELETE_SUB_CATEGORY_SUCCESS,
  }
}

export const deleteSubCategoryFailure = payload => {
  return {
    type: types.DELETE_SUB_CATEGORY_FAILURE,
    payload,
  }
}

export const restoreSubCategoryRequest = id => {
  return {
    type: types.RESTORE_SUB_CATEGORY_REQUEST,
    payload: { id, type: "sub" },
  }
}

export const restoreSubCategorySuccess = () => {
  return {
    type: types.RESTORE_SUB_CATEGORY_SUCCESS,
  }
}

export const restoreSubCategoryFailure = payload => {
  return {
    type: types.RESTORE_SUB_CATEGORY_FAILURE,
    payload,
  }
}

export const editSubCategoryRequest = (SubCategory, id) => {
  return {
    type: types.EDIT_SUB_CATEGORY_REQUEST,
    payload: { formData: SubCategory, id },
  }
}

export const editSubCategorySuccess = data => {
  return {
    type: types.EDIT_SUB_CATEGORY_SUCCESS,
    payload: data,
  }
}

export const editSubCategoryFailure = err => {
  return {
    type: types.EDIT_SUB_CATEGORY_FAILURE,
    payload: err,
  }
}

export const createSubCategoryRequest = SubCategory => {
  return {
    type: types.CREATE_SUB_CATEGORY_REQUEST,
    payload: SubCategory,
  }
}

export const createSubCategorySuccess = SubCategory => {
  return {
    type: types.CREATE_SUB_CATEGORY_SUCCESS,
    payload: SubCategory,
  }
}

export const createSubCategoryFailure = err => {
  return {
    type: types.CREATE_SUB_CATEGORY_FAILURE,
    payload: err,
  }
}

export const clearCreateSubCategoryStatus = () => {
  return {
    type: types.CLEAR_CREATE_SUB_CATEGORY_STATUS,
  }
}

//#subfactors

export const getSubFactorsSuccess = data => {
  return {
    type: types.GET_SUB_FACTORS_SUCCESS,
    payload: data,
  }
}

export const getSubFactorsRequest = () => {
  return {
    type: types.GET_SUB_FACTORS_REQUEST,
    payload: { parentId: null }, // we can get this param from saga
  }
}

export const getSubFactorsFailure = err => {
  return {
    type: types.GET_SUB_FACTORS_FAILURE,
    payload: err,
  }
}

export const setCurrentPageSubFactors = int => {
  return {
    type: types.SET_CURRENT_PAGE_SUB_FACTORS,
    payload: int,
  }
}

export const deleteSubFactorRequest = id => {
  return {
    type: types.DELETE_SUB_FACTOR_REQUEST,
    payload: { id },
  }
}

export const deleteSubFactorSuccess = () => {
  return {
    type: types.DELETE_SUB_FACTOR_SUCCESS,
  }
}

export const deleteSubFactorFailure = payload => {
  return {
    type: types.DELETE_SUB_FACTOR_FAILURE,
    payload,
  }
}

export const restoreSubFactorRequest = id => {
  return {
    type: types.RESTORE_SUB_FACTOR_REQUEST,
    payload: { id },
  }
}

export const restoreSubFactorSuccess = () => {
  return {
    type: types.RESTORE_SUB_FACTOR_SUCCESS,
  }
}

export const restoreSubFactorFailure = payload => {
  return {
    type: types.RESTORE_SUB_FACTOR_FAILURE,
    payload,
  }
}

export const editSubFactorRequest = (SubFactor, id) => {
  return {
    type: types.EDIT_SUB_FACTOR_REQUEST,
    payload: { formData: SubFactor, id },
  }
}

export const editSubFactorSuccess = data => {
  return {
    type: types.EDIT_SUB_FACTOR_SUCCESS,
    payload: data,
  }
}

export const editSubFactorFailure = err => {
  return {
    type: types.EDIT_SUB_FACTOR_FAILURE,
    payload: err,
  }
}

export const createSubFactorRequest = SubFactor => {
  return {
    type: types.CREATE_SUB_FACTOR_REQUEST,
    payload: SubFactor,
  }
}

export const createSubFactorSuccess = SubFactor => {
  return {
    type: types.CREATE_SUB_FACTOR_SUCCESS,
    payload: SubFactor,
  }
}

export const createSubFactorFailure = err => {
  return {
    type: types.CREATE_SUB_FACTOR_FAILURE,
    payload: err,
  }
}

export const clearCreateSubFactorStatus = () => {
  return {
    type: types.CLEAR_CREATE_SUB_FACTOR_STATUS,
  }
}
