import React from "react"
import { StyledTableHeader, StyledLabel } from "../../styledComponents"

const labels = [
  { id: "id", label: "ID", width: "5%" },
  { id: "name", label: "Name", width: "14%" },
  { id: "phone", label: "Phone", width: "14%" },
  { id: "email", label: "E-mail", width: "14%" },
  { id: "type", label: "Type", width: "14%" },
  { id: "company", label: "Company", width: "23%" },
  { id: "actions", label: "Actions", width: "14%" },
]

const Header = () => {
  const getFlexScale = index => {
    if (index < 4) return 1
    return 1.5
  }

  return (
    <StyledTableHeader>
      {labels.map((item, index) => (
        <StyledLabel key={index} style={{ width: item.width }}>
          {item.label}
        </StyledLabel>
      ))}
    </StyledTableHeader>
  )
}

export default Header
