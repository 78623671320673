import types from "../types"

const initialState = {
  pending: 0, // page 1
  technicians: 0,
  companies: 0,
  customers: 0,
  vehicles: 0,
  factors: 0,
  categories: 0,
  templates: 0,
  subCategories: 0,
}

export default function currentPages(state = initialState, action) {
  switch (action.type) {
    case types.SET_CURRENT_PAGE_PENDING:
      return {
        ...state,
        pending: action.payload,
      }
    case types.SET_CURRENT_PAGE_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
      }
    case types.SET_CURRENT_PAGE_TECHNICIANS:
      return {
        ...state,
        technicians: action.payload,
      }
    case types.SET_CURRENT_PAGE_COMPANIES:
      return {
        ...state,
        companies: action.payload,
      }
    case types.SET_CURRENT_PAGE_VEHICLES:
      return {
        ...state,
        vehicles: action.payload,
      }
    case types.SET_CURRENT_PAGE_FACTORS:
      return {
        ...state,
        factors: action.payload,
      }
    case types.SET_CURRENT_PAGE_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      }
    case types.SET_CURRENT_PAGE_SUB_CATEGORIES:
      return {
        ...state,
        subCategories: action.payload,
      }
    case types.SET_CURRENT_PAGE_TEMPLATES:
      return {
        ...state,
        templates: action.payload,
      }
    default:
      return state
  }
}
