import React, { useState } from "react"
import { Row, Col, Menu, Dropdown, Button, message, Input, Modal } from "antd"
import { DownOutlined, UserOutlined } from "@ant-design/icons"
import {
  PageContainer,
  StyledDropDown,
  StyledTextAreat,
} from "../styledComponents"
import PageTitle from "../../../components/PageTitle"
import { NewButton } from "../../../components/Buttons"

const names = [
  "Technicians",
  "Technicians and Mechanicshops",
  "Customers",
  "All",
]

const Notifications = () => {
  // display choosen items
  const [dropdownValue, setDropdownValue] = useState("")

  // for Input to clear TextArea
  const [text, setText] = useState("")

  // for DropDown
  function handleMenuClick(e) {
    setDropdownValue(e.key)
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      {names.map(item => {
        return (
          <Menu.Item key={item} icon={<UserOutlined />}>
            {item}
          </Menu.Item>
        )
      })}
    </Menu>
  )

  // for TextArea
  const { TextArea } = Input

  //for button's modal window
  const [active, setActive] = useState(false)

  const confirm = () => {
    if (!text) {
      alert("Please, fill all the data")
      return
    }
    setActive(false)
    setText("")
  }

  return (
    <div>
      <PageContainer>
        <Row>
          <Col span={8}>
            <PageTitle title={"Notifications"} />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <StyledDropDown>
              <Dropdown overlay={menu}>
                <Button style={{ minWidth: 240, position: "relative" }}>
                  <div style={{ textAlign: "left" }}>
                    {dropdownValue || "Select recipient"}
                  </div>
                  <DownOutlined
                    style={{ position: "absolute", top: 10, right: 10 }}
                  />
                </Button>
              </Dropdown>
            </StyledDropDown>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <StyledTextAreat>
              <TextArea
                placeholder="Type Text"
                onChange={e => {
                  setText(e.target.value)
                }}
                value={text}
                autoSize={{ minRows: 5, maxRows: 8 }}
                showCount
                maxLength={250}
              />
            </StyledTextAreat>
            <Modal
              title="Send notification confirmation"
              visible={active}
              onOk={confirm}
              onCancel={() => setActive(false)}
              okText="Confirm"
              cancelText="Cancel"
              okButtonProps={{
                style: {
                  background: "#559966",
                  borderRadius: 20,
                  height: 40,
                  width: 120,
                  fontSize: "0.8rem",
                  margin: "0 6px",
                  color: "#fff",
                },
              }}
              cancelButtonProps={{
                style: {
                  background: "#D53E3E",
                  borderRadius: 20,
                  height: 40,
                  width: 120,
                  fontSize: "0.8rem",
                  margin: "0 6px",
                  color: "#fff",
                },
              }}
            >
              <p>
                Are you sure you want to send a message to the {dropdownValue}?
              </p>
            </Modal>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <NewButton
              title="Send"
              onClick={() => {
                setActive(true)
              }}
            ></NewButton>
          </Col>
        </Row>
      </PageContainer>
    </div>
  )
}

export default Notifications
