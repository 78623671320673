import { takeLatest } from "redux-saga/effects"
import { postLoginAsync } from "./auth.saga"
import getPendingAsync from "./pending/get.saga"
import assignPendingAsync from "./pending/assign.saga"
import getAllCompaniesAsync from "./companies/getAllCompanies.saga"
import getCompaniesAsync from "./companies/get.saga"
import types from "../types"

import setActiveTechnicianAsync from "./technicians/setActive.saga"
import restoreTechnicianAsync from "./technicians/restore.saga"
import deleteTechnicianAsync from "./technicians/delete.saga"
import editTechnicianAsync from "./technicians/edit.saga"
import getTechniciansAsync from "./technicians/get.saga"
import createTechnicianAsync from "./technicians/create.saga"

import deleteCompanyAsync from "./companies/delete.saga"
import editCompanyAsync from "./companies/edit.saga"
import restoreCompanyAsync from "./companies/restore.saga"
import createCompanyAsync from "./companies/create.saga"

import deleteCustomerAsync from "./customers/delete.saga"
import editCustomerAsync from "./customers/edit.saga"
import restoreCustomerAsync from "./customers/restore.saga"
import createCustomerAsync from "./customers/create.saga"
import getCustomersAsync from "./customers/get.saga"

import deleteVehicleAsync from "./vehicles/delete.saga"
import editVehicleAsync from "./vehicles/edit.saga"
import restoreVehicleAsync from "./vehicles/restore.saga"
import createVehicleAsync from "./vehicles/create.saga"
import getVehiclesAsync from "./vehicles/get.saga"
import setActiveVehicleAsync from "./vehicles/setActive.saga"

import deleteFactorAsync from "./factors/delete.saga"
import editFactorAsync from "./factors/edit.saga"
import restoreFactorAsync from "./factors/restore.saga"
import createFactorAsync from "./factors/create.saga"
import getFactorsAsync from "./factors/get.saga"
import getOneFactorAsync from "./factors/getOne.saga"

import deleteCategoryAsync from "./categories/delete.saga"
import editCategoryAsync from "./categories/edit.saga"
import restoreCategoryAsync from "./categories/restore.saga"
import createCategoryAsync from "./categories/create.saga"
import getCategoriesAsync from "./categories/get.saga"

import editSubCategoryAsync from "./subCategories/edit.saga"
import createSubCategoryAsync from "./subCategories/create.saga"
import getSubCategoriesAsync from "./subCategories/get.saga"

import deleteTemplateAsync from "./templates/delete.saga"
import restoreTemplateAsync from "./templates/restore.saga"
// import editTemplateAsync from "./templates/edit.saga";
import createTemplateAsync from "./templates/create.saga"
import getTemplatesAsync from "./templates/get.saga"

import getSubFactorsAsync from "./subFactors/get.saga"
import createSubFactorAsync from "./subFactors/create.saga"
import editSubFactorAsync from "./subFactors/edit.saga"
import deleteSubFactorAsync from "./subFactors/delete.saga"
import checkAuthAsync from "./dashboard/checkAuth.saga"
import getSingleTemplateAsync from "./templates/getSingle.saga"

function* watch() {
  yield takeLatest(types.CHECK_AUTH, checkAuthAsync)
  yield takeLatest(types.GET_PENDING_REQUEST, getPendingAsync)
  yield takeLatest(types.GET_TECHNICIANS_REQUEST, getTechniciansAsync)
  yield takeLatest(types.LOGIN_REQUEST, postLoginAsync)
  yield takeLatest(types.GET_ALL_COMPANIES_REQUEST, getAllCompaniesAsync)
  yield takeLatest(types.CREATE_TECHNICIAN_REQUEST, createTechnicianAsync)
  yield takeLatest(types.ASSIGN_PENDING_REQUEST, assignPendingAsync)
  yield takeLatest(types.GET_COMPANIES_REQUEST, getCompaniesAsync)
  yield takeLatest(
    types.SET_ACTIVE_TECHNICIAN_REQUEST,
    setActiveTechnicianAsync,
  )
  yield takeLatest(types.RESTORE_TECHNICIAN_REQUEST, restoreTechnicianAsync)
  yield takeLatest(types.DELETE_TECHNICIAN_REQUEST, deleteTechnicianAsync)
  yield takeLatest(types.EDIT_TECHNICIAN_REQUEST, editTechnicianAsync)
  yield takeLatest(types.RESTORE_COMPANY_REQUEST, restoreCompanyAsync)
  yield takeLatest(types.DELETE_COMPANY_REQUEST, deleteCompanyAsync)
  yield takeLatest(types.EDIT_COMPANY_REQUEST, editCompanyAsync)
  yield takeLatest(types.CREATE_COMPANY_REQUEST, createCompanyAsync)

  yield takeLatest(types.RESTORE_CUSTOMER_REQUEST, restoreCustomerAsync)
  yield takeLatest(types.DELETE_CUSTOMER_REQUEST, deleteCustomerAsync)
  yield takeLatest(types.EDIT_CUSTOMER_REQUEST, editCustomerAsync)
  yield takeLatest(types.CREATE_CUSTOMER_REQUEST, createCustomerAsync)
  yield takeLatest(types.GET_CUSTOMERS_REQUEST, getCustomersAsync)
  yield takeLatest(types.RESTORE_PENDING_REQUEST, restoreTechnicianAsync)
  yield takeLatest(types.DELETE_PENDING_REQUEST, deleteTechnicianAsync)

  yield takeLatest(types.RESTORE_VEHICLE_REQUEST, restoreVehicleAsync)
  yield takeLatest(types.DELETE_VEHICLE_REQUEST, deleteVehicleAsync)
  yield takeLatest(types.EDIT_VEHICLE_REQUEST, editVehicleAsync)
  yield takeLatest(types.CREATE_VEHICLE_REQUEST, createVehicleAsync)
  yield takeLatest(types.GET_VEHICLES_REQUEST, getVehiclesAsync)
  yield takeLatest(types.SET_ACTIVE_VEHICLE_REQUEST, setActiveVehicleAsync)

  yield takeLatest(types.RESTORE_FACTOR_REQUEST, restoreFactorAsync)
  yield takeLatest(types.DELETE_FACTOR_REQUEST, deleteFactorAsync)
  yield takeLatest(types.EDIT_FACTOR_REQUEST, editFactorAsync)
  yield takeLatest(types.CREATE_FACTOR_REQUEST, createFactorAsync)
  yield takeLatest(types.GET_FACTORS_REQUEST, getFactorsAsync)
  yield takeLatest(types.GET_ONE_FACTOR_REQUEST, getOneFactorAsync)

  yield takeLatest(types.RESTORE_CATEGORY_REQUEST, restoreCategoryAsync)
  yield takeLatest(types.DELETE_CATEGORY_REQUEST, deleteCategoryAsync)
  yield takeLatest(types.EDIT_CATEGORY_REQUEST, editCategoryAsync)
  yield takeLatest(types.CREATE_CATEGORY_REQUEST, createCategoryAsync)
  yield takeLatest(types.GET_CATEGORIES_REQUEST, getCategoriesAsync)

  yield takeLatest(types.RESTORE_TEMPLATE_REQUEST, restoreTemplateAsync)
  yield takeLatest(types.DELETE_TEMPLATE_REQUEST, deleteTemplateAsync)
  // yield takeLatest(types.EDIT_TEMPLATE_REQUEST, editTemplateAsync);
  yield takeLatest(types.CREATE_TEMPLATE_REQUEST, createTemplateAsync)
  yield takeLatest(types.GET_TEMPLATES_REQUEST, getTemplatesAsync)

  yield takeLatest(types.EDIT_SUB_CATEGORY_REQUEST, editSubCategoryAsync)
  yield takeLatest(types.CREATE_SUB_CATEGORY_REQUEST, createSubCategoryAsync)
  yield takeLatest(types.GET_SUB_CATEGORIES_REQUEST, getSubCategoriesAsync)
  yield takeLatest(types.DELETE_SUB_CATEGORY_REQUEST, deleteCategoryAsync)
  yield takeLatest(types.RESTORE_SUB_CATEGORY_REQUEST, restoreCategoryAsync)

  yield takeLatest(types.GET_SUB_FACTORS_REQUEST, getSubFactorsAsync)
  yield takeLatest(types.EDIT_SUB_FACTOR_REQUEST, editSubFactorAsync)
  yield takeLatest(types.DELETE_SUB_FACTOR_REQUEST, deleteSubFactorAsync)
  yield takeLatest(types.CREATE_SUB_FACTOR_REQUEST, createSubFactorAsync)

  yield takeLatest(types.SINGLE_TEMPLATE_REQUEST, getSingleTemplateAsync)
}

export default watch
