import React, { useState, useCallback, useRef, useEffect } from "react"
import classes from "./classes.module.css"

const imageSize = [500, 456] // to be changed for the next set of pictures

const Canvas = ({ url, setCircle, circle, hover }) => {
  const [imageURL, setImageURL] = useState(null)
  const imageRef = useRef(null)
  const canvasRef = useRef(null)

  useEffect(() => {
    setImageURL(url)
  }, [url])

  const getCoordinates = event => {
    if (!canvasRef.current) {
      return
    }
    const canvas = canvasRef.current
    return {
      x: event.pageX - canvas.offsetLeft,
      y: event.pageY - canvas.offsetTop,
    }
  }

  const drawCircle = ctx => {
    ctx.beginPath()
    ctx.strokeStyle = "#ffff11"
    ctx.lineWidth = 2
    ctx.arc(circle.x, circle.y, 50, 0, 2 * Math.PI)
    ctx.stroke()
  }

  React.useEffect(() => {
    const canvas = canvasRef.current
    const img = imageRef.current
    const ctx = canvas.getContext("2d")
    ctx.clearRect(0, 0, window.innerHeight, window.innerWidth)
    ctx.drawImage(img, 0, 0, ...imageSize)
    if (circle) {
      drawCircle(ctx)
    }
  }, [circle, hover])

  useEffect(() => {
    setTimeout(() => {
      const canvas = canvasRef.current
      const img = imageRef.current
      const ctx = canvas.getContext("2d")
      ctx.clearRect(0, 0, window.innerHeight, window.innerWidth)
      ctx.drawImage(img, 0, 0, ...imageSize)
      if (circle) {
        drawCircle(ctx)
      }
    }, 1000)
  }, [])
  const handleClick = e => {
    setCircle(getCoordinates(e))
  }

  return (
    <div className={classes.container}>
      <canvas onClick={handleClick} ref={canvasRef} width={500} height={450} />
      <img
        ref={imageRef}
        src={imageURL}
        alt="canvasImg"
        className={classes.hidden}
      />
    </div>
  )
}

export default Canvas
