import types from "../types"
import uuid from "uuid/v1"

class _Category {
  constructor() {
    this.store_id = uuid()
    this.steps = []
    this.subcategories = []
    // meta
    this.id = null
    this.name = ""
    this.model = null // {type , picture}
    this.editing = true
    this.allowSub = false
  }
}

// payload:
// id: 7
// name: "Sedan 2cat"
// number_of_assigned_companies: 1

// templates_categories: Array(2)
// 0:
// id: 1
// model_path: "Sedan/body"
// name: "Exterior"
// sub_categories: Array(3)
// 0:
// areas: {x: 258, y: 77}
// factors: (3) [{…}, {…}, {…}]
// id: 2
// name: "Front end"
// steps: (3) [{…}, {…}, {…}]
// template_category_id: 32
// __proto__: Object
// 1: {id: 5, template_category_id: 33, name: "Hood", areas: {…}, factors: Array(3), …}
// 2: {id: 6, template_category_id: 34, name: "Roof", areas: {…},

const initialCategory = new _Category()
const initialState = {
  name: null,
  vehicle: null,
  processing: true,
  categories: [initialCategory], // name:string photo:fileArray allowSub:bool
  currentCategory: null,
  allSubcategoriesFinished: false,
}

const formatSubcategoriesEdit = arr => {
  if (!Array.isArray(arr)) {
    return []
  }
  return arr.map(e => {
    return {
      editing: false,
      id: e.id,
      name: e.name,
      steps: e.steps.sort((a, b) => a.order < b.order).map(s => s.name),
      store_id: uuid(),
      subcategory: { name: e.name, id: e.id },
      areas: e.areas,
    }
  })
}

const formatCategoriesEdit = arr => {
  return arr.map(e => {
    return {
      allowSub: true,
      editing: false,
      id: e.id,
      name: e.name,
      steps: [],
      store_id: uuid(),
      model: {
        picture: null,
        model_path: e.model_path,
        type: e.model_path.split("/")[1],
      },
      subcategories: formatSubcategoriesEdit(e.sub_categories),
    }
  })
}

const deductProcessingFromSetCurrent = (currentCategory, categories) => {
  if (currentCategory) {
    return false
  } else if (categories.length === 0) {
    return false
  }
  return true
}

export default function createTeplate(state = initialState, action) {
  switch (action.type) {
    case types.SET_CREATE_TEMPLATE_NAME_AND_VEHICLE:
      return {
        ...state,
        name: action.payload.name,
        vehicle: action.payload.vehicle,
      }
    case types.SET_TEMPLATE_CURRENT_CATEGORY:
      const currentCategory = action.payload
        ? { ...state.currentCategory, ...action.payload }
        : null
      if (currentCategory && !currentCategory.steps) currentCategory.steps = []
      return {
        ...state,
        processing: deductProcessingFromSetCurrent(
          currentCategory,
          state.categories,
        ),
        currentCategory,
      }
    case types.PUSH_STEPS_CURRENT_CATEGORY:
      return {
        ...state,
        currentCategory: {
          ...state.currentCategory,
          steps: action.payload.steps,
        },
      }
    case types.PUSH_SUB_CURRENT_CATEGORY:
      return {
        ...state,
        currentCategory: {
          ...state.currentCategory,
          subcategories: action.payload.subcategories,
        },
      }
    case types.PUSH_TEMPLATE_CATEGORY:
      return {
        ...state,
        processing: false,
        categories: [
          ...state.categories,
          action.payload
            ? action.payload
            : new _Category(state.categories.length),
        ],
      }
    case types.REMOVE_TEMPLATE_CATEGORIES:
      const filteredCategories = state.categories.filter(
        e => action.payload.store_id !== e.store_id,
      )
      return {
        ...state,
        categories: filteredCategories,
      }
    case types.UPDATE_TEMPLATE_CATEGORIES:
      const newCategories = state.categories.map(e =>
        action.payload.store_id === e.store_id ? action.payload.item : e,
      )
      return {
        ...state,
        categories: newCategories,
      }
    case types.RESET_CREATE_TEMPLATE:
      return initialState
    case types.RESET_TEMPLATE_CATEGORIES:
      return {
        ...state,
        categories: action.payload || [],
      }
    case types.RESET_CURRENT_SUBCATEGORIES:
      return {
        ...state,
        currentCategory: { ...state.currentCategory, subcategories: [] },
      }
    case types.SET_ALL_SUBCATEGORIES_FINISHED:
      return {
        ...state,
        allSubcategoriesFinished: action.payload.bool,
      }
    case types.SET_TEMPLATE_PACKAGE:
      return {
        ...state,
        package_id: action.pkg.id,
        package_body: {
          label: action.pkg.name,
          value: action.pkg,
          ...action.pkg,
        },
      }
    case types.RETRIEVE_CATEGORIES_EDIT:
      return {
        ...state,
        categories: formatCategoriesEdit(action.payload),
      }
    default:
      return state
  }
}
