import React from "react"
import styled from "styled-components"
import theme from "../style/theme"

const StyledLoginContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 5%;
  width: 100%;
`
const StyledHead = styled.h3`
	margin-bottom: 2%;
	font-size: ${theme.font.sm};
	font-weight: ${theme.font.regular}
	color: ${theme.colors.blackMain}
`
const StyledInput = styled.input`
  height: 40px;
  width: 100%;
  min-width: ${theme.size.minWidth};
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  border: 0.5px solid ${theme.colors.borderMain};
`

const StyledSimpleContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: ${props => (props.marginBottom ? "5%" : "0")};
  margin-right: ${props => (props.margin ? "5%" : "0")};
`

const StyledModalInput = styled.input`
  height: 28px;
  width: 100%;
  min-width: ${theme.size.minWidth};
  border-radius: 2px;
  // color:  ${theme.colors.borderMain};
  padding: 10px;
  box-sizing: border-box;
  border: 0.5px solid ${theme.colors.borderMain};
`

export function LoginInput({
  title = null,
  name = "default",
  value = "default",
  placeholder,
  onChange,
}) {
  let type
  switch (name) {
    case "password":
      type = "password"
      break
    case "email":
      type = "email"
      break
    default:
      type = "text"
  }
  return (
    <StyledLoginContainer>
      <StyledHead>{title}</StyledHead>
      <StyledInput
        placeholder={placeholder}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
      />
    </StyledLoginContainer>
  )
}

export function SimpleInput({
  title = null,
  name = "default",
  value = "default",
  placeholder = "type...",
  onChange,
  style = {},
  small = false,
  type = "text",
  disabled = false,
  marginBottom = true,
  margin = true,
  containerStyle = {},
}) {
  const disabledStyle = disabled
    ? {
        backgroundColor: theme.colors.blueLight,
        border: `1px solid ${theme.colors.borderDarkBlue}`,
      }
    : {}
  return (
    <StyledSimpleContainer
      small={small}
      marginBottom={marginBottom}
      margin={margin}
      style={containerStyle}
    >
      <StyledHead>{title}</StyledHead>
      <StyledModalInput
        disabled={disabled}
        style={{ ...disabledStyle, ...style }}
        placeholder={placeholder}
        type={type}
        name={name}
        value={value}
        onChange={e => onChange(e.target.value)}
      />
    </StyledSimpleContainer>
  )
}
