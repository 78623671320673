/**
 * Upload image.
 *
 * @param imageElement
 * @returns {Promise<unknown>}
 */
export const uploadImage = imageElement => {
  return new Promise((resolve, reject) => {
    if (imageElement instanceof FileList) {
      const formData = new FormData()
      formData.append("image", imageElement[0])

      fetch(`${process.env.REACT_APP_API_PATH}/image-upload`, {
        method: "POST",
        body: formData,
      })
        .then(response => {
          return response.json()
        })
        .then(data => {
          resolve(data.path)
        })
        .catch(err => {
          reject(err)
        })
    } else {
      reject("image is not define")
    }
  })
}
