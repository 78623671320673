import React, { useState, useEffect } from "react"
import { StyledField } from "../styled"
import Editing from "./Editing"

const CategoryItem = ({
  item,
  setTemplateCurrentCategory,
  someItemsBeingEditing,
}) => {
  const [editing, setEditing] = useState(item.name.length === 0) // default item

  useEffect(() => {
    item.editing = editing
  }, [editing])

  const handleStartEditing = () => {
    if (someItemsBeingEditing()) return
    setTemplateCurrentCategory(item)
    setEditing(true)
  }

  const { name } = item
  if (editing)
    return <Editing item={item} finishEditing={() => setEditing(false)} />
  return (
    <StyledField onClick={handleStartEditing}>
      {name || `Unknown Category`}
    </StyledField>
  )
}

export default CategoryItem
