import React, { useState, useEffect } from "react"
import MySwitch from "../../../../components/MySwitch"
import MySearchInput from "../../../../components/MySearchInput"
import PageTitle from "../../../../components/PageTitle"
import Table from "./Table"
import { PageContainer, StyledToolBar, ToolGroup } from "../../styledComponents"
import { NewButton } from "../../../../components/Buttons"
// import MyPagination from "../../../../components/MyPagination";
import AddModal from "../Actions/AddModal"
import { useAlert } from "react-alert"
import RenderModal from "../../../../components/RenderModal"
import SimpleModal from "../../../../components/SimpleModal"

const Customers = props => {
  const [search, setSearch] = useState(null)

  const [newCustomer, setNewCustomer] = useState(null)
  const [editedCustomer, setEditedCustomer] = useState(null)
  const alert = useAlert()

  const {
    setShowDeleted,
    deleted,
    getCustomersRequest,
    getCustomersStatus,
    customers,
    currentPage,
    setCurrentPageCustomers,
    setDeleteModal,
    setEditModal,
    setRestoreModal,
    modals,
    editCustomerRequest,
    restoreCustomerRequest,
    deleteCustomerRequest,
    createCustomerRequest,
    clearCreateCustomerStatus,
    createRequestStatus,
    createRequestError,
  } = props

  const getData = (page = null, search = null) => {
    const params = {
      page: page !== null ? page : currentPage,
      soft_deleted: deleted.show,
      search,
      sort: "name",
    }
    getCustomersRequest(params)
  }

  useEffect(() => {
    if (createRequestStatus === "loading") {
      alert.show("Sending")
    }
    if (createRequestStatus === "success") {
      alert.success("Customer created!")
    }

    if (createRequestStatus === "failure") {
      // alert.error("Error creating Technician");
      Object.values(createRequestError).forEach(e => {
        alert.error(e)
      })
    }
    clearCreateCustomerStatus()
  }, [createRequestStatus])

  const handleEdit = () => {
    console.log("editedCustomer = ", editedCustomer)

    editCustomerRequest(
      {
        ...editedCustomer,
        phone: editedCustomer.phone.replace(/\W/g, ""),
      },
      modals.edit.id,
    )
  }

  const handleCreate = () => {
    console.log("newCustomer = ", newCustomer)
    createCustomerRequest({
      ...newCustomer,
      phone: newCustomer.phone.replace(/\W/g, ""),
    })
  }

  const handleDelete = () => {
    deleteCustomerRequest(modals.delete.id)
  }

  const handleRestore = () => {
    restoreCustomerRequest(modals.restore.id)
  }

  const handleSearch = e => {
    const query = e.target.value
    setSearch(query)
    getData(null, query)
  }

  useEffect(() => {
    getData()
  }, [deleted.show])

  const handleChangePage = event => {
    setCurrentPageCustomers(event.selected)
    getData(event.selected)
  }

  return (
    <PageContainer>
      <RenderModal
        label="Add New Customer"
        onConfirm={handleCreate}
        render={() => <AddModal callback={setNewCustomer} />}
        active={props.modals.showAdd}
        setActive={props.setAddModal}
      />
      <RenderModal
        label={`Edit ${modals.edit.selected.first_name} ${modals.edit.selected.last_name}`}
        render={() => (
          <AddModal
            callback={setEditedCustomer}
            customer={modals.edit.selected}
          />
        )}
        active={modals.edit.show}
        onConfirm={handleEdit}
        setActive={setEditModal}
      />
      <RenderModal
        alignButtonsRight
        label="Confirmation"
        render={() => (
          <SimpleModal text="Are you sure you want to delete Customer?" />
        )}
        onConfirm={handleDelete}
        active={modals.delete.show}
        setActive={setDeleteModal}
      />
      <RenderModal
        alignButtonsRight
        label="Confirmation"
        render={() => (
          <SimpleModal text="Are you sure you want to restore Customer?" />
        )}
        active={modals.restore.show}
        setActive={setRestoreModal}
        onConfirm={handleRestore}
      />
      <PageTitle title="Customers" />
      <StyledToolBar>
        <MySearchInput value={search} onChange={handleSearch} />
        <ToolGroup>
          {/* <MyPagination
                        current={currentPage}
                        pageCount={customers.meta.last_page}
                        onChangePage={handleChangePage}
                    /> */}
        </ToolGroup>
        <MySwitch
          label="Show Deleted"
          active={deleted.show}
          setActive={setShowDeleted}
        />
        <NewButton onClick={() => props.setAddModal(true)} />
      </StyledToolBar>
      <Table
        // data={customers.array}
        status={getCustomersStatus}
        currentPage={currentPage}
        onChangePage={handleChangePage}
        customers={customers}
      />
    </PageContainer>
  )
}

export default Customers
