import { put, call, select } from "redux-saga/effects"

import * as actions from "../../actions"
import axios from "axios"

const getToken = state => state.auth.token

const apiCall = ({ search, page, sort, soft_deleted }, token) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_PATH,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const perPage = 8
  let path = `/dashboard/technicians?paginate=${perPage}&page=${page}&sort=id&soft_deleted=${soft_deleted}`
  if (search) path += `&search=${search}`
  return instance
    .get(path)
    .then(response => response)
    .catch(error => error)
}

export default function* getTechniciansAsync(action) {
  const { search, page, sort, soft_deleted } = action.payload
  try {
    const token = yield select(getToken)
    const params = {
      search,
      page,
      sort,
      soft_deleted,
    }
    // const data = yield call(getFakePending);
    const data = yield call(apiCall, params, token)

    console.log(" data = ", data)
    if (data.status === 200)
      yield put(
        actions.getTechniciansSuccess({
          data: data.data.data,
          meta: data.data.meta,
        }),
      )
    else yield put(actions.getTechniciansFailure())
  } catch (err) {
    yield put(actions.getTechniciansFailure())
  }
}
