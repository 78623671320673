import { put, call, select } from "redux-saga/effects"

import * as actions from "../../actions"
import axios from "axios"

const getToken = state => state.auth.token
const getCurrentFactor = state => state.sub.selected.id

const apiCall = (parentId, token) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_PATH,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const path = `/dashboard/subfactors/${parentId}`
  return instance
    .get(path)
    .then(response => response)
    .catch(error => error)
}

export default function* getSubFactorsAsync(action) {
  const factor = yield select(getCurrentFactor)

  try {
    const token = yield select(getToken)
    const res = yield call(apiCall, factor, token)

    console.log(" res = ", res)
    if (res.status === 200)
      yield put(actions.getSubFactorsSuccess({ data: res.data, meta: null }))
    else yield put(actions.getSubFactorsFailure())
  } catch (err) {
    yield put(actions.getSubFactorsFailure())
  }
}
