import React, { useState } from "react"
import styled from "styled-components"
import LeftEditor from "./LeftEditor"
import RightEditor from "./RightEditor"
import ActionHeader from "./ActionHeader"

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  // flex: 1;
  height: 100%;
  // box-sizing: border-box;
  overflow-y: hidden;
`

const CategoryEditor = ({ history }) => {
  const createCategory = () => {}

  const nextUrl = history.location.pathname + "/categories"
  const isEdit = history.location.pathname.includes("edit")
  return (
    <>
      <ActionHeader isEdit={isEdit} />
      <Container>
        <LeftEditor isEdit={isEdit} />
        <RightEditor />
      </Container>
    </>
  )
}

export default CategoryEditor
