import { keyframes } from "styled-components"

export const fadeAnimation = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
}
`
