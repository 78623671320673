import React from "react"
import { Layout, Image, Typography, Space, Button, Modal } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faSignOutAlt,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons"
import { useHistory } from "react-router-dom"

import styles from "styles/components/HomeLayout.module.css"
import Avatar from "antd/lib/avatar/avatar"
import { useDispatch, useSelector } from "react-redux"
import { signOut } from "store/actions"

const Header = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.auth)

  const handleLogOutClick = () => {
    Modal.confirm({
      title: "Are you sure want to sign out?",
      icon: (
        <FontAwesomeIcon icon={faExclamationCircle} size="2x" color="red" />
      ),
      content: "You will have to sign in again",
      okText: "Sign out",
      okType: "danger",
      cancelText: "No",
      onOk() {
        dispatch(signOut())
        history.replace("/login")
      },
    })
  }

  const firstLettersOfNames = (names = []) => {
    return names.map((x) => x[0]).join('')
  }

  return (
    <Layout.Header
      style={{
        backgroundColor: "white",
        display: "flex",
      }}
    >
      <Image src="/logo.png" height="80%" preview={false} />
      <h1 className={styles.headerTitle}>Admin Panel</h1>
      <Space style={{ flex: 1, justifyContent: "flex-end" }}>
        <Avatar>{firstLettersOfNames([user.first_name, user.last_name])}</Avatar>
        <Typography.Text>{user.email}</Typography.Text>
        <Button
          type="primary"
          shape="round"
          icon={<FontAwesomeIcon icon={faSignOutAlt} />}
          size="middle"
          onClick={handleLogOutClick}
        >
          &nbsp;Sign out
        </Button>
      </Space>
    </Layout.Header>
  )
}

export default Header
