import React, { Suspense, useEffect } from "react"
import {
  setDeleteModal,
  setRestoreModal,
  setEditModal,
  setShowDeleted,
  setAddModal,
  getCategoriesRequest,
  createCategoryRequest,
  deleteCategoryRequest,
  setCurrentPageCategories,
  restoreCategoryRequest,
  editCategoryRequest,
  clearCreateCategoryStatus,
  getSubCategoriesRequest,
  createSubCategoryRequest,
  editSubCategoryRequest,
  deleteSubCategoryRequest,
  restoreSubCategoryRequest,
  setCurrentPageSubCategories,
  clearCreateSubCategoryStatus,
  setShowSub,
} from "../../../store/actions"
import { connect } from "react-redux"

const Body = React.lazy(() => import("./Body/index.categories"))
const SubBody = React.lazy(() => import("./Body/index.sub"))

const mapState = state => ({
  deleted: state.deleted,
  modals: state.modals,
  categories: state.categories,
  getCategoriesStatus: state.statuses.getCategories,
  getSubCategoriesStatus: state.statuses.getSubCategories,
  createRequestStatus: state.statuses.createCategory,
  createRequestError: state.errors.createCategory,
  createSubStatus: state.statuses.createSubCategory,
  createSubError: state.errors.createSubCategory,
  currentPage: state.currentPages.categories,
  currentSubPage: state.currentPages.subCategories,
  sub: state.sub,
})

const mapDispatch = {
  setDeleteModal,
  setRestoreModal,
  setEditModal,
  setShowDeleted,
  setAddModal,
  getCategoriesRequest,
  createCategoryRequest,
  editCategoryRequest,
  deleteCategoryRequest,
  restoreCategoryRequest,
  setCurrentPageCategories,
  clearCreateCategoryStatus,

  getSubCategoriesRequest,
  createSubCategoryRequest,
  editSubCategoryRequest,
  deleteSubCategoryRequest,
  restoreSubCategoryRequest,
  setCurrentPageSubCategories,
  clearCreateSubCategoryStatus,
  setShowSub,
}

const Categories = props => {
  useEffect(() => {
    // cleanUp of the reducer after factors
    props.setShowSub(false, null)
    props.setShowDeleted(false)
  }, [])

  return (
    <Suspense fallback={null}>
      {props.sub.show ? <SubBody {...props} /> : <Body {...props} />}
    </Suspense>
  )
}

export default connect(mapState, mapDispatch)(Categories)
