import React from "react"
import { StyledTableHeader, StyledLabel } from "../../styledComponents"

const labels = [
  "ID",
  "First Name",
  "Last Name",
  "E-mail",
  "Phone",
  "State",
  "Actions",
]

const Header = () => {
  const getFlexScale = index => {
    if (index < 4) return 1
    if (index === 7) return 1.5
    return 1.5
  }
  return (
    <StyledTableHeader>
      {labels.map((e, index) => (
        <StyledLabel key={index} flex={getFlexScale(index)}>
          {e}
        </StyledLabel>
      ))}
    </StyledTableHeader>
  )
}

export default Header
