import React, { Component } from "react"
import { connect } from "react-redux"

export default ComposedComponent => {
  class RequireAuth extends Component {
    componentWillMount() {
      console.log("status = ", this.props.status)
      if (this.props.status !== "success") this.props.history.replace("/login")
    }
    componentWillUpdate() {
      console.log("status = ", this.props.status)
      if (this.props.status !== "success") this.props.history.replace("/login")
    }
    render() {
      return <ComposedComponent {...this.props} />
    }
  }

  function mapStateToProps(state) {
    return {
      status: state.auth.status,
    }
  }
  return connect(mapStateToProps)(RequireAuth)
}
