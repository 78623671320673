import { put, call } from "redux-saga/effects"

import * as actions from "../actions"
import axios from "axios"

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_PATH,
})

const apiCall = (email, password) => {
  return instance
    .post(`/login`, {
      email,
      password,
    })
    .then(response => response)
    .catch(error => error.response)
}

export function* postLoginAsync(action) {
  console.log("action = ", action)
  const { email, pass } = action.payload
  try {
    const data = yield call(apiCall, email, pass)

    console.log(" data = ", data)
    if (data.status === 200) {
      yield put(
        actions.loginSuceess({
          token: data.data.access_token,
          user: data.data.user,
        }),
      )
    } else if (data.status === 401)
      yield put(actions.loginFailure("The credentials don't pass"))
    else yield put(actions.loginFailure("Unknown Error"))
  } catch (err) {
    yield put(actions.loginFailure("Server Error"))
  }
}
