import React, { useEffect, useState } from "react"
import Header from "./Header"
import Item from "./Item"
import { TableContainer, StyledTableBody } from "../../styledComponents"
import ContentDisplay from "../../../../components/ContentDisplay"
import MyPagination from "../../../../components/MyPagination"

const Table = ({ technicians, onDelete, currentPage, onChangePage }) => {
  const { status, array: data } = technicians
  useEffect(() => {
    console.log("data.", data)
  }, [data])

  return (
    <TableContainer>
      <Header />
      <StyledTableBody>
        <ContentDisplay
          status={status}
          data={data.map((e, index) => (
            <Item onDelete={onDelete} key={index} technician={e} />
          ))}
        ></ContentDisplay>
        <MyPagination
          current={currentPage}
          pageCount={technicians.meta.last_page}
          onChangePage={onChangePage}
        />
      </StyledTableBody>
    </TableContainer>
  )
}

export default Table
