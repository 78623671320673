import axios from "axios"

/**
 * Get template items.
 *
 * @param token
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getItems = token => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/templates`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(response => {
      return response.data
    })
}

/**
 * Assign company to template
 * @param token
 * @param companyId
 * @param templateId
 * @returns {Promise<AxiosResponse<T>>}
 */
export const assignCompany = (token, companyId, templateId) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/templates/assign-company`,
      {
        companyId,
        templateId,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    )
    .then(response => {
      return response.data
    })
}

/**
 * Unassign company to template.
 *
 * @param token
 * @param companyId
 * @param templateId
 * @returns {Promise<AxiosResponse<T>>}
 */
export const unassignCompany = (token, companyId, templateId) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/templates/unassign-company`,
      {
        companyId,
        templateId,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    )
    .then(response => {
      return response.data
    })
}
