import React, { useEffect, useState } from "react"
import { Pagination, Space } from "antd"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import moment from "moment"
import axios from "axios"

import AppointmentsListFilters from "./components/AppointmentsListFilters"
import AppointmentsList from "./components/AppointmentsList"
import PageLayout from "components/PageLayout"
import styles from "styles/pages/AppoinmentsListPage.module.css"
import { inspectionStatusByStatusId } from "helpers/inspectionStatus"

const AppointmentsListPage = () => {
  const [items, setItems] = useState([])
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPage: 0,
  })
  const [filters, setFilters] = useState({
    search: "",
    companyId: 0,
    technicianId: 0,
    status: -1,
    appointmentDate: null,
  })
  const [isLoading, setIsLoading] = useState(true)
  const { token } = useSelector(state => state.auth)
  const history = useHistory()

  useEffect(() => {
    loadAppointments()
  }, [pagination.currentPage, filters])

  const getAppointments = async () => {
    try {
      setIsLoading(true)
      let params = {
        page: pagination.currentPage,
        filters,
      }

      const res = await axios.post(
        `${process.env.REACT_APP_API_PATH}/appointments/list`,
        params,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )

      setIsLoading(false)
      return res.data
    } catch {
      return null
    }
  }

  const loadAppointments = async () => {
    const prepareItems = []
    setPagination({ ...pagination, disabled: true })

    const response = await getAppointments()

    if (!response) return alert("Failed to get appointments list")

    setPagination({
      ...pagination,
      disabled: false,
      currentPage: response.current_page,
      totalPage: response.total,
    })

    response.data.map(item => {
      prepareItems.push({
        key: item.id,
        id: item.id,
        company: item.company_id && item.company_name,
        technician:
          item.technician_id &&
          `${item.technician_first_name} ${item.technician_last_name}`,
        customer:
          item.customer_id &&
          `${item.customer_first_name} ${item.customer_last_name}`,
        status: inspectionStatusByStatusId(item.status),
        date:
          item.application_date && moment(item.application_date).format("L LT"),
        inspectionDate:
          item.inspection_date_time &&
          moment(item.inspection_date_time).format("L LT"),
      })
    })

    setItems(prepareItems)
  }

  const deleteAppointment = async id => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_PATH}/appointments/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )

      await loadAppointments()
    } catch {
      alert("Failed to delete appointment")
    }
  }

  const handleFilterChange = input => {
    setPagination({ ...pagination, currentPage: 1 })
    setFilters({ ...filters, ...input })
  }

  return (
    <PageLayout title="Appointments" withoutBackButton>
      <Space direction="vertical" style={{ width: "100%" }}>
        <AppointmentsListFilters
          filters={filters}
          setFilters={handleFilterChange}
        />
        <AppointmentsList
          data={items}
          pagination={{ ...pagination }}
          onItemOpen={id => history.push(`appointment/${id}`)}
          onItemDelete={id => deleteAppointment(id)}
          isLoading={isLoading}
        />
        <div className={styles.paginationContainer}>
          <Pagination
            current={pagination.currentPage}
            total={pagination.totalPage}
            onChange={page =>
              setPagination({
                ...pagination,
                currentPage: page,
              })
            }
            disabled={pagination.disabled}
          />
        </div>
      </Space>
    </PageLayout>
  )
}

export default AppointmentsListPage
