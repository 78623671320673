export const makeOption = (val, id = null) => ({ value: val, label: val, id })
export const makeOptionWidthData = (val, data = null) => ({
  value: val,
  label: val,
  data,
})

export const mapCompaniesToOptions = companies => {
  return companies.map(e => ({
    value: e.name,
    label: e.name,
    id: e.id,
  }))
}

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
