import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { uploadImage } from "../../helpers/image"

const Plus = styled.span`
  font-size: 100px;
  line-height: 1;
  left: 2px;
  color: #999;
  font-weight: 200;
`

const StyledImage = styled.div`
  width: 100px;
  height: 100px;
  background-color: #f2f2f2;
  text-align: center;
  vertical-align: middle;
  img {
    width: 100%;
    height: 100%;
  }
`

const ImageSelectContainer = styled.div`
  width: 110px;
  height: 110px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
`

const closeStyles = {
  color: "red",
}

const ImageSelect = ({ path, onLoad }) => {
  const [fullPath, setFullPath] = useState(
    `${process.env.REACT_APP_API_DOMAIN}/${path}`,
  )
  const [loaded, setLoaded] = useState(false)

  const inputRef = React.createRef()

  useEffect(() => {
    setLoaded(true)
  }, [path])

  /**
   * Upload image.
   *
   * @param e
   */
  const handleSetImage = e => {
    if (e.target.files.length > 0) {
      uploadImage(e.target.files).then(responsePath => {
        setFullPath(`${process.env.REACT_APP_API_DOMAIN}/${responsePath}`)
        onLoad(responsePath)
        setLoaded(true)
      })
    }
  }

  /**
   * Delete image.
   */
  const handleDelete = () => {
    setLoaded(false)
    onLoad(null)
  }

  /**
   * Click by image.
   */
  const handleClick = () => {
    inputRef.current.click()
  }

  return (() => {
    const preview = !loaded ? <Plus>+</Plus> : <img src={fullPath} />

    return (
      <ImageSelectContainer>
        <StyledImage onClick={handleClick}>{preview}</StyledImage>
        <FontAwesomeIcon
          onClick={handleDelete}
          icon={faTimesCircle}
          style={closeStyles}
        />
        <input
          onChange={handleSetImage}
          ref={inputRef}
          style={{ display: "none" }}
          type="file"
          id="avatar"
          name="avatar"
          accept="image/png, image/jpeg"
        />
      </ImageSelectContainer>
    )
  })()
}

export default ImageSelect
