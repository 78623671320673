import React from "react"
import Header from "./Header"
import Item from "./Item"
import { TableContainer, StyledTableBody } from "../../styledComponents"
import ContentDisplay from "../../../../components/ContentDisplay"
import MyPagination from "../../../../components/MyPagination"

const Table = ({ currentPage, customers, onChangePage }) => {
  const { status, array: data } = customers

  return (
    <TableContainer>
      <Header />
      <StyledTableBody>
        <ContentDisplay
          status={status}
          data={data.map((e, index) => (
            <Item key={index} customer={e} />
          ))}
        ></ContentDisplay>
        <MyPagination
          current={currentPage}
          pageCount={customers.meta.last_page}
          onChangePage={onChangePage}
        />
      </StyledTableBody>
    </TableContainer>
  )
}

export default Table
