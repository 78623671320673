import React, { useEffect, useState } from "react"
import axios from "axios"
import { useSelector } from "react-redux"
import { Input, DatePicker, Select, Space } from "antd"

const { Search } = Input

const AppointmentsListFilters = ({ setFilters }) => {
  const [companies, setCompanies] = useState([])
  const [technicians, setTechnician] = useState([])
  const [isCompaniesLoading, setIsCompaniesLoading] = useState(false)
  const [isTechniciansLoading, setIsTechniciansLoading] = useState(false)
  const { token } = useSelector(state => state.auth.token)

  useEffect(() => {
    getCompanies()
    getTechicians()
  }, [])

  const getCompanies = async () => {
    try {
      setIsCompaniesLoading(true)

      const response = await axios.get(
        `${process.env.REACT_APP_API_PATH}/lists/companies`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )

      const companiesList = []

      response.data.map(item => {
        companiesList.push({
          id: item.id,
          name: item.name,
        })
      })

      setIsCompaniesLoading(false)
      setCompanies(companiesList)
    } catch {
      setIsCompaniesLoading(false)
      alert("Failed to get companies")
    }
  }

  const getTechicians = async () => {
    try {
      setIsTechniciansLoading(true)

      const response = await axios.get(
        `${process.env.REACT_APP_API_PATH}/lists/technicians`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )
      const techniciansList = []

      response.data.map(item => {
        techniciansList.push({
          id: item.id,
          name: `${item.user.first_name} ${item.user.last_name}`,
        })
      })

      setIsTechniciansLoading(false)
      setTechnician(techniciansList)
    } catch {
      setIsTechniciansLoading(false)
      alert("Failed to get technicians")
    }
  }

  return (
    <Space>
      <Search
        placeholder="Search"
        onSearch={value => setFilters({ search: value })}
        onChange={e => {
          setFilters({ search: e.currentTarget.value })
        }}
      />
      <DatePicker.RangePicker
        size="default"
        onChange={(_, dateString) => {
          if (dateString.every(x => x.length === 0)) dateString = null

          setFilters({ appointmentDate: dateString })
        }}
      />
      <Select
        style={{ width: "200px" }}
        placeholder="Company"
        onChange={value => {
          setFilters({ companyId: value })
        }}
        loading={isCompaniesLoading}
      >
        <Select.Option key={`company-0`} value={0}>
          All
        </Select.Option>
        {companies.map((item, index) => (
          <Select.Option key={`company-${index}`} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
      <Select
        style={{ width: "200px" }}
        placeholder="Technician"
        onChange={value => {
          setFilters({ technicianId: value })
        }}
        loading={isTechniciansLoading}
      >
        <Select.Option key={`technician-0`} value={0}>
          All
        </Select.Option>
        {technicians.map((item, index) => (
          <Select.Option key={`technician-${index}`} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
      <Select
        style={{ width: "200px" }}
        placeholder="Status"
        onChange={value => {
          setFilters({ status: value })
        }}
      >
        <Select.Option key={-1} value={-1}>
          All
        </Select.Option>
        <Select.Option key={0} value={0}>
          Awaiting Approve
        </Select.Option>
        <Select.Option key={1} value={1}>
          Awaiting Execution
        </Select.Option>
        <Select.Option key={2} value={2}>
          Inspected
        </Select.Option>
        <Select.Option key={3} value={3}>
          Canceled
        </Select.Option>
        <Select.Option key={4} value={4}>
          Ready to GO
        </Select.Option>
      </Select>
    </Space>
  )
}

export default AppointmentsListFilters
