import Pending from "./Body"
import {
  setShowDeleted,
  getPendingRequest,
  setCurrentPagePending,
  setAssignModal,
  setDeleteModal,
  setRestoreModal,
  clearAssignPendingStatus,
  assingPendingRequest,
  deletePendingRequest,
  restorePendingRequest,
} from "../../../store/actions"
import { connect } from "react-redux"

const mapState = state => ({
  deleted: state.deleted,
  pending: state.pending,
  currentPage: state.currentPages.pending,
  modals: state.modals,
  assignError: state.errors.assignPending,
  assignStatus: state.statuses.assignPending,
})

const mapDispatch = {
  setShowDeleted,
  getPendingRequest,
  setCurrentPagePending,
  setAssignModal,
  setDeleteModal,
  setRestoreModal,
  clearAssignPendingStatus,
  assingPendingRequest,
  deletePendingRequest,
  restorePendingRequest,
}

export default connect(mapState, mapDispatch)(Pending)
