import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import { SimpleButton } from "../../../../components/Buttons"
import {
  createTemplateRequest,
  clearCreateTemplateStatus,
  editTemplateRequest,
} from "../../../../store/actions"
import { connect } from "react-redux"
import { useAlert } from "react-alert"

import theme from "../../../../style/theme"

const Container = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  padding: 0;
  background: #bbb;
  color: ${props => (props.selected ? theme.colors.blackMain : "white")};
  justify-content: center;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  color: #999;
  color: white;
  font-weight: 400;
  justify-content: center;
  flex: ${props => (props.flex ? props.flex : 1)};
`

const ActionHeader = ({
  createTemplate,
  createTemplateRequest,
  createTemplateStatus,
  createTemplateErrors,
  editTemplateRequest,
  clearCreateTemplateStatus,
  history,
  templateID,
  isEdit,
}) => {
  const [ready, setReady] = useState(false)
  const alert = useAlert()

  const handleSubmit = () => {
    // console.log('aaa')
    if (isEdit) {
      editTemplateRequest(templateID)
    } else {
      createTemplateRequest()
    }
  }

  useEffect(() => {
    setReady(createTemplate.processing) //ghost#temp
  }, [createTemplate.processing])

  useEffect(() => {
    if (createTemplateStatus === "loading") {
      alert.show("Sending")
    }
    if (createTemplateStatus === "success") {
      alert.success("Template created!")
      history.replace("/templates")
    }

    if (createTemplateStatus === "failure") {
      // alert.error("Error creating Template");
      Object.values(createTemplateErrors).forEach(e => {
        alert.error(e)
      })
    }
    clearCreateTemplateStatus()
  }, [createTemplateStatus])

  return (
    <Container>
      <Row flex={2}>Name: {createTemplate.name}</Row>
      <Row flex={2}>
        Vehicle Type: ({createTemplate.vehicle.id}){" "}
        {createTemplate.vehicle.name}
      </Row>

      <Row flex={1}>
        <SimpleButton
          title={isEdit ? "Edit" : "Create"}
          textColor="white"
          disabled={!ready}
          color="#E8C77A"
          shadow
          scalable
          onClick={handleSubmit}
        />
        {/* <CreateButton>Create {createTemplate.name}</CreateButton> */}
      </Row>
      <Row flex={0.1}></Row>
    </Container>
  )
}

const connectState = state => ({
  createTemplate: state.createTemplate,
  createTemplateStatus: state.statuses.createTemplate,
  createTemplateErrors: state.errors.createTemplate,
  templateID: state.templates.template ? state.templates.template.id : null,
})
const connectActions = {
  createTemplateRequest,
  clearCreateTemplateStatus,
  editTemplateRequest,
}

export default connect(connectState, connectActions)(withRouter(ActionHeader))
