import React from "react"
import styled from "styled-components"
import theme from "../../style/theme"
import classes from "../classes.module.css"
import { faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const StyledContainer = styled.div`
  background: ${theme.colors.blueLight};
  width: 135px !important;
  height: 135px !important;
  border-radius: 0;
  display: flex;
  align-items: center;
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  padding: 0;
  justify-content: center;
  text-align: center;
  border: 1px solid ${theme.colors.borderDarkBlue};
  &:hover {
    opacity: 0.8;
  }
`

const Plus = styled.p`
  position: relative;
  left: 2px;
  color: #999;
  font-size: 100px;
  font-weight: 200;
`

const StyledInput = styled.input`
  cursor: pointer;
  width: 135px !important;
  height: 135px !important;
  border-radius: 0;
  border-radius: 5px;
  position: absolute;
  opacity: 0;
`

const StyledImageBox = styled.div`
  width: 135px !important;
  height: 135px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  border-radius: 5px;
  overflow: hidden;
`

const ImageContainer = ({ image, deletePhoto }) => {
  return (
    <div className={classes.imageField}>
      <StyledImageBox>
        <img height={150} src={URL.createObjectURL(image)} alt="avatar" />
      </StyledImageBox>
      <FontAwesomeIcon
        onClick={deletePhoto}
        icon={faTimesCircle}
        className={classes.closeSquarePhoto}
      />
    </div>
  )
}

const Container = props => {
  return (
    <>
      <StyledContainer>
        {props.image ? (
          <ImageContainer deletePhoto={props.deletePhoto} image={props.image} />
        ) : (
          <Plus>+</Plus>
        )}
        {props.children}
      </StyledContainer>
    </>
  )
}

const MyPhotoInput = ({ value, onChange, ...rest }) => {
  return (
    <Container deletePhoto={() => onChange([])} image={value[0]}>
      <StyledInput
        {...rest}
        onChange={e => {
          onChange(e.target.files)
        }}
        // value={value}
        type="file"
        id="avatar"
        name="avatar"
        accept="image/png, image/jpeg"
      />
    </Container>
  )
}

export default MyPhotoInput
