import React from "react"
import { Popconfirm, Tooltip, Button, Space } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash, faEye } from "@fortawesome/free-solid-svg-icons"

const AppointmentsListActions = ({ onOpen, onDelete }) => {
  return (
    <Space>
      <Tooltip title="Open appointment">
        <Button
          type="primary"
          shape="circle"
          onClick={onOpen}
          icon={<FontAwesomeIcon icon={faEye} />}
        />
      </Tooltip>
      <Tooltip title="Delete appointment">
        <Popconfirm
          title="Are you sure want to delete this appointment?"
          onConfirm={onDelete}
          okText="Yes"
          cancelText="No"
        >
          <Button
            type="primary"
            shape="circle"
            color="red"
            danger
            icon={<FontAwesomeIcon icon={faTrash} />}
          />
        </Popconfirm>
      </Tooltip>
    </Space>
  )
}

export default AppointmentsListActions
