import React from "react"
import { Layout } from "antd"

import SiderMenu from "./components/SideMenu"
import Header from "./components/Header"

import styles from "styles/components/HomeLayout.module.css"

const { Content } = Layout

const HomeLayout = ({ children }) => {
  return (
    <Layout className={styles.layout}>
      <SiderMenu />
      <Layout>
        <Header />
        <Content>{children}</Content>
      </Layout>
    </Layout>
  )
}

export default HomeLayout
