import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import { getItems } from "../../../../helpers/package"
import PageTitle from "../../../../components/PageTitle"
import styled from "styled-components"
import FormPackage from "../FormPackage"
import Actions from "./Actions"

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: ${props => (props.noPadding ? 0 : "2%")};
  box-sizing: border-box;
  overflow-y: scroll;
`

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const StyledTHead = styled.thead`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  color: #a4a4b4;
  background-color: #e1eae5;
  td {
    padding: 13px;
  }

  @media (max-width: 1470px) {
    font-size: 10px;
  }
`

const StyledTBody = styled.tbody`
  font-size: 0.65rem;
`

const StyledTr = styled.tr`
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #f5f5f7;
  td {
    padding: 13px;
  }
  :nth-child(even) {
    background-color: #f2f2f2;
  }
  :hover {
    background-color: #d0d0d0;
    cursor: pointer;
  }
`

const StatusCell = styled.td`
  .ant-badge-status-text {
    font-size: 0.65rem;
  }
`

const Packages = props => {
  const [items, setItems] = useState([])
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    getItems(props.auth.token).then(response => {
      const prepareItems = []

      response.map(item => {
        prepareItems.push({
          id: item.id,
          name: item.name ? item.name : "-",
          price: item.price ? `$${item.price}` : "-",
          actions: <Actions packageId={item.id} />,
        })
      })

      setItems(prepareItems)
    })
  }, [])

  return (
    <PageContainer>
      <PageTitle title="Packages" />
      <StyledTable>
        <StyledTHead>
          <tr>
            <td>ID</td>
            <td>Name</td>
            <td>Price</td>
            <td style={{ width: "100px" }}>Actions</td>
          </tr>
        </StyledTHead>
        <StyledTBody>
          {items &&
            items.map((item, index) => {
              return (
                <StyledTr key={`appointment-${index}`}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item.price}</td>
                  <td>{item.actions}</td>
                </StyledTr>
              )
            })}
        </StyledTBody>
      </StyledTable>
      <FormPackage visible={visible} />
    </PageContainer>
  )
}

const mapState = state => ({
  auth: state.auth,
})

export default connect(mapState)(Packages)
