import React, { useCallback, useEffect } from "react"
import styled from "styled-components"
import theme from "../../style/theme"
import Close from "./Close"
import { fadeAnimation } from "../../style/keyframes"

import { ConfirmButton, CancelButton, EditButton } from "../Buttons"

const ModalMask = styled.div`
  padding: ${props => props.padding}px 0 ${props => props.padding}px 0 !important;
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.18) !important;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  position: fixed;
  overflow: auto;
  // position: absolute;
  align-items: center;
  justify-content: center;
`

const ModalContainer = styled.div`
  display: flex;
  background: white;
  flex-direction: column;
  border-radius: 2px;
  box-shadow: 0px 0px 2px rgba(255, 255, 255, 0.4);
  align-items: center;
  animation: ${fadeAnimation} 400ms ease-out;
  justify-content: center;
  padding: 25px;
  margin-top: ${props =>
    props.label === "Add New Technician" || props.label === "Edit Technician"
      ? "200px;"
      : null};
`

const ModalHeader = styled.div`
  font-size: ${theme.font.md2};
  color: ${theme.colors.blackMain};
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: ${props =>
    props.alignButtonsRight ? "space-around" : "flex-end"};
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`

const ChooseButton = ({ onClick, type }) => {
  if (type === "edit") {
    return <EditButton onClick={onClick} />
  } else if (type === "view") return null
  return <ConfirmButton onClick={onClick} />
}

const RenderModal = ({
  label = null,
  render,
  type = "regular",
  setActive = () => {},
  onConfirm = () => {},
  active,
  showClose = true,
  padding = 0,
}) => {
  const close = useCallback(() => setActive(false), [setActive])
  const confirm = useCallback(() => {
    setActive(false)
    onConfirm()
  }, [setActive, onConfirm])

  if (!active) return null
  return (
    <ModalMask padding={padding} onClick={() => {}}>
      <ModalContainer label={label}>
        <HeaderContainer>
          <ModalHeader>{label}</ModalHeader>
          {showClose && <Close onClick={close} />}
        </HeaderContainer>
        {render()}
        <ButtonContainer>
          <ChooseButton onClick={confirm} type={type}></ChooseButton>
          <CancelButton onClick={close}></CancelButton>
        </ButtonContainer>
      </ModalContainer>
    </ModalMask>
  )
}

export default RenderModal
