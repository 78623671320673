import React, { useState, useEffect } from "react"
import MySwitch from "../../../../components/MySwitch"
import MySearchInput from "../../../../components/MySearchInput"
import Table from "./Table"
import { StyledToolBar, ToolGroup } from "../../styledComponents"
import { NewButton } from "../../../../components/Buttons"
import AddModal from "../Actions/AddModal"
import SimpleModal from "../../../../components/SimpleModal"
import RenderModal from "../../../../components/RenderModal"
import { useAlert } from "react-alert"
import PageLayout from "components/PageLayout"

const Technicians = props => {
  const [search, setSearch] = useState(null)
  const [newTechnician, setNewTechnician] = useState(null)
  const [editedTechnician, setEditedTechnician] = useState(null)
  const alert = useAlert()
  const {
    setShowDeleted,
    deleted,
    getTechniciansRequest,
    technicians,
    currentPage,
    setCurrentPageTechnicians,
    createTechnicianRequest,
    createRequestStatus,
    clearCreateTechnicianStatus,
    deleteTechnicianRequest,
    restoreTechnicianRequest,
    editTechnicianRequest,
    setDeleteModal,
    setRestoreModal,
    setEditModal,
    errors,
    modals,
  } = props

  const getData = (page = null, search = null) => {
    const params = {
      page: page !== null ? page : currentPage,
      soft_deleted: deleted.show,
      search,
      sort: null,
    }
    getTechniciansRequest(params)
  }

  useEffect(() => {
    getData()
  }, [deleted.show])

  useEffect(() => {
    if (createRequestStatus === "loading") {
      alert.show("Sending")
    }
    if (createRequestStatus === "success") {
      alert.success("Technician created!")
    }

    if (createRequestStatus === "failure") {
      // alert.error("Error creating Technician");
      Object.values(errors.createTechnician).forEach(e => {
        alert.error(e)
      })
    }
    clearCreateTechnicianStatus()
  }, [createRequestStatus])

  const handleChangePage = event => {
    setCurrentPageTechnicians(event.selected)
    getData(event.selected, search)
  }

  const handleSearch = e => {
    const query = e.target.value
    setSearch(query)
    getData(null, query)
  }

  const handleCreate = () => {
    // createTechnicianRequest(newTechnician);
    createTechnicianRequest({
      ...newTechnician,
      phone: newTechnician.phone.replace(/\W/g, ""),
    })
  }

  const handleEdit = () => {
    // get existing data
    // get again after edit
    console.log("editedTechnician = ", editedTechnician)
    // editTechnicianRequest(editedTechnician, modals.edit.id);
    editTechnicianRequest(
      {
        ...editedTechnician,
        phone: editedTechnician.phone.replace(/\W/g, ""),
      },
      modals.edit.id,
    )
  }

  const getTechnicianForEdit = () =>
    technicians.array.find(e => e.id === modals.edit.id)

  const handleDelete = () => {
    deleteTechnicianRequest(modals.delete.id)
  }

  const handleRestore = () => {
    restoreTechnicianRequest(modals.restore.id)
  }

  return (
    <PageLayout title="Technicians" withoutBackButton>
      <RenderModal
        label="Add New Technician"
        padding={150}
        render={() => <AddModal callback={setNewTechnician} />}
        active={props.modals.showAdd}
        setActive={props.setAddModal}
        onConfirm={handleCreate}
      />
      <RenderModal
        alignButtonsRight
        label="Confirmation"
        onConfirm={handleDelete}
        render={() => (
          <SimpleModal text="Are you sure you want to delete Technician?" />
        )}
        active={modals.delete.show}
        setActive={setDeleteModal}
      />
      <RenderModal
        label="Edit Technician"
        onConfirm={handleEdit}
        render={() => (
          <AddModal
            technician={getTechnicianForEdit()}
            callback={setEditedTechnician}
          />
        )}
        padding={150}
        active={modals.edit.show}
        setActive={setEditModal}
      />
      <RenderModal
        alignButtonsRight
        label="Confirmation"
        render={() => (
          <SimpleModal text="Are you sure you want to restore Technician?" />
        )}
        active={modals.restore.show}
        onConfirm={handleRestore}
        setActive={setRestoreModal}
      />
      <StyledToolBar>
        <MySearchInput value={search} onChange={handleSearch} />
        <ToolGroup>
          {/* <MyPagination
                                current={currentPage}
                                pageCount={technicians.meta.last_page}
                                onChangePage={handleChangePage}
                            /> */}
        </ToolGroup>

        <MySwitch
          label="Show Deleted"
          active={deleted.show}
          setActive={setShowDeleted}
        />
        <NewButton onClick={() => props.setAddModal(true)} />
      </StyledToolBar>
      <Table
        onChangePage={handleChangePage}
        currentPage={currentPage}
        onDelete={handleDelete}
        technicians={technicians}
      />
    </PageLayout>
  )
}

export default Technicians
