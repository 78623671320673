import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { Menu, Modal, Spin } from "antd"
import axios from "axios"

import PageLayout from "components/PageLayout"

import ClientInfo from "./Tabs/ClientInfo"
import PaymentsDetails from "./Tabs/PaymentsDetails"
import InspectionDetails from "./Tabs/InspectionDetails"
import AppointmentsHistory from "./Tabs/AppointmentsHistory"

const tabsItems = [
  { label: "Customer Info", key: "customer-info" },
  { label: "Inspection Details", key: "inspection-details" },
  { label: "Payments", key: "payments" },
  { label: "Appointments History", key: "history" },
]

const Body = props => {
  const [appointment, setAppointment] = useState(null)
  const [currentTabKey, setCurrentTabKey] = useState("customer-info")
  const [isAppointmentLoading, setIsAppointmentLoading] = useState(false)
  const token = useSelector(state => state.auth.token)

  useEffect(() => {
    getAppointment()
  }, [props.match.params.appointmentId])

  const getAppointment = async () => {
    try {
      setIsAppointmentLoading(true)
      const response = await axios.get(
        `${process.env.REACT_APP_API_PATH}/appointments/${props.match.params.appointmentId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      )

      setIsAppointmentLoading(false)
      setAppointment(response.data)
    } catch {
      setIsAppointmentLoading(false)

      Modal.error({
        title: "Failed to get an appointment",
        content: "Try to refresh this page",
      })
    }
  }

  const currentTab = {
    "customer-info": <ClientInfo appointment={appointment} />,
    "inspection-details": <InspectionDetails appointment={appointment} />,
    payments: <PaymentsDetails appointmentId={props.appointmentId} />,
    history: <AppointmentsHistory appointment={appointment} />,
  }

  const appointmentTitle = [
    appointment?.technician ? "Customer's" : "Inspector's",
    "appointment",
    `#${appointment?.id}`,
  ].join(" ")

  return (
    <PageLayout title={appointment ? appointmentTitle : "Loading..."}>
      <Spin spinning={isAppointmentLoading}>
        <div style={{ backgroundColor: "white" }}>
          <Menu
            items={tabsItems}
            mode="horizontal"
            selectedKeys={[currentTabKey]}
            onClick={({ key }) => setCurrentTabKey(key)}
          />
          {currentTab[currentTabKey]}
        </div>
      </Spin>
    </PageLayout>
  )
}

export default Body
