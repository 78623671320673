import React from "react"
import Templates from "./Tabs/Templates/Templates"
import Technicians from "./Tabs/Technicians/Technicians"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import ClientInfo from "../../Appointment/Form/Tabs/ClientInfo"

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
`

const TabsContainer = styled.div`
  border-style: solid;
  border-width: 1px;
  border-color: #e1e1e1;
`

const TabsMenu = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #9bcaaf;
`

const TabsMenuItem = styled.div`
  padding: 10px;
  font-weight: 600;
  font-size: 13px;
  width: 25%;
  text-align: center;
  cursor: pointer;
  background-color: ${props => (!props.active ? "#9bcaaf" : "#d5e9dc")};
  color: ${props => (!props.active ? "#fff" : "#000")};
`

const TabsBody = styled.div`
  height: 100%;
`

const CompanyDetail = props => {
  const history = useHistory()

  const handleChangeTab = name => {
    history.push(`/company/detail/${props.match.params.id}/${name}`)
  }

  return (() => {
    let currentTabComponent = ""

    // eslint-disable-next-line default-case
    switch (props.match.params.tab) {
      case "templates":
        currentTabComponent = <Templates companyId={props.match.params.id} />
        break
      case "technicians":
        currentTabComponent = <Technicians companyId={props.match.params.id} />
        break
    }

    return (
      <PageContainer>
        <TabsContainer>
          <TabsMenu>
            <TabsMenuItem
              active={props.match.params.tab === "templates"}
              onClick={() => handleChangeTab("templates")}
            >
              Templates
            </TabsMenuItem>
            <TabsMenuItem
              active={props.match.params.tab === "technicians"}
              onClick={() => handleChangeTab("technicians")}
            >
              Technicians
            </TabsMenuItem>
          </TabsMenu>
          <TabsBody>{currentTabComponent}</TabsBody>
        </TabsContainer>
      </PageContainer>
    )
  })()
}

export default CompanyDetail
