import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { SimpleButton } from "../../../../../../components/Buttons"
import SimpleModal from "../../../../../../components/SimpleModal"
import RenderModal from "../../../../../../components/RenderModal"
import { pushStepsCurrentCategory } from "../../../../../../store/actions"
import Steps from "../Steps"
import StepModal from "../StepModal"

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: ${props => (props.flex ? props.flex : 1)}
    width: 90%;
    padding: 38px 20px 20px 20px;
	margin: auto;
    align-items: center;
    justify-content: ${props => (props.colCenter ? "center" : "start")};
`

const StepEditor = ({ currentCategory, pushStepsCurrentCategory }) => {
  const [stepModal, openStepModal] = useState(false)
  const [removeModal, setRemoveModal] = useState({
    open: false,
    target: null,
    name: "",
  })
  const [step, setStep] = useState("")
  const [steps, setSteps] = useState(
    currentCategory.steps ? currentCategory.steps.map(e => e) : [],
  )

  const handlePushStep = () => {
    if (step === "") return
    setStep("")
    setSteps([...steps, step])
  }

  const handleDelete = () => {
    setSteps(steps.filter((e, index) => index !== removeModal.target))
  }

  useEffect(() => {
    pushStepsCurrentCategory(steps)
  }, [steps])

  return (
    <Container>
      <RenderModal
        alignButtonsRight
        label="Confirmation"
        onConfirm={handleDelete}
        render={() => <SimpleModal text={`Remove [${removeModal.name}]?`} />}
        active={removeModal.open}
        setActive={() =>
          setRemoveModal({ open: false, target: null, name: "" })
        }
      />
      <RenderModal
        label="Add Step"
        render={() => (
          <StepModal
            close={() => openStepModal(false)}
            name={step}
            setName={setStep}
          />
        )}
        onConfirm={handlePushStep}
        active={stepModal}
        setActive={openStepModal}
      />
      <SimpleButton
        scalable
        title={`+ Add Step for ${
          currentCategory.name ? currentCategory.name : "Current Category"
        }`}
        color="#555555"
        onClick={() => openStepModal(true)}
      />
      <Steps
        onRemove={target =>
          setRemoveModal({
            open: true,
            target: target.id,
            name: target.name,
          })
        }
        steps={steps}
        fullSize
      />
    </Container>
  )
}

const connectState = state => ({
  currentCategory: state.createTemplate.currentCategory,
})

const connectActions = { pushStepsCurrentCategory }
export default connect(connectState, connectActions)(StepEditor)
