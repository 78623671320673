import React, { useState } from "react"
import ReactPaginate from "react-paginate"
import "./style.css"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 350px;
  align-items: flex-end;
`

const Pagination = ({ current, pageCount, onChangePage }) => {
  return (
    <Container
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "14px 0px 7px",
      }}
    >
      <ReactPaginate
        key={pageCount}
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        initialPage={current >= pageCount ? 0 : current}
        breakClassName={"break-me"}
        pageCount={pageCount}
        onPageChange={onChangePage}
        marginPagesDisplayed={1}
        pageRangeDisplayed={4}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
    </Container>
  )
}

export default Pagination
