import types from "../types"

const initialMeta = {
  last_page: 1,
}
const initialState = {
  listCompanies: [], // for select
  array: [],
  selectedCompanyInfo: {},
  meta: initialMeta,
}

const formatResponseData = data => {
  const array = data.map(e => {
    return {
      id: e.id,
      name: e.name,
      phone: e.phone,
      email: e.email,
      contactPerson: e.contact_person,
      numberOfTechnicians: e.number_of_technicians,
      actions: true,
      // not displayable
      templates: e.templates,
      technicians: e.technicians,
      stateId: e.state_id - 1, // 1-indexed array to  0-indexed
    }
  })

  console.log(" companies reducer = ", data)

  return array
}

export default function companies(state = initialState, action) {
  switch (action.type) {
    case types.GET_ALL_COMPANIES_SUCCESS:
      return {
        ...state,
        listCompanies: action.payload,
      }
    case types.GET_COMPANIES_SUCCESS:
      const array = formatResponseData(action.payload.data)
      return {
        ...state,
        array: array,
        meta: action.payload.meta,
      }
    case types.GET_COMPANY_INFO_SUCCESS:
      // const array = formatResponseData(action.payload.data);
      return {
        ...state,
        selectedCompanyInfo: action.payload.data,
      }

    default:
      return state
  }
}
