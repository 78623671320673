import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { SimpleButton } from "../../../../../../components/Buttons"
import SubItem from "./Item"
import { NoItemsMessage } from "../../styled"
import uuid from "uuid/v1"
import {
  pushSubsCurrentCategory,
  setAllSubcategoriesFinished,
} from "../../../../../../store/actions"

const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
`

const Container = styled.div`
  overflow-y: auto;
  padding: 38px 20px 20px 20px;
  height: 85%;
  max-height: 455px !important;
`

const AddButton = ({ onClick, name }) => {
  return (
    <BtnContainer>
      <SimpleButton
        scalable
        title={`+ Add Subcategory for ${name ? name : "Current Category"}`}
        color="#3B86FF"
        onClick={onClick}
      />
    </BtnContainer>
  )
}

// const emptyArea = [];
class _Subcategory {
  constructor() {
    this.id = null
    this.store_id = uuid()
    // meta
    this.name = ""
    this.steps = []
    this.areas = null
    this.editing = true
  }
}

const SubEditor = ({
  categoryName,
  storedSubcategories,
  pushSubsCurrentCategory,
  setAllSubcategoriesFinished,
}) => {
  const [subcategories, setSubcategories] = useState(
    storedSubcategories && storedSubcategories.length > 0
      ? storedSubcategories
      : [new _Subcategory()],
  )

  const createNew = () => {
    setSubcategories([...subcategories, new _Subcategory()])
  }

  const handleUpdate = item => {
    setSubcategories(
      subcategories.map(e => (e.store_id === item.store_id ? item : e)),
    )
  }

  useEffect(() => {
    setAllSubcategoriesFinished(subcategories.every(e => e.editing === false))
  }, [subcategories])

  const handleRemove = store_id => {
    const newCategories = subcategories.filter(e => e.store_id !== store_id)
    setSubcategories(newCategories)
    handlePushStore(newCategories)
  }

  const handlePushStore = arr => {
    pushSubsCurrentCategory(
      arr || subcategories.map(e => ({ ...e, editing: false })),
    ) // stop editing and save
  }

  let content
  content = subcategories.map(e => {
    return (
      <SubItem
        onUpdate={handleUpdate}
        pushStore={handlePushStore}
        onRemove={handleRemove}
        array={subcategories}
        item={e}
        key={e.store_id}
      />
    )
  })
  return (
    <Container>
      <AddButton name={categoryName} onClick={createNew} />
      {content}
    </Container>
  )
}

const connectState = state => ({
  categoryName: state.createTemplate.currentCategory.name,
  storedSubcategories: state.createTemplate.currentCategory.subcategories,
})

const connectActions = {
  pushSubsCurrentCategory,
  setAllSubcategoriesFinished,
}
export default connect(connectState, connectActions)(SubEditor)
